import { useContext, useEffect, useState } from 'react';
import './App.css';
import { Navigate, Route, Routes, createBrowserRouter, RouterProvider, useLocation, useNavigate, useParams } from 'react-router-dom';
import Home from './pages/Home';
import Courses from './pages/Admin/Courses/Courses';
import Users from './pages/Admin/Users/Users';
import Dashboard from './pages/Admin/Dashboard';
import Coupons from './pages/Admin/Coupons/Coupons';
import Quizzes from './pages/Admin/Quizzes/Quizzes';
import SingleUser from './pages/Admin/Users/SingleUser';
import SingleCourse from './pages/Admin/Courses/SingleCourse';
import SingleQuiz from './pages/Admin/Quizzes/SingleQuiz';
import Profile from './pages/Profile';
import Cart from './pages/Cart';
import Checkout from './pages/Payment';
import CoursesList from './pages/PublicProducts';
import UserCourses from './pages/Profile/UserCourses';
import UserCertificates from './pages/Profile/UserCertificates';
import { ApiConfig } from './config/Configuration';
import Resources from './pages/Admin/Resources/Resources';
import SingleResource from './pages/Admin/Resources/SingleResource';
import SingleCoupon from './pages/Admin/Coupons/SingleCoupon';
import Videos from './pages/Admin/Videos/Videos';
import SingleVideo from './pages/Admin/Videos/SingleVideo';
import SignIn from './pages/Authentication/Signin';
import Signup from './pages/Authentication/Signup';
import { AuthService } from './auth/sso/auth.service';
import { IDPPermission, UserService } from './services/user.service';
import { jwtDecode } from 'jwt-decode';
import { axiosAuthInstance, axiosAuthPublicInstance } from './auth/sso/auth.interceptor';
import PublicProducts from './pages/PublicProducts';
import { PublicSingleProduct } from './pages/PublicSingleProduct';
import { PublicSingleVideo } from './pages/PublicSingleVideo';
import axios from 'axios';
import MyProfile from './pages/Profile/MyProfile';
import Payment from './pages/Payment';
import { PublicSingleLive } from './pages/PublicSingleLive';
import { Lives } from './pages/Admin/Lives/Lives';
import SingleLive from './pages/Admin/Lives/SingleLive';
import PublicSubscriptions from './pages/PublicSubscriptions';
import { CartContext } from './core/cartProvider';
import { ConfirmSignup } from './pages/Authentication/ConfirmSignup';
import { ForgotPassword } from './pages/Authentication/ForgotPassword';
import { ResetPassword } from './pages/Authentication/ResetPassword';
import { UserSubscriptions } from './pages/Profile/HandleSubscriptions';


const VideoGuard = ({ element: Component }: any) => { // Route privata per gestire la protezione dei video
  const navigate = useNavigate();
  const { courseId, videoId } = useParams();
  const isAuthenticated = AuthService.getInstance().accessToken ?? false;
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const checkAccess = async () => {
      if (!isAuthenticated) {
        navigate(`/public/corsi/${courseId}`);
        return;
      }

      try {
        const responseCourse = await axiosAuthInstance.get(`${ApiConfig.ROOT_URL}/public/courses/${courseId}`);
        const course = responseCourse.data;

        if (!course.Access.CanView) {
          navigate(`/public/corsi/${courseId}`);
        } else {
          setLoading(false);
        }
      } catch (error) {
        console.error("Error checking course access:", error);
        navigate(`/public/corsi/${courseId}`);
      }
    };

    checkAccess();
  }, [isAuthenticated, courseId, navigate]);

  if (loading) {
    return null; // Non renderizzo nulla finché non ho finito di controllare l'accesso
  }

  return <Component courseId={courseId} videoId={videoId} />;
};

const LiveGuard = ({ element: Component }: any) => { // Route privata per gestire la protezione delle live
  const navigate = useNavigate();
  const { liveId } = useParams();
  const isAuthenticated = AuthService.getInstance().accessToken ?? false;
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const checkAccess = async () => {
      if (!isAuthenticated) {
        navigate(`/public/lives/${liveId}`);
        return;
      }

      try {
        const responseLive = await axiosAuthInstance.get(`${ApiConfig.ROOT_URL}/public/lives/${liveId}`);
        const live = responseLive.data;

        if (!live.Access.CanView) {
          navigate(`/public/lives/${liveId}`);
        } else {
          setLoading(false);
        }
      } catch (error) {
        console.error("Error checking live access:", error);
        navigate(`/public/lives/${liveId}`);
      }
    }

    checkAccess();
  }, [isAuthenticated, liveId, navigate]);

  if (loading) {
    return null; // Non renderizzo nulla finché non ho finito di controllare l'accesso
  }

  return <Component liveId={liveId} />;
}

const AdminGuard = ({ element: Component }: any) => { // Route privata per gli admin, se non hai i permessi ti reindirizza alla home
  return AuthService.getInstance().isAdmin ? <Component /> : <Navigate to="/" />;
};

// Route privata che verifica se l'utente è autenticato o no. Ad esempio, se provo ad accedere alla pagina del checkout senza essere autenticato,
// mi aspetto di essere reindirizzato alla pagina di login. Se mi autentico successivamente, devo essere reindirizzato alla pagina a cui volevo accedere

const AuthGuard = ({ element: Component, to }: any) => {
  const isAuthenticated = AuthService.getInstance().accessToken ?? false;
  return isAuthenticated ? <Component /> : <Navigate to="/signin" state={{ toPath: to }} />;
}

// Route privata che verifica se l'utente è autenticato e se ha almeno un prodotto nel carrello. Se non è autenticato o non ha prodotti nel carrello,
// viene reindirizzato alla pagina specificata 

const CheckoutGuard = ({ element: Component, to }: any) => { // da limitare solo a url
  const isAuthenticated = AuthService.getInstance().accessToken ?? false;
  const { cartItems } = useContext(CartContext);

  return isAuthenticated && cartItems.length > 0 ? <Component /> : <Navigate to={to} />;
}

function App() {
  useEffect(() => {
    async function handleUser() {
      await UserService.getInstance().getUserData(); // Ottengo i dati dell'utente
    }

    if (!AuthService.getInstance().accessToken)
      return;

    handleUser().then(() => {
      const decodedToken: any = jwtDecode(AuthService.getInstance().accessToken);
      // Salvo i permessi dell'utente dentro il servizio UserService, usando l'enum IDPPermission 
      UserService.getInstance().permissions = decodedToken.Permissions.map((permission: string) => {
        const enumPermission = getEnum(permission);
        if (enumPermission) {
          return enumPermission;
        } else {
          throw new Error(`Permission ${permission} not found in IDPPermission enum`);
        }
      });
    }).catch((error) => {
      console.error(error);
    });
  }, []); // Eseguo il codice ogni volta che isAuthenticated cambia

  const getEnum = (value: string): IDPPermission | undefined => {
    // Verificare se il valore è presente nell'enum
    if (Object.values(IDPPermission).includes(value as IDPPermission)) {
      return value as IDPPermission;
    } else {
      return undefined;
    }
  }

  // creo le routes
  const routes = createBrowserRouter([
    { path: "*", element: <Navigate to="/" /> }, // Se l'utente cerca una route non esistente, lo reindirizzo alla home

    // Gestisco le route di IDP
    { path: "/signin", element: <SignIn /> },
    { path: "/signup", element: <Signup /> },
    { path: "/confirm-account", element: <ConfirmSignup /> },
    { path: "/forgot-password", element: <ForgotPassword /> },
    { path: "/reset-password", element: <ResetPassword /> },

    // Gestisco le route dell'applicazione
    {
      path: "/",
      element: <Home />,
      loader: async () => {
        const response = await axiosAuthPublicInstance.get(`${ApiConfig.ROOT_URL}/public/classes`); // Chiamo l'API per ottenere tutti i corsi prima di renderizzare la pagina
        return response.data; // Ritorno i dati dei corsi
      },
    },
    {
      path: "/profile/*", element: <AuthGuard element={Profile} to={"/profile"} />, children: [
        { path: "", element: <Navigate to="/profile/my-profile" /> },
        { path: "my-profile", element: <MyProfile /> },
        { 
          path: "courses", 
          element: <UserCourses />,
          loader: async () => {
            const response = await axiosAuthPublicInstance.get(`${ApiConfig.ROOT_URL}/public/classes`);
            // MOMENTANEO PER TEST PRENDO SOLO GLI ELEMENTI CHE HANNO Access.CanView = true
            const myProducts = response.data.filter((product: any) => product.Access.CanView);
            return myProducts;
          }
        },
        { path: "certificates", element: <UserCertificates /> },
        { path: "subscriptions", element: <UserSubscriptions /> }
      ]
    },
    { 
      path: "/public/abbonamenti", 
      element: <PublicSubscriptions />,
      loader: async () => {
        const response = await axiosAuthPublicInstance.get(`${ApiConfig.ROOT_URL}/public/subscriptions`); // Chiamo l'API per ottenere tutti gli abbonamenti prima di renderizzare la pagina
        return response.data; // Ritorno i dati degli abbonamenti
      }
    },
    { path: "/cart", element: <Cart /> },
    {
      path: "/payment",
      element: <CheckoutGuard element={Payment} to="/cart" />,
    },
    {
      path: "/checkout",
      element: <CheckoutGuard element={Checkout} to="/payment" />,
    },
    {
      path: "/public/corsi",
      element: <PublicProducts />,
      loader: async ({request}: any) => {
        const url = new URL(request.url);
        const searchQuery = url.searchParams.get("search");

        console.log("searchQuery", searchQuery);

        if (searchQuery) {
          const response = await axiosAuthPublicInstance.get(`${ApiConfig.ROOT_URL}/public/classes`, {
            params: {
              s: searchQuery
            }
          }); // Chiamo l'API per ottenere tutti i corsi prima di renderizzare la pagina

          response.data.forEach((element: any, index: number) => {
            element.ElementId = index;
          });

          return response.data; // Ritorno i dati dei corsi
        } else {
          const response = await axiosAuthPublicInstance.get(`${ApiConfig.ROOT_URL}/public/classes`); // Chiamo l'API per ottenere tutti i corsi prima di renderizzare la pagina
          // Aggiungo momentaneamente una chiave numerica incrementale per ogni elemento dentro response.data, chiamato "ElementId"
          response.data.forEach((element: any, index: number) => {
            element.ElementId = index;
          });


          return response.data; // Ritorno i dati dei corsi
        }
      },
    },

    {
      path: "/public/corsi/registrati",
      element: <PublicProducts />,
      loader: async () => {
        const response = await axiosAuthPublicInstance.get(`${ApiConfig.ROOT_URL}/public/courses`); // Chiamo l'API per ottenere tutti i corsi prima di renderizzare la pagina
        return response.data; // Ritorno i dati dei corsi
      },
    },
    {
      path: "/public/corsi/live",
      element: <PublicProducts />,
      loader: async () => {
        const response = await axiosAuthPublicInstance.get(`${ApiConfig.ROOT_URL}/public/lives`); // Chiamo l'API per ottenere tutti i corsi prima di renderizzare la pagina
        return response.data; // Ritorno i dati dei corsi
      },
    },
    {
      path: "/public/corsi/evidenza",
      element: <PublicProducts />,
      loader: async () => {
        const response = await axiosAuthPublicInstance.get(`${ApiConfig.ROOT_URL}/public/courses`); // Chiamo l'API per ottenere tutti i corsi prima di renderizzare la pagina
        const featuredCourses = response.data.filter((course: any) => course.Featured); // Filtraggio dei corsi in evidenza
        return featuredCourses; // Ritorno i dati dei corsi in evidenza
      },
    },
    {
      path: "/public/corsi/:id",
      element: <PublicSingleProduct />,
      loader: async (params: any) => {
        const response = await axiosAuthPublicInstance.get(`${ApiConfig.ROOT_URL}/public/courses/${params.params.id}`); // Chiamo l'API per ottenere il corso prima di renderizzare la pagina
        console.log("SINGOLO CORSO", response.data);
        return { product: response.data, typology: 'Course' };
      },
    },
    {
      path: "/public/lives/:id",
      element: <PublicSingleProduct />,
      loader: async (params: any) => {
        const response = await axiosAuthPublicInstance.get(`${ApiConfig.ROOT_URL}/public/lives/${params.params.id}`); // Chiamo l'API per ottenere il corso prima di renderizzare la pagina
        console.log("SINGOLA LIVE", response.data);
        return { product: response.data, typology: 'Live' };
      },
    },
    {
      path: "/public/videos/:courseId/:videoId",
      element: <VideoGuard element={PublicSingleVideo} />,
      loader: async (params: any) => {
        try {
          const responseVideo = await axiosAuthInstance.get(`${ApiConfig.ROOT_URL}/public/videos/${params.params.courseId}/${params.params.videoId}`);
          const responseCourse = await axiosAuthInstance.get(`${ApiConfig.ROOT_URL}/public/courses/${params.params.courseId}`);
          return { video: responseVideo.data, course: responseCourse.data };
        } catch (error) {
          // Navigate to course page
          return <Navigate to={`/public/corsi/${params.params.courseId}`} />;
        }
      },
    },
    {
      path: "/public/lives/streaming/:liveId", // DA DEFINIRE
      element: <LiveGuard element={PublicSingleLive} />,
      loader: async (params: any) => {
        try {
          const response = await axiosAuthInstance.get(`${ApiConfig.ROOT_URL}/public/lives/${params.params.liveId}`);
          console.log("SINGOLA LIVEAAAAAA", response.data);
          return response.data;
        } catch (error) {
          // Navigate to course page
          return <Navigate to={`/public/lives/${params.params.liveId}`} />;
        }
      },
    },
    { path: "/admin", element: <AdminGuard element={Dashboard} /> },
    { path: "/admin/dashboard", element: <AdminGuard element={Dashboard} /> },
    { path: "/admin/users", element: <AdminGuard element={Users} />, },
    {
      path: "/admin/users/:id",
      element: <AdminGuard element={SingleUser} />,
      loader: async (params: any) => {
        const response = await axiosAuthInstance.get(`${ApiConfig.ROOT_URL}/users/${params.params.id}`); // Chiamo l'API per ottenere l'utente prima di renderizzare la pagina
        return response.data; // Ritorno i dati dell'utente
      },
      errorElement: <Navigate to="/admin/users" /> // DA AGGIUNGERE A TUTTO
    },
    { path: "/admin/users/new", element: <AdminGuard element={SingleUser} /> },
    { path: "/admin/courses", element: <AdminGuard element={Courses} /> },
    {
      path: "/admin/courses/:id",
      element: <AdminGuard element={SingleCourse} />,
      loader: async (params: any) => {
        const response = await axiosAuthInstance.get(`${ApiConfig.ROOT_URL}/courses/${params.params.id}`); // Chiamo l'API per ottenere il corso prima di renderizzare la pagina
        return response.data; // Ritorno i dati
      },
      errorElement: <Navigate to="/admin/courses" /> // DA AGGIUNGERE A TUTTO 
    },
    { path: "/admin/courses/new", element: <AdminGuard element={SingleCourse} /> },
    { path: "/admin/lives", element: <AdminGuard element={Lives} /> },
    {
      path: "/admin/lives/:id",
      element: <AdminGuard element={SingleLive} />,
      loader: async (params: any) => {
        const response = await axiosAuthInstance.get(`${ApiConfig.ROOT_URL}/lives/${params.params.id}`); // Chiamo l'API per ottenere la live prima di renderizzare la pagina
        return response.data; // Ritorno i dati della live
      },
    },
    { path: "/admin/lives/new", element: <AdminGuard element={SingleLive} /> },
    { path: "/admin/videos", element: <AdminGuard element={Videos} /> },
    {
      path: "/admin/videos/:id",
      element: <AdminGuard element={SingleVideo} />,
      loader: async (params: any) => {
        const response = await axiosAuthInstance.get(`${ApiConfig.ROOT_URL}/videos/${params.params.id}`); // Chiamo l'API per ottenere il video prima di renderizzare la pagina
        return response.data; // Ritorno i dati del video
      },
      errorElement: <Navigate to="/admin/videos" /> // DA AGGIUNGER
    },
    { path: "/admin/videos/new", element: <AdminGuard element={SingleVideo} /> },
    { path: "/admin/quizzes", element: <AdminGuard element={Quizzes} /> },
    {
      path: "/admin/quizzes/:id",
      element: <AdminGuard element={SingleQuiz} />,
      loader: async (params: any) => {
        const response = await axiosAuthInstance.get(`${ApiConfig.ROOT_URL}/quizzes/${params.params.id}`); // Chiamo l'API per ottenere il quiz prima di renderizzare la pagina
        return response.data; // Ritorno i dati del quiz
      },
      errorElement: <Navigate to="/admin/quizzes" /> // DA AGGIUNGER
    },
    { path: "/admin/quizzes/new", element: <AdminGuard element={SingleQuiz} /> },
    { path: "/admin/resources", element: <AdminGuard element={Resources} /> },
    {
      path: "/admin/resources/:id",
      element: <AdminGuard element={SingleResource} />,
      loader: async (params: any) => {
        const response = await axiosAuthInstance.get(`${ApiConfig.ROOT_URL}/resources/${params.params.id}`); // Chiamo l'API per ottenere la risorsa prima di renderizzare la pagina
        return response.data; // Ritorno i dati della risorsa
      },
      errorElement: <Navigate to="/admin/resources" /> // DA AGGIUNGER
    },
    { path: "/admin/resources/new", element: <AdminGuard element={SingleResource} /> },
    { path: "/admin/coupons", element: <AdminGuard element={Coupons} /> },
    {
      path: "/admin/coupons/:id",
      element: <AdminGuard element={SingleCoupon} />,
      loader: async (params: any) => {
        const response = await axiosAuthInstance.get(`${ApiConfig.ROOT_URL}/coupons/${params.params.id}`); // Chiamo l'API per ottenere il coupon prima di renderizzare la pagina
        return response.data; // Ritorno i dati del coupon
      },
      errorElement: <Navigate to="/admin/coupons" /> // DA AGGIUNGER
    },
    { path: "/admin/coupons/new", element: <AdminGuard element={SingleCoupon} /> },
  ]);

  return (
    <RouterProvider router={routes} /> // Passo le routes al RouterProvider
  )
}

export default App;