import BannerAbbonamento from "../assets/images/banner-abbonamento.jpg";

export const SubscriptionBanner = () => {
    return (
        <div className="bg-black py-12 px-24 w-full flex flex-col items-end justify-end" style={{ backgroundImage: `url(${BannerAbbonamento})`, backgroundSize: 'cover' }}>
            { /* creo una card con informazioni su abbonamento */}
            <div className="bg-white w-60 h-96 flex flex-col items-center justify-center border rounded-lg">
                
            </div>
        </div>
    )
}