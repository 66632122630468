import { useContext, useEffect } from "react";
import PublicLayout from "../layout/PublicLayout";
import { TextDictionary } from "../utils/TextDictionary";
import { useLoaderData, useNavigate } from "react-router-dom";
import { CartContext } from "../core/cartProvider";
import { PrimaryButton } from "../components/PrimaryButton";

const PublicSubscriptions = () => {
    const navigate = useNavigate();

    const subscriptions = useLoaderData() as any;
    const { addToCart, isCourseAlreadyInCart } = useContext(CartContext);

    useEffect(() => {
        console.log(subscriptions);
    }, [subscriptions]);

    return (
        <PublicLayout>
            <div className="max-w-[1400px] flex flex-col w-full h-full items-center justify-center mx-auto my-12 px-4 md:px-12">
                {
                    subscriptions.map((subscription: any) => (
                        <div key={subscription.ID} className="flex flex-col gap-4 border border-black border-opacity-20 p-4">
                            <div className="flex flex-col gap-4">
                                <p className="text-lg font-medium">{subscription.Title}</p>
                                <p className="text-md text-gray-500">{subscription.Description}</p>
                                <div className="flex flex-row gap-2 items-center">
                                    <p className="text-md text-gray-500 line-through">{subscription.RegularPrice.toFixed(2) ?? 0} €</p>
                                    <p className="text-md text-primary">{subscription.DiscountPrice.toFixed(2) ?? 0} €</p>
                                </div>
                            </div>
                            {!subscription.Access.CanView ? (
                                <PrimaryButton text={TextDictionary.ListaCorsi.BottoneAcquista} onClick={() => {
                                    // Controllo se il corso è già nel carrello
                                    if (!isCourseAlreadyInCart(subscription)) { // Se il corso non è nel carrello, lo aggiungo
                                        addToCart(subscription)
                                    }
                                    navigate('/cart');
                                }} />
                            ) : (
                                <PrimaryButton text={TextDictionary.ListaCorsi.SeiGiaAbbonato} onClick={() => {
                                }} disabled/>
                            )}
                        </div>
                    ))
                }
            </div>
        </PublicLayout>
    );
};

export default PublicSubscriptions;