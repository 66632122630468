import { useContext, useEffect, useState } from "react";
import PublicLayout from "../layout/PublicLayout";
import { TextDictionary } from "../utils/TextDictionary";
import { loadStripe, StripeElementsOptions } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";

import { axiosAuthInstance } from "../auth/sso/auth.interceptor";
import { ApiConfig } from "../config/Configuration";
import { Checkout } from "./Checkout";
import { CartContext } from "../core/cartProvider";

const stripePromise = loadStripe('pk_test_51IqDQnK27LvEc94NEVeZ5muNw9rUtcwMayh3jvmvyx5blWCRr5a6Fdri61YwPWrFGCbD1hje5j9E4X6qOPhWA4Gd00ykTHHpng'); // Chiave pubblica di test di Stripe

export interface BeginCheckoutRequest {
    Note?: string;
    OrderRows?: {
        SubscriptionID?: number;
        CourseID?: number;
        Note?: string;
    }[];
    Coupons?: string[];
    IntentID?: string;
}

const Payment = () => {
    const [clientSecret, setClientSecret] = useState<string | null>(null); // Client secret generato dal backend
    const [intentID, setIntentID] = useState<string | null>(null); // ID dell'intent generato dal backend
    const [checkoutBody, setCheckoutBody] = useState<BeginCheckoutRequest | null>(null); // Body della richiesta di checkout
    const { cartItems, getPromoCode } = useContext(CartContext);

    useEffect(() => {
        const beginCheckout = async () => {
            console.log("Inizio processo di checkout");
            if (clientSecret || intentID  || checkoutBody) return; // Se uno dei valori è già stato valorizzato, non fare nulla

            const body: BeginCheckoutRequest = { 
                Note: "Nota dell'ordine",
            };   
            
            // costruisco il body della richiesta di checkout basandomi sul carrello (cartItems)
            body.OrderRows = cartItems.map((product: any) => {
                if (product.Type === 'Course') {
                    return {
                        CourseID: product.ID,
                        Note: "Nota corso"
                    };
                } else if (product.Type === 'Live') {
                    return {
                        LiveID: product.ID,
                        Note: "Nota abbonamento"
                    };
                } else {
                    return {
                        SubscriptionID: product.ID,
                        Note: "Nota abbonamento"
                    };
                }
            });

            body.Coupons = [getPromoCode()]; // Aggiungo il codice promozionale

            try {
                const response = await axiosAuthInstance.post(ApiConfig.ROOT_URL + "/orders/begin-checkout", body);
                console.log("begin checkout response", response);
                
                // OrderID, IntentID, IntentClientSecret

                const { IntentClientSecret, IntentID } = response.data;

                setClientSecret(IntentClientSecret);
                setIntentID(IntentID);
                setCheckoutBody(body);
            } catch (error) {
                console.error("Error during checkout initialization:", error);
            }
        };

        beginCheckout(); // Avvio il processo di checkout

        // Cleanup function
        return () => {
            setClientSecret(null);
            setIntentID(null);
            setCheckoutBody(null);
        };
    }, []);

    const options: StripeElementsOptions | undefined = clientSecret ? { 
        clientSecret: clientSecret,
    } : undefined;

    return (
        options && intentID  && checkoutBody ? ( // Se clientSecret, intentID e checkoutBody sono stati valorizzati correttamente
            <Elements stripe={stripePromise} options={options}>
                <Checkout 
                    intentID={intentID}
                    checkoutBody={checkoutBody}
                />
            </Elements>
        ) : (
            <PublicLayout>
                <div className="flex flex-col items-center justify-center h-full w-full">
                    <h1 className="text-xl font-medium mb-8 text-primary">{TextDictionary.Carrello_Checkout.Inizializzazione}</h1>
                    <div className="animate-spin rounded-full h-8 w-8 border-t-2 border-b-2 border-primary"></div>
                </div>
            </PublicLayout>
        )
    );
};

export default Payment;
