import { TextDictionary } from "../utils/TextDictionary";
import FlessibilitaAssoluta from "../assets/images/flessibilita.png";
import Certificati from "../assets/images/certificati.png";
import Apprendimento from "../assets/images/apprendimento.png";
import AreaRiservata from "../assets/images/area_riservata.png";
import Supporto from "../assets/images/supporto.png";
import { PrimaryButton } from "./PrimaryButton";

export const Vantaggi = () => {
    return (
        <div className="max-w-[1400px] h-auto py-20 px-8">
            <h2 className="text-5xl font-medium text-center text-primary mb-6">{TextDictionary.Home.Vantaggi}</h2>
            <h3 className="text-xl text-center text-black mb-20" dangerouslySetInnerHTML={{ __html: TextDictionary.Home.VantaggiContenuto }}></h3>

            {/* creo una griglia di 2 colonne */}
            <div className="grid grid-cols-1 md:grid-cols-2 gap-20 mt-12 mb-16 text-center md:text-left">
                {/* Flessibilità assoluta */}
                <div className="flex flex-col md:flex-row gap-8 items-center md:items-start">
                    <div className="flex items-center justify-center bg-white rounded-full shadow-md aspect-square h-20 w-20">
                        <img src={FlessibilitaAssoluta} className="w-8" />
                    </div>
                    <div className="flex flex-col gap-2">
                        <h3 className="text-xl font-medium text-primary">{TextDictionary.Home.TitoloFlessibilita}</h3>
                        <span className="text-sm text-black" dangerouslySetInnerHTML={{ __html: TextDictionary.Home.ContenutoFlessibilita }}></span>
                    </div>
                </div>

                {/* Area riservata */}
                <div className="flex flex-col md:flex-row gap-8 items-center md:items-start">
                    <div className="flex items-center justify-center bg-white rounded-full shadow-md aspect-square h-20 w-20">
                        <img src={AreaRiservata} className="w-8" />
                    </div>
                    <div className="flex flex-col gap-2">
                        <h3 className="text-xl font-medium text-primary">{TextDictionary.Home.TitoloAreaRiservata}</h3>
                        <span className="text-sm text-black" dangerouslySetInnerHTML={{ __html: TextDictionary.Home.ContenutoAreaRiservata }}></span>
                    </div>
                </div>

                {/* Apprendimento */}
                <div className="flex flex-col md:flex-row gap-8 items-center md:items-start">
                    <div className="flex items-center justify-center bg-white rounded-full shadow-md aspect-square h-20 w-20">
                        <img src={Apprendimento} className="w-8" />
                    </div>
                    <div className="flex flex-col gap-2">
                        <h3 className="text-xl font-medium text-primary">{TextDictionary.Home.TitoloApprendimento}</h3>
                        <span className="text-sm text-black" dangerouslySetInnerHTML={{ __html: TextDictionary.Home.ContenutoApprendimento }}></span>
                    </div>
                </div>

                {/* Certificati */}
                <div className="flex flex-col md:flex-row gap-8 items-center md:items-start">
                    <div className="flex items-center justify-center bg-white rounded-full shadow-md aspect-square h-20 w-20">
                        <img src={Certificati} className="w-8" />
                    </div>
                    <div className="flex flex-col gap-2">
                        <h3 className="text-xl font-medium text-primary">{TextDictionary.Home.TitoloCertificati}</h3>
                        <span className="text-sm text-black" dangerouslySetInnerHTML={{ __html: TextDictionary.Home.ContenutoCertificati }}></span>
                    </div>
                </div>

                {/* Supporto */}
                <div className="flex flex-col md:flex-row gap-8 items-center md:items-start">
                    <div className="flex items-center justify-center bg-white rounded-full shadow-md aspect-square h-20 w-20">
                        <img src={Supporto} alt="placeholder" className="w-8" />
                    </div>
                    <div className="flex flex-col gap-2">
                        <h3 className="text-xl font-medium text-primary">{TextDictionary.Home.TitoloSupporto}</h3>
                        <span className="text-sm text-black" dangerouslySetInnerHTML={{ __html: TextDictionary.Home.ContenutoSupporto }}></span>
                    </div>
                </div>
            </div>

            <div className="flex items-center justify-center">
                <PrimaryButton text={TextDictionary.Home.CTA_RichiediInformazioni} onClick={() => {
                    console.log("Inizia la formazione");
                }} />
            </div>
        </div>
    )
};