export const TextDictionary = {
    "Home": {
        "Titolo": "Academy",
        "Contenuto": "Accedi a <b>corsi professionali</b> su misura per le tue esigenze, con la libertà di seguire <b>lezioni live o registrate</b>, mettendo in pausa o saltando capitoli come preferisci.",
        "CTA_Formazione": "Inizia la formazione",
        "CTA_Landing": "Come funziona ",
        "CorsiEvidenza": "Corsi in evidenza",
        "ProssimiCorsi": "Prossimi corsi",
        "ScopriTuttiCorsi": "Scopri tutti i corsi",
        "NessunCorso": "Errore nel caricamento dei corsi",
        "Vantaggi": "I vantaggi di Academy",
        "VantaggiContenuto": "Tutto ciò che ti serve per crescere in un’unica soluzione",


        "TitoloFlessibilita": "Flessibilità assoluta",
        "ContenutoFlessibilita": "Visualizza i corsi live o in modalità on demand, quando ti è più comodo, permettendoti di conciliare gli impegni professionali con la formazione.",
        "TitoloAreaRiservata": "Area riservata personalizzata",
        "ContenutoAreaRiservata": "Accedi a tutti i tuoi corsi in un unico luogo monitora i progressi, riprendi dove hai interrotto e gestisci facilmente il tuo percorso formativo.",
        "TitoloApprendimento": "Apprendimento mirato",
        "ContenutoApprendimento": "Alla fine di ogni capitolo, potrai completare test per verificare la tua comprensione e consolidare le tue competenze.",
        "TitoloCertificati": "Certificati e crediti formativi",
        "ContenutoCertificati": "Ottieni attestati di partecipazione che potrai utilizzare per dimostrare le tue nuove competenze, e guadagna crediti formativi riconosciuti.",
        "TitoloSupporto": "Supporto continuo",
        "ContenutoSupporto": "Il nostro team è disponibile per assisterti in ogni fase del tuo percorso formativo, garantendo un’esperienza senza intoppi.",

        "CTA_RichiediInformazioni": "Richiedi informazioni",
    },
    "Profilo": {
        "Titolo": "Profilo personale",
        "MioProfilo": "Il mio profilo",
        "MieiCorsi": "I miei corsi",
        "Certificati": "Certificati",
        "GestioneAbbonamenti": "Gestione abbonamenti",
    },
    "Abbonamenti": {
        "Titolo": "Abbonamenti",
    },
    "ListaCorsi": {
        "Titolo": "Scopri tutti \ni corsi",
        "Sottotitolo": "Corsi di formazione online \nriconosciuti a livello europeo!",
        "CreaAccountGratis": "Registrati",
        "BottoneAcquista": "Acquista ora!",
        "BottoneAbbonati": "Abbonati ora!",
        "SeiGiaAbbonato": "Sei già abbonato",
        "BottoneGuardaLive": "GUARDA LIVE!",
        "BottoneGuardaCorso": "Guarda il corso!",
        "BottoneAccediPerVedere": "Accedi per vedere la live!",
        "ScopriCorso": "Scopri il corso",
        "TestoRegistratiPerAcquistare": "Sei già registrato? Effettua il ",
        

        "LabelLezioni": "Lezioni:",
        "LabelData": "Data:",
        "LabelDurata": "Durata:",
        "LabelDocente": "DOCENTE:",
        "LabelConAbbonamento": "o gratuito con abbonamento",


        "LabelDescrizioneCorso": "Obbiettivo",
        "LabelCosaComprende": "Cosa troverai nel corso",
        "LabelLezioniNelCorso": "Lezioni",

        "CorsiInEvidenza": "Corsi in evidenza",

        "NessunaRisorsa": "Nessuna risorsa collegata al corso",
        "NessunaDescrizione": "Nessuna descrizione disponibile",

        "NessunRisultato": "Nessun risultato",
    },
    "PaginaVideo": {
        "BottoneIniziaQuiz": "Inizia il quiz",
        "LabelAggiornatoAl": "AGGIORNATO AL:",
        "LabelDocente": "DOCENTE:",
        "LabelDocenteLezione": "DOCENTE DELLA LEZIONE:",
        "BottoneIniziaTest": "FAI IL TEST",
        "BottoneProssimaUnita": "Passa alla prossima unità",
        "LabelIntroduzione": "Di cosa parleremo in questa lezione",
        "LabelRisorse": "Scarica il materiale utile per il tuo corso",
        "BottoneScaricaRisorse": "Scarica risorse",
        "LabelRiepilogo": "Riepilogo del corso",

        "LabelRisorseLezione": "Risorse della lezione",
        "LabelNessunaRisorsaLezione": "Nessuna risorsa collegata alla lezione",
        "LabelRisorseCorso": "Risorse del corso",
        "LabelNessunaRisorsaCorso": "Nessuna risorsa collegata al corso",
    },

    "PaginaLive": {
        "BottoneIniziaQuiz": "Inizia il quiz",
        "LabelAggiornatoAl": "DATA:",
        "LabelDocente": "DOCENTE:",
        "LabelDocenteLezione": "DOCENTE DELLA LIVE:",
        "BottoneIniziaTest": "FAI IL TEST",
        "BottoneProssimaUnita": "Passa alla prossima unità",
        "LabelIntroduzione": "Di cosa parleremo in questa live",
        "LabelRisorse": "Scarica il materiale utile per il tuo corso",
        "BottoneScaricaRisorse": "Scarica risorse",
        "LabelRiepilogo": "Riepilogo del corso",

        "LabelRisorseLezione": "Risorse della live",
        "LabelNessunaRisorsaLezione": "Nessuna risorsa collegata alla live",
        "LabelRisorseCorso": "Risorse del corso",
        "LabelNessunaRisorsaCorso": "Nessuna risorsa collegata al live",
    },

    "Carrello_Checkout": {
        "Inizializzazione": "Caricamento in corso...",


        "TitoloCarrello": "Carrello",
        "TitoloCheckout": "Checkout",
        "LabelProdotto": "Prodotto",
        "LabelTotale": "Totale",
        "LabelRiepilogo": "Riepilogo",
        "BottoneApplica": "Applica",
        "BottoneRimuoviProdotto": "Rimuovi prodotto",
        "LabelSubtotale": "Subtotale",
        "PlaceholderCodicePromozionale": "Codice promozionale",
        "BottoneProcediPagamento": "Procedi al pagamento",
        "LabelInformazioniAggiuntive": "Informazioni aggiuntive",
        "LabelAbbonamento": "Abbonamento",

        "LabelDettagliDiFatturazione": "Dettagli di fatturazione",
        "LabelNoteSulTuoOrdine": "Note sul tuo ordine",

        "BottoneEffettuaOrdine": "Effettua ordine",
    },
    "ProfiloUtente": {
        "Titolo": "Il mio profilo",
        "Sottotitolo": "Gestionisci i tuoi dati personali",
        "DatiPersonali": "Dati personali",
        "CambiaPassword": "Cambia password",
        "SalvaModifiche": "Salva modifiche",
        "Sicurezza": "Sicurezza",
    },
    "CorsiUtente": {
        "Titolo": "I miei corsi",
        "Sottotitolo": "Visualizza i corsi a cui sei iscritto",
    },
    "CertificatiUtente": {
        "Titolo": "I miei certificati",
        "Sottotitolo": "Visualizza i certificati ottenuti",
    },
    "GestioneAbbonamenti": {
        "Titolo": "Gestione abbonamenti",
        "Sottotitolo": "Visualizza e gestisci i tuoi abbonamenti",

        "AbbonamentoAttivo": "Abbonamento attivo",
        "Scadenza": "Scadenza",
        "Proprietario": "Proprietario",
        "Dipendenti": "Dipendenti",
    },
    "TabelleDinamiche": {
        "Errore": "Attenzione!",
        "ErroreMessaggio": "Errore nel caricamento dei dati",
        "BottoneRiprova": "Riprova",

        "ConfermaEliminazione": "Sei sicuro di voler eliminare l'elemento?",
        "ConfermaSi": "Si",
        "ConfermaOk": "Ok",
        "ConfermaNo": "Annulla",

        "NessunRisultato": "Nessun risultato",
    },
    "DropdownUtente": {
        "VaiAlProfilo": "Vai al profilo",
        "AreaAmministrativa": "Area amministrativa",
        "Logout": "Logout",
        "MessaggioLogout": "Logout effettuato correttamente",
    },

    "Header": {
        "VoceMenu": "Menu",
        "VoceCorsi": "Corsi",
        "VoceHome": "Home",
        "LabelCategorie": "Categorie",
        "LabelCorsiRegistrati": "Corsi registrati",
        "LabelCorsiLive": "Corsi live",
        "LabelCorsiEvidenza": "Corsi in evidenza",
        "VoceDogana": "Dogana",
        "VoceImportExport": "Import / Export",
        "VoceTrasporti": "Trasporti",
        "VoceVediCorsi": "Vedi tutti i corsi",
        "VoceScopriAbbonamenti": "Abbonamenti",
        "VoceInternationalNews": "International News",
        "VoceNovita": "Novità",
        "VoceProfilo": "Profilo",
        "VoceCarrello": "Carrello",
        "VoceRegistrati": "Crea un account",
        "VoceAccedi": "Accedi",
    },

    "PagineIDP": {
        "Sottotitolo": "Carbognani Academy",
        "TitoloLogin": "Autenticazione",
        "TitoloRegistrazione": "Registrazione",
        "TitoloRecuperoPassword": "Recupero password",
        "TitoloResetPassword": "Reset password",
        "TitoloConfermaRegistrazione": "Conferma registrazione",

        "CampoNome": "Nome",
        "CampoCognome": "Cognome",
        "CampoEmail": "Email",
        "CampoPassword": "Password",
        "CampoConfermaPassword": "Conferma password",

        "CampoObbligatorio": "Campo obbligatorio",

        "BottoneNonHaiAccount": "Non hai un account?",
        "BottoneRegistrati": "Registrati",
        "BottonePasswordDimenticata": "Password dimenticata?",

        "BottoneHaiAccount": "Hai già un account?",
        "BottoneAccedi": "Accedi",

        "BottoneModificaPassword": "Modifica password",
    },

    "Admin": {
        "Salvataggio": {
            "NuovoElemento": "Elemento creato correttamente",
            "ModificaElemento": "Elemento modificato correttamente",
            "ErroreSalvataggio": "Errore durante il salvataggio dell'elemento",

            "DipendenteAssociato": "Dipendente associato correttamente",
            "EliminaDipendente": "Sei sicuro di voler eliminare il dipendente?",
            "Conferma": "Conferma",
            
            "MessaggioModificheInSospeso": "Hai delle modifiche in sospeso, devi prima salvare per proseguire!",
            "AnnullaModifiche": "Annulla modifiche",
            "SalvaModifiche": "Salva modifiche",
            "Annulla": "Annulla",

            "ModificheInSospeso": "Hai delle modifiche in sospeso",
            "ModificheInSospesoTesto": "Vuoi salvare le modifiche prima di creare un nuovo elemento?",
        },

        "Form": {
            "CampoObbligatorio": "Campo obbligatorio",
        },

        "Dropzone": {
            "Testo": "Trascina qui il file o clicca per selezionarlo (max 10 MB)",
            "ErroreDimensioni": "Attenzione, il file supera 10 MB",
        },

        "Tabelle": {
            "Utenti": {
                "Address": "Indirizzo",
                "City": "Città",
                "Company": "Azienda",
                "Country": "Paese",
                "Email": "Email",
                "Enabled": "Abilitato",
                "Group": "Gruppo",
                "ID": "ID",
                "IsEmployee": "Dipendente",
                "IsSubscribed": "Abbonato",
                "Language": "Lingua",
                "LastLogin": "Ultimo accesso",
                "Name": "Nome",
                "Phone": "Telefono",
                "Province": "Provincia",
                "Sdi": "Sdi",
                "Surname": "Cognome",
                "TaxCode": "Codice fiscale",
                "URLImage": "Immagine",
                "VatCode": "Partita IVA",
                "ZIP": "CAP"
            },
            "Corsi": {
                "ID": "ID",
                "Title": "Titolo",
                "Description": "Descrizione",
                "Date": "Data",
                "URLLive": "URL Lezione Live",
                "PreviewVideoURL": "URL Lezione Preview",
                "ShortDescription": "Descrizione breve",
                "Teacher": "Docente",
                "Language": "Lingua",
                "RegularPrice": "Prezzo",
                "DiscountPrice": "Prezzo scontato",
                "ImageURL": "Immagine",
            },
            "Live": {
                "ID": "ID",
                "Title": "Titolo",
                "Description": "Descrizione",
                "Date": "Data",
                "URLLive": "URL Lezione Live",
                "PreviewVideoURL": "URL Lezione Preview",
                "ShortDescription": "Descrizione breve",
                "Teacher": "Docente",
                "Language": "Lingua",
                "RegularPrice": "Prezzo",
                "DiscountPrice": "Prezzo scontato",
                "ImageURL": "Immagine",
            },
            "Quiz": {
                "ID": "ID",
                "Title": "Titolo",
                "Description": "Descrizione",
                "HasFile": "File",
                "ThreesoldScore": "Punteggio minimo",
            },
            "Video": {
                "ID": "ID",
                "Title": "Titolo",
                "ShortDescription": "Descrizione breve",
                "Description": "Descrizione",
                "URLVideo": "URL Lezione",
                "SmallThumb": "Miniatura lezione",
            },
            "Risorse": {
                "ID": "ID",
                "Title": "Titolo",
                "Description": "Descrizione",
                "URI": "URI",
            },
            "Coupon": {
                "ID": "ID",
                "Title": "Codice sconto",
                "Description": "Descrizione",
                "Value": "Importo",
            },
        },

        "Dashboard": {
            "CollegamentoUtenti": "Utenti",
            "CollegamentoCorsi": "Corsi",
            "CollegamentoRisorse": "Risorse",
            "CollegamentoQuiz": "Quiz",
            "CollegamentoCoupons": "Coupons",
            "CollegamentoVideo": "Lezioni",
            "CollegamentoLive": "Live",
        },
        "Utenti": {
            "Titolo": "Utenti",
            "NuovoUtente": "Nuovo utente",
            "ModificaUtente": "Modifica utente",
            "BottoneTornaIndietro": "Torna indietro",
            "BottoneAnnula": "Annulla",
            "BottoneSalva": "Salva",

            "ResetPassword": "Reset password",
            "MessaggioResetPassword": "Sei sicuro di voler proseguire? Verrà inviata una mail all'indirizzo dell'utente con la possibilità di impostare una nuova password.",
            "Conferma": "Conferma",
            "Annulla": "Annulla",
            "ErroreResetPassword": "Errore durante il reset della password",
            "MessaggioResetPasswordInviata": "Email per il reset della password inviata correttamente",

            "CampoNome": "Nome",
            "CampoCognome": "Cognome",
            "CampoAzienda": "Azienda",
            "CampoEmail": "Email",
            "CampoTelefono": "Telefono",

            "CampoEmailProprietario": "Email proprietario",
            "CampoTipoAbbonamento": "Tipo abbonamento",
            "CampoDataScadenza": "Data scadenza",
            "CampoNumeroDipendenti": "Numero dipendenti",
            "ModaleDipendenti": "Dipendenti",
            "NessunDipendente": "Nessun dipendente",

            "CouponAssociati": "Coupon associati",
            "AggiungiCoupon": "Aggiungi coupon",
            "NessunCoupon": "Nessun coupon collegato all'utente",

            "CorrettoSalvataggio": "Utente salvato correttamente",
            "CorrettaModifica": "Utente modificato correttamente",
            "ErroreSalvataggio": "Errore durante il salvataggio dell'utente",
        },
        "Risorse": {
            "Titolo": "Risorse",
            "NuovaRisorsa": "Nuova risorsa",
            "ModificaRisorsa": "Modifica risorsa",
            "BottoneTornaIndietro": "Torna indietro",
            "BottoneAnnula": "Annulla",
            "BottoneSalva": "Salva",

            "CampoNome": "Nome",
            "CampoDescrizione": "Descrizione",
            "CampoFile": "File collegato",

            "CorsiAssociati": "Corsi associati",
            "AggiungiCorsi": "Aggiungi corsi",
            "NessunCorso": "Nessun corso collegato alla risorsa",

            "VideoAssociati": "Lezioni associate",
            "AggiungiVideo": "Aggiungi lezioni",
            "NessunVideo": "Nessuna lezione collegato alla risorsa",

            "CorrettoSalvataggio": "Risorsa salvata correttamente",
            "CorrettaModifica": "Risorsa modificata correttamente",
            "ErroreSalvataggio": "Errore durante il salvataggio della risorsa",
        },
        "Quiz": {
            "Titolo": "Quiz",
            "NuovoQuiz": "Nuovo quiz",
            "ModificaQuiz": "Modifica quiz",
            
            "CampoNome": "Nome",
            "CampoDescrizione": "Descrizione",
            "CampoFile": "Seleziona file",
            "CampoPunteggioMinimo": "Punteggio minimo",

            "AnteprimaQuiz": "Anteprima quiz",
            "NessunQuizCaricato": "Nessun quiz caricato",

            "PlaceholderPunteggioMassimo": "Punteggio massimo",

            "CampoRispostaCorretta": "Risposta corretta",
            "CampoRispostaErrata": "Risposta errata",
            "CampoRispostaVuota": "Risposta vuota",

            "VideoAssociati": "Lezioni associate",
            "AggiungiVideo": "Aggiungi lezione",
            "NessunVideo": "Nessuna lezione collegata al quiz",

            "CorsiAssociati": "Corsi associati",
            "AggiungiCorsi": "Aggiungi corsi",
            "NessunCorso": "Nessun corso collegato al quiz",

            "LiveAssociate": "Live associate",
            "AggiungiLive": "Aggiungi live",
            "NessunaLive": "Nessuna live collegata al quiz",

            "CorrettoSalvataggio": "Quiz salvato correttamente",
            "CorrettaModifica": "Quiz modificato correttamente",
            "ErroreSalvataggio": "Errore durante il salvataggio del quiz",
        },
        "Video": {
            "Titolo": "Lezioni",
            "NuovoVideo": "Nuova lezione",
            "ModificaVideo": "Modifica lezione",
            "BottoneTornaIndietro": "Torna indietro",
            "BottoneAnnula": "Annulla",
            "BottoneSalva": "Salva",

            "CampoTitolo": "Titolo",
            "CampoDescrizione": "Descrizione",
            "CampoDescrizioneBreve": "Descrizione breve",
            "CampoURL": "URL della lezione",
            "URLInseritoNonValido": "URL inserito non valido",


            "CampoQuiz": "Quiz associato",
            "CampoAutore": "Docente",

            "CorsiAssociati": "Corsi associati",
            "AggiungiCorsi": "Aggiungi corsi",
            "NessunCorso": "Nessun corso collegato alla lezione",

            "QuizAssociati": "Quiz associato",
            "AggiungiQuiz": "Aggiungi quiz",
            "NessunQuiz": "Nessun quiz collegato alla lezione",

            "RisorseAssociati": "Risorse associate",
            "AggiungiRisorse": "Aggiungi risorse",
            "NessunaRisorsa": "Nessuna risorsa collegata alla lezione",

            "Autore": "Docente",
            "DataCreazione": "Data creazione",
            "DataModifica": "Ultima modifica",

            "CorrettoSalvataggio": "Lezione salvata correttamente",
            "CorrettaModifica": "Lezione modificata correttamente",
            "ErroreSalvataggio": "Errore durante il salvataggio della lezione",
        },
        "Corsi": {
            "Titolo": "Corsi",
            "NuovoCorso": "Nuovo corso",
            "ModificaCorso": "Modifica corso",
            "BottoneTornaIndietro": "Torna indietro",
            "BottoneAnnula": "Annulla",
            "BottoneSalva": "Salva",

            "CampoTitolo": "Nome",
            "CampoDescrizione": "Descrizione",
            "CampoDescrizioneBreve": "Descrizione breve (max 100 caratteri)",
            "CampoData": "Data",
            "CampoURLLive": "URL Lezione Live",
            "CampoPreview": "URL Lezione Preview",
            "CampoDocente": "Docente",
            "CampoLingua": "Lingua",
            "CampoPrezzo": "Prezzo",
            "CampoPrezzoScontato": "Prezzo scontato",
            "CampoImmagine": "Immagine",
            "CampoDurata": "Durata (max 10 caratteri)",
            "CliccaPerCaricareImmagine": "Clicca per caricare l'immagine (max 10 MB)",
            "RimuoviImmagine": "Rimuovi immagine",

            "LiveAssociata": "Live associata",
            "GeneraNuovaLive": "Genera nuova live",


            "VideoAssociati": "Lezioni associate",
            "AggiungiVideo": "Aggiungi lezione",
            "NessunVideo": "Nessuna lezione collegata al corso",

            "RisorseAssociate": "Risorse associate",
            "AggiungiRisorse": "Aggiungi risorse",
            "NessunaRisorsa": "Nessuna risorsa collegata al corso",

            "QuizAssociato": "Quiz associato",

            "CorrettoSalvataggio": "Corso salvato correttamente",
            "CorrettaModifica": "Corso modificato correttamente",
            "ErroreSalvataggio": "Errore durante il salvataggio del corso",

            "ImmagineSuperaDimensioni": "Attenzione, l'immagine supera 10 MB",
        },
        "Live": {
            "Titolo": "Live",
            "NuovaLive": "Nuova live",
            "ModificaLive": "Modifica live",
            "BottoneTornaIndietro": "Torna indietro",
            "BottoneAnnula": "Annulla",
            "BottoneSalva": "Salva",

            "CampoTitolo": "Nome",
            "CampoDescrizione": "Descrizione",
            "CampoDescrizioneBreve": "Descrizione breve (max 100 caratteri)",
            "CampoData": "Data",
            "CampoURL": "URL Lezione Live",
            "CampoPreview": "URL Lezione Preview",
            "CampoDocente": "Docente",
            "CampoLingua": "Lingua",
            "CampoPrezzo": "Prezzo",
            "CampoPrezzoScontato": "Prezzo scontato",
            "CampoImmagine": "Immagine",
            "CampoDurata": "Durata (max 10 caratteri)",
            "CliccaPerCaricareImmagine": "Clicca per caricare l'immagine (max 10 MB)",
            "RimuoviImmagine": "Rimuovi immagine",

            "RisorseAssociate": "Risorse associate",
            "AggiungiRisorse": "Aggiungi risorse",
            "NessunaRisorsa": "Nessuna risorsa collegata al corso",

            "QuizAssociato": "Quiz associato",
            "CorsoAssociato": "Corso associato",

            "GeneraNuovoCorso": "Genera nuovo corso",

            "CorrettoSalvataggio": "Lezione live salvata correttamente",
            "CorrettaModifica": "Lezione live modificata correttamente",
            "ErroreSalvataggio": "Errore durante il salvataggio della lezione live",

            "ImmagineSuperaDimensioni": "Attenzione, l'immagine supera 10 MB",
        },
        "Coupons": {
            "Titolo": "Coupons",
            "NuovoCoupon": "Nuovo coupon",
            "ModificaCoupon": "Modifica coupon",
            "BottoneTornaIndietro": "Torna indietro",

            "CampoNome": "Codice sconto",
            "CampoDescrizione": "Descrizione",
            "CampoImporto": "Importo del codice promozionale",
            "BottoneGenera": "Genera codice casuale",
            "TipologiaPercentuale": "Percentuale",
            "TipologiaFisso": "Fisso",
            "UtentiAssociati": "Utenti associati",
            "AggiungiUtenti": "Aggiungi utenti",
            "EmailAssociate": "Email associate",
            "AggiungiEmail": "Aggiungi email",

            "NessunDato": "Coupon valido per tutti gli utenti",
            "NessunNuovoUtente": "Nessun nuovo utente da associare",
            "Autore": "Autore",
            "DataCreazione": "Data creazione",
            "DataModifica": "Ultima modifica",

            "BottoneSalva": "Salva",
            "BottoneAnnulla": "Annulla",
            "BottoneSalvaChiudi": "Salva e chiudi",

            "PlaceholderAutore": "Admin",
            "PlaceholderData": "gg/mm/aa",

            "CorrettoSalvataggio": "Coupon salvato correttamente",
            "CorrettaModifica": "Coupon modificato correttamente",
            "ErroreSalvataggio": "Errore durante il salvataggio del coupon",
        }
    },
    "Autenticazione": {
        "Login": {
            "Titolo": "Accedi",
        },
        "Registrazione": {
            "Titolo": "Registrati",
        },
        "RecuperoPassword": {
            "Titolo": "Recupero password",
        },
        "ResetPassword": {
            "Titolo": "Reset password",
        },
    }
}