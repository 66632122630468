const Footer = () => {
    return (
        <footer className="relative bottom-0 flex w-full bg-primary justify-center">
            <div className="flex flex-grow items-center justify-between py-10 px-4 md:px-6 2xl:px-11 max-w-[1400px]">
                <svg version="1.1" id="Livello_1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                    viewBox="0 0 167.44 154.26" xmlSpace="preserve" className="h-16" fill="white">
                    <g>
                        <path className="st0" d="M82.03,133.68c-26.92,0-48.82-21.9-48.82-48.82s21.9-48.82,48.82-48.82c13.04,0,25.3,5.08,34.52,14.3l1.7,1.7
                        l-3.39,3.39l-1.7-1.7c-8.31-8.31-19.37-12.89-31.13-12.89c-24.27,0-44.02,19.75-44.02,44.02s19.75,44.02,44.02,44.02
                        c10.96,0,21.3-3.97,29.39-11.24l-4.35-4.35c-6.92,6.11-15.72,9.45-25.04,9.45c-20.89,0-37.89-17-37.89-37.89s17-37.89,37.89-37.89
                        c10.12,0,19.63,3.94,26.79,11.1c0.94,0.94,0.94,2.46,0,3.39l-7.73,7.73c-0.45,0.45-1.06,0.7-1.7,0.7l0,0c-0.64,0-1.25-0.25-1.7-0.7
                        c-4.18-4.18-9.75-6.49-15.66-6.49c-12.21,0-22.15,9.94-22.15,22.15s9.94,22.15,22.15,22.15c5.92,0,11.48-2.3,15.66-6.49l1.7-1.7
                        l3.39,3.39l-1.7,1.7c-5.09,5.09-11.86,7.89-19.06,7.89c-14.86,0-26.95-12.09-26.95-26.95s12.09-26.95,26.95-26.95
                        c6.39,0,12.45,2.21,17.29,6.27l4.35-4.35c-6.01-5.21-13.61-8.05-21.64-8.05c-18.24,0-33.09,14.84-33.09,33.09
                        c0,18.24,14.84,33.09,33.09,33.09c8.84,0,17.15-3.44,23.4-9.69c0.94-0.94,2.46-0.94,3.39,0l7.73,7.73c0.45,0.45,0.7,1.06,0.7,1.7
                        c0,0.64-0.25,1.25-0.7,1.7C107.33,128.6,95.07,133.68,82.03,133.68z"/>
                    </g>
                </svg>

                <div className="flex flex-col items-center text-white text-sm gap-3">
                    <span>© 2022 Studio Carbognani srl | P.Iva 02085250344 | tutti i diritti riservati</span>
                    <span>Powered by <a href="https://bbsway.it/agency" target="_blank" rel="noreferrer" className="underline">The BB's Way</a></span>
                </div>
                
                {/* icona di linkedin */}
                <svg width="20" height="20" viewBox="0 0 20 20" fill="white"
                    className="cursor-pointer"
                    onClick={() => window.open('https://www.linkedin.com/company/studio-toscano-carbognani/about/?viewAsMember=true', '_blank')}
                ><path d="M5.77,17.89 L5.77,7.17 L2.21,7.17 L2.21,17.89 L5.77,17.89 L5.77,17.89 Z M3.99,5.71 C5.23,5.71 6.01,4.89 6.01,3.86 C5.99,2.8 5.24,2 4.02,2 C2.8,2 2,2.8 2,3.85 C2,4.88 2.77,5.7 3.97,5.7 L3.99,5.7 L3.99,5.71 L3.99,5.71 Z"></path><path d="M7.75,17.89 L11.31,17.89 L11.31,11.9 C11.31,11.58 11.33,11.26 11.43,11.03 C11.69,10.39 12.27,9.73 13.26,9.73 C14.55,9.73 15.06,10.71 15.06,12.15 L15.06,17.89 L18.62,17.89 L18.62,11.74 C18.62,8.45 16.86,6.92 14.52,6.92 C12.6,6.92 11.75,7.99 11.28,8.73 L11.3,8.73 L11.3,7.17 L7.75,7.17 C7.79,8.17 7.75,17.89 7.75,17.89 L7.75,17.89 L7.75,17.89 Z"></path></svg>
            </div>
        </footer>
    );
};

export default Footer;