import { useEffect, useState } from "react";
import Profile from "../Profile";
import { TextDictionary } from "../../utils/TextDictionary";
import { UserService } from "../../services/user.service";
import { AuthUser } from "../../auth/interfaces/user.interface";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { axiosAuthInstance } from "../../auth/sso/auth.interceptor";
import { ApiConfig, AppConfig } from "../../config/Configuration";
import Swal from "sweetalert2";
import { SubscriptionBanner } from "../../components/SubscriptionBanner";
import { EmployeesData } from "../../models/user";

export const UserSubscriptions = () => {
    const navigate = useNavigate();
    const user = UserService.getInstance().user;
    const [isCompilingEmployee, setIsCompilingEmployee] = useState(false);
    const [employees, setEmployees] = useState<EmployeesData[]>([]);
    const [employeesNumber, setEmployeesNumber] = useState<number>(0);

    useEffect(() => {
        console.log('UserSubscriptions');
        if (!user) return;

        if (user.UserSubscriptionData) { // Se questo è valorizzato, vuol dire che l'utente è proprietario di un abbonamento
            setEmployeesNumber(user.UserSubscriptionData[0].EmployeesNumber); // Setto il numero di dipendenti
            setEmployees(user.UserSubscriptionData[0].Employees?.map((employee) => { // Setto i dipendenti
                return {
                    ID: employee.ID,
                    UserID: employee.UserID,
                    Name: employee.Name,
                    Surname: employee.Surname,
                    Email: employee.Email
                };
            }) || []);
        }

    }, [user]);

    const saveEmployee = async (employee: EmployeesData) => {
        if (!employee.Email || !employee.Name || !employee.Surname) {
            return;
        }

        // Chiamo l'endpoint per salvare i dipendenti uno ad uno
        setIsCompilingEmployee(true);

        try {
            // /userSubscriptions/:userSubscriptionID/employee
            const payload = {
                Name: employee.Name,
                Surname: employee.Surname,
                Email: employee.Email
            };

            const response = await axiosAuthInstance.post(`${ApiConfig.ROOT_URL}/userSubscriptions/${user?.UserSubscriptionData?.[0].ID}/employee`, payload);
            console.log("response save", response.data);

            if (response.status >= 200) {
                // Imposto l'ID del dipendente prendendolo dalla response.data
                employee.ID = response.data.UserID;
                employee.UserID = response.data.UserID; // Imposto l'ID del dipendente effettivo
                employee.Name = response.data.Name; // Imposto il nome del dipendente effettivo
                employee.Surname = response.data.Surname; // Imposto il cognome del dipendente effettivo

                const index = employees.findIndex((emp) => emp.Email === employee.Email);
                setEmployees([...employees.slice(0, index), employee, ...employees.slice(index + 1)]); // Sostituisco l'elemento nell'array

                Swal.fire({
                    title: TextDictionary.Admin.Salvataggio.DipendenteAssociato,
                });
            }

            setIsCompilingEmployee(false);
        } catch (error: any) {
            Swal.fire({
                title: TextDictionary.Admin.Salvataggio.ErroreSalvataggio,
                text: error.response.data.message
            });

            setIsCompilingEmployee(false);
        }
    }

    const deleteEmployee = async (employee: EmployeesData) => {
        if (!employee.ID) {
            // Rimuovo l'elemento dall'array
            const index = employees.findIndex((emp) => emp.Email === employee.Email);
            setEmployees([...employees.slice(0, index), ...employees.slice(index + 1)]);
            return;
        }

        // Mostro un avviso di conferma
        const result = await Swal.fire({
            title: TextDictionary.Admin.Salvataggio.EliminaDipendente,
            showCancelButton: true,
            confirmButtonText: TextDictionary.Admin.Salvataggio.Conferma,
            cancelButtonText: TextDictionary.Admin.Salvataggio.Annulla
        });

        if (!result.isConfirmed) {
            return;
        }

        // Chiamo l'endpoint per eliminare il singolo dipendente
        setIsCompilingEmployee(true);
        try {
            // /userSubscriptions/:userSubscriptionID/employee
            const response = await axiosAuthInstance.delete(`${ApiConfig.ROOT_URL}/userSubscriptions/${user?.UserSubscriptionData?.[0].ID}/employee/${employee.ID}`);
            console.log(response.data);


            if (response.status >= 200) {
                const index = employees.findIndex((emp) => emp.Email === employee.Email);
                setEmployees([...employees.slice(0, index), ...employees.slice(index + 1)]); // Elimino l'elemento dall'array
            }

            setIsCompilingEmployee(false);

        } catch (error: any) {
            Swal.fire({
                title: TextDictionary.Admin.Salvataggio.ErroreSalvataggio,
                text: error.response.data.message
            });

            setIsCompilingEmployee(false);
        }
    }


    return (
        <div className="w-full flex flex-col">
            { /* creo un header con il titolo della pagina centrato */}
            <div className="flex flex-col justify-center items-center w-full py-4 border-y border-black border-opacity-10 gap-2 md:border-t-0 border-x md:border-x-0">
                <h1 className="text-black text-lg md:text-2xl font-medium">{TextDictionary.GestioneAbbonamenti.Titolo}</h1>
                <span className="text-black text-opacity-80 text-sm md:text-md">{TextDictionary.GestioneAbbonamenti.Sottotitolo}</span>
            </div>

            {
                !user?.UserSubscriptionEmployeeData && !user?.UserSubscriptionData && ( // Se l'utente non ha abbonamenti
                    <SubscriptionBanner />
                )
            }

            {
                user?.UserSubscriptionEmployeeData && ( // Se l'utente ha un abbonamento attivo come dipendente
                    <div className="flex flex-col gap-4 p-8 mb-4">
                        <span className="text-primary text-lg font-medium">{TextDictionary.GestioneAbbonamenti.AbbonamentoAttivo}</span>
                        <div className="flex flex-col gap-2">
                            <span className="text-black text-opacity-80 text-sm">{user.UserSubscriptionEmployeeData[0].SubscriptionName}</span>
                            <span className="text-black text-opacity-80 text-sm">{user.UserSubscriptionEmployeeData[0].SubscriptionDescription}</span>
                        </div>

                        <div className="flex flex-col gap-4 mb-4">
                            <span className="text-primary text-lg font-medium">{TextDictionary.GestioneAbbonamenti.Scadenza}</span>
                            <span className="text-black">{user.UserSubscriptionEmployeeData[0].ExpireDate}</span>
                        </div>

                        <span className="text-primary text-lg font-medium">{TextDictionary.GestioneAbbonamenti.Proprietario}</span>
                        <a className="text-black underline cursor-pointer"
                            href={`mailto:${user.UserSubscriptionEmployeeData[0].UserSubscriptionOwnerEmail}`}
                        >
                            {user.UserSubscriptionEmployeeData[0].UserSubscriptionOwnerEmail}
                        </a>
                    </div>
                )
            }

            {
                user?.UserSubscriptionData && ( // Se l'utente ha un abbonamento attivo come proprietario
                    <div className="flex flex-col p-8 mb-4">
                        <div className="flex flex-col mb-4 gap-2">
                            <span className="text-primary text-lg font-medium">{TextDictionary.GestioneAbbonamenti.AbbonamentoAttivo}</span>
                            <span className="text-black text-opacity-80 text-xl">{user.UserSubscriptionData[0].SubscriptionName}</span>
                            <span className="text-black text-opacity-80 text-sm">{user.UserSubscriptionData[0].SubscriptionDescription}</span>
                        </div>

                        <div className="flex flex-col mb-4 gap-2">
                            <span className="text-primary text-lg font-medium">{TextDictionary.GestioneAbbonamenti.Scadenza}</span>
                            <span className="text-black">{user.UserSubscriptionData[0].ExpireDate}</span>
                        </div>

                        <div className="flex flex-col mb-4 gap-2">
                            <div className="flex flex-row gap-8 items-center mb-4">
                                <span className="text-primary text-lg font-medium">{TextDictionary.GestioneAbbonamenti.Dipendenti} (max {user.UserSubscriptionData[0].EmployeesNumber})</span>
                                {
                                    employeesNumber && employees.length < employeesNumber && (
                                        <button className="bg-primary text-white rounded-md px-3 py-1"
                                            onClick={() => {
                                                // Aggiungo un nuovo elemento all'array employees con valori nulli
                                                const newEmployees = [...employees];
                                                newEmployees.push({
                                                    ID: null,
                                                    UserID: null,
                                                    Name: '',
                                                    Surname: '',
                                                    Email: ''
                                                });
                                                setEmployees(newEmployees);
                                            }}
                                            disabled={isCompilingEmployee} style={{ opacity: isCompilingEmployee ? 0.5 : 1 }}> +</button>
                                    )}
                            </div>


                            <div className="flex flex-col gap-4">
                                {/* riscrivo iniziando a far si che vengano mostrate le righe solo se sono compilate (disabilitata e con un tasto per rimuoverla). Se clicco col tasto "+" deve aggiungermi una nuova
                                    riga con i campi vuoti e, una volta compilati tutti i campi deve comparire un tasto salva che salva la riga e la disabilita. */}
                                {
                                    employees.length > 0 ? employees.map((employee: EmployeesData, index) => (
                                        employeesNumber && index < employeesNumber && (
                                            <div key={index} className="flex flex-row gap-4">
                                                <input type="text" className="border border-black border-opacity-10 px-4 py-1" value={employee.Name} onChange={(e) => {
                                                    const newEmployees = [...employees];
                                                    newEmployees[index].Name = e.target.value;
                                                    setEmployees(newEmployees);
                                                }} placeholder="Nome"
                                                    disabled={employee.ID ? true : false}
                                                    style={{ backgroundColor: employee.ID ? AppConfig.lightGray : 'white' }}
                                                />
                                                <input type="text" className="border border-black border-opacity-10 px-4 py-1" value={employee.Surname} onChange={(e) => {
                                                    const newEmployees = [...employees];
                                                    newEmployees[index].Surname = e.target.value;
                                                    setEmployees(newEmployees);
                                                }} placeholder="Cognome"
                                                    disabled={employee.ID ? true : false}
                                                    style={{ backgroundColor: employee.ID ? AppConfig.lightGray : 'white' }}
                                                />
                                                <input type="text" className={`border border-black border-opacity-10 px-4 py-1`} value={employee.Email} onChange={(e) => {
                                                    const newEmployees = [...employees];
                                                    newEmployees[index].Email = e.target.value;
                                                    setEmployees(newEmployees);
                                                }} placeholder="Email"
                                                    style={{ backgroundColor: employee.ID ? AppConfig.lightGray : 'white' }}
                                                    disabled={employee.ID ? true : false}
                                                />
                                                {
                                                    employees[index] && (
                                                        <button className="bg-errorRed text-white rounded-md w-20" onClick={() => {
                                                            deleteEmployee(employee);
                                                        }}>X</button>
                                                    )
                                                }
                                                {
                                                    employee.Email && employee.Name && employee.Surname && employee.ID === null && (
                                                        <button className="bg-primary text-white px-4 py-1" onClick={() => {
                                                            saveEmployee(employee)
                                                        }} disabled={isCompilingEmployee} style={{ opacity: isCompilingEmployee ? 0.5 : 1 }}>
                                                            Salva
                                                        </button>
                                                    )
                                                }
                                            </div>)
                                    )) : (
                                        <span>{TextDictionary.Admin.Utenti.NessunDipendente}</span>
                                    )
                                }
                            </div>
                        </div>
                    </div>
                )
            }
        </div>
    );
}
