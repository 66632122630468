import { useEffect, useState } from "react";
import PublicLayout from "../layout/PublicLayout";
import { ProductCard } from "../components/ProductCard";
import { ApiConfig } from "../config/Configuration";
import axios from "axios";
import { useLoaderData } from "react-router-dom";
import { Course } from "../models/course";
import Slider from "react-slick";
import { PrimaryButton } from "../components/PrimaryButton";
import { TextDictionary } from "../utils/TextDictionary";
import { FeauturedProducts } from "../components/FeauturedProducts";

const PublicProducts = () => {
    const products = useLoaderData() as any;

    useEffect(() => {
        console.log("LISTA PRODOTTI", products);
    });

    return (
        <PublicLayout>
            <div className="flex flex-col">
                <div className="flex flex-col md:flex-row justify-between items-center bg-primary w-full">
                    <div className="max-w-[1400px] flex flex-row w-full items-center mx-auto">
                        <div className="flex flex-col justify-center gap-4 flex-1 w-full h-full">
                            <div className="py-6 md:py-0">
                                <h1 className="text-white text-6xl font-medium mb-4 bodoni">{TextDictionary.ListaCorsi.Titolo}</h1>
                                <p className="text-white text-xl mb-8">{TextDictionary.ListaCorsi.Sottotitolo}</p>
                                {/* <button className="bg-customYellow text-primary flex items-center border-0 text-sm font-medium px-8 py-2 rounded-lg w-fit">Crea il tuo account gratis</button> */}
                                <PrimaryButton text={TextDictionary.ListaCorsi.CreaAccountGratis} onClick={() => { }} />
                            </div>
                        </div>
                        <div className="flex flex-col gap-4 flex-1 w-full h-full justify-center">
                            <div className="grid grid-cols-2 gap-4 py-8">
                                <img src="https://placehold.co/400" alt="placeholder" className="w-full h-36 rounded-lg object-cover" />
                                <img src="https://placehold.co/400" alt="placeholder" className="w-full h-36 rounded-lg object-cover" />

                                <img src="https://placehold.co/400" alt="placeholder" className="w-full h-36 rounded-lg object-cover" />
                                <img src="https://placehold.co/400" alt="placeholder" className="w-full h-36 rounded-lg object-cover" />

                                <img src="https://placehold.co/400" alt="placeholder" className="w-full h-36 rounded-lg object-cover" />
                                <img src="https://placehold.co/400" alt="placeholder" className="w-full h-36 rounded-lg object-cover" />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="flex flex-col justify-center items-center w-full py-10 max-w-[1400px] mx-auto">
                    {/* <div className="w-full mb-14">
                        <FeauturedProducts />
                    </div> */}

                    { products.length > 0 ? (
                    <div className="grid xl:grid-cols-3 lg:grid-cols-2 md:grid-cols-2 sm:grid-cols-1 gap-12 w-full">
                        {
                            products.map((product: any) => (
                                <ProductCard key={product.ElementId} element={product} />
                            ))
                        }
                    </div> ) : (
                        <span className="text-xl font-medium mb-12 pt-10 text-black text-opacity-50">{TextDictionary.ListaCorsi.NessunRisultato}</span>
                    )}

                </div>
            </div>
        </PublicLayout>
    );
};

export default PublicProducts;