

import { createContext, useState, useEffect } from 'react';

export const CartContext = createContext();

export const CartProvider = ({ children }) => {
  const [cartItems, setCartItems] = useState(localStorage.getItem('cartItems') ? JSON.parse(localStorage.getItem('cartItems')) : []);
  const [promoCode, setPromoCode] = useState('');

  const addToCart = (item) => {
    console.log('Add to cart', item);
    const isItemInCart = cartItems.find((cartItem) => cartItem.ID === item.ID);

    if (isItemInCart) {
      // Non faccio nulla
      console.log('Elemento già presente nel carrello');
    } else {
      setCartItems([...cartItems, { ...item }]);
    }

    console.log('Cart items', cartItems);
  };

  const removeFromCart = (item) => {
    console.log('Remove from cart', item);
    const isItemInCart = cartItems.find((cartItem) => cartItem.ID === item.ID);

    if (isItemInCart) {
      setCartItems(cartItems.filter((cartItem) => cartItem.ID !== item.ID));
    } else {
      // Non faccio nulla
      console.log('Elemento non presente nel carrello');
    }
  };

  const isCourseAlreadyInCart = (item) => {
    console.log('Check if course is already in cart', item);
    const isItemInCart = cartItems.find((cartItem) => cartItem.ID === item.ID);

    if (isItemInCart) {
      return true;
    } else {
      return false;
    }
  }

  const clearCart = () => {
    console.log('Clear cart');
    setCartItems([]);
  };

  const getCartTotal = () => {
    console.log('Get cart total');
    return cartItems.reduce((total, item) => total + item.DiscountPrice, 0).toFixed(2);
  };

  const getCartLength = () => {
    console.log('Get cart length');
    return cartItems.length;
  };

  const getPromoCode = () => {
    console.log('Get promo code');
    return promoCode;
  };

  const applyPromoCode = (code) => {
    console.log('Set promo code', code);
    setPromoCode(code);
  };

  useEffect(() => {
    const cartString = JSON.stringify(cartItems);
    localStorage.setItem("cartItems", cartString);
  }, [cartItems]);

  useEffect(() => {
    const cartItems = localStorage.getItem("cartItems");
    const cartObject = JSON.parse(cartItems);
    if (cartItems) {
      setCartItems(cartObject);
    }
  }, []);

  return (
    <CartContext.Provider
      value={{
        cartItems,
        addToCart,
        isCourseAlreadyInCart,
        getCartLength,
        removeFromCart,
        clearCart,
        getCartTotal,
        getPromoCode,
        applyPromoCode,
      }}
    >
      {children}
    </CartContext.Provider>
  );
};


