import { useCallback, useEffect, useState } from "react";
import AdminLayout from "../../../layout/AdminLayout";
import { unstable_usePrompt, useLoaderData, useNavigate, useParams } from "react-router-dom";
import { TextDictionary } from "../../../utils/TextDictionary";
import { FloatingBottomToolbar } from "../../../components/FloatingBottomToolbar";
import CustomDropzone from "../../../components/CustomDropzone";
import { Resource } from "../../../models/resource";
import { useForm } from "react-hook-form";
import { saveFunction } from "../../../core/saveFunction";
import Swal from "sweetalert2";
import { axiosAuthInstance } from "../../../auth/sso/auth.interceptor";
import { ApiConfig } from "../../../config/Configuration";
import { get } from "http";

const SingleResource = () => {
    const navigate = useNavigate();
    const { id } = useParams();
    const resource = useLoaderData() as any | null;

    const [isRemovingFile, setIsRemovingFile] = useState(false);

    const { register, handleSubmit, getValues, setValue, formState: { errors, isDirty, isSubmitting }, reset } = useForm<Resource>({
        defaultValues: {
            Title: resource?.Title || '',
            Description: resource?.Description || '',
            GoogleDriveID: resource?.GoogleDriveID || null,
            FileName: resource?.FileName || null,
            FileData: null,
        }
    });
    const [isSaving, setIsSaving] = useState(false);
    const [toggleAlert, setToggleAlert] = useState(false);
    const onSubmit = (data: Resource, saveAndClose?: boolean) => saveFunction(data, id, "resources", "Risorse", setIsSaving, navigate, saveAndClose); // Invoco la funzione di salvataggio 
    // passando i parametri necessari

    useEffect(() => {
        if (Object.keys(errors).length > 0) {
            // Stampo la combinazione [campo]: [messaggio di errore] per ogni errore di validazione
            Swal.fire({
                title: TextDictionary.Admin.Salvataggio.ErroreSalvataggio,
                text: Object.keys(errors).map(key => `${key}: ${(errors as any)[key].message}`).join('\n')
            });
        }
    }, [toggleAlert]);

    useEffect(() => {
        if (resource) {
            reset({
                Title: resource.Title,
                Description: resource.Description,
                GoogleDriveID: resource.GoogleDriveID || null,
                FileName: resource.FileName || null,
                FileData: null,
            });
        }
    }, [resource, reset]);

    useEffect(() => {
        const handleBeforeUnload = (e: BeforeUnloadEvent) => {
            if (isDirty && !isSaving) {
                e.preventDefault();
                e.returnValue = '';
            }
        };

        window.addEventListener('beforeunload', handleBeforeUnload);

        return () => {
            window.removeEventListener('beforeunload', handleBeforeUnload);
        };
    }, [isDirty, isSaving]);

    unstable_usePrompt({
        message: TextDictionary.Admin.Salvataggio.MessaggioModificheInSospeso,
        when: ({ currentLocation, nextLocation }) =>
            isDirty && !isSaving && currentLocation.pathname !== nextLocation.pathname
    });

    const onDrop = useCallback((acceptedFiles: any) => {
        acceptedFiles.map((file: any) => {
            const reader = new FileReader();
            reader.onload = function (e) {
                const data = e.target?.result;
                setValue("FileData", file);
            };

            reader.readAsDataURL(file);
            return file;
        });
    }, []);

    const discard = () => {
        navigate('/admin/resources');
    };

    const handleSave = (saveAndClose?: boolean) => {
        handleSubmit(() => onSubmit(getValues(), saveAndClose))()
            .then((e) => {
                setToggleAlert(!toggleAlert);
            },
                (error) => {
                    const errorMessages = [];
                    for (const key in error.response.data.errors) {
                        errorMessages.push(`${key}: ${error.response.data.errors[key]}`);
                    }
                    Swal.fire({
                        title: TextDictionary.Admin.Salvataggio.ErroreSalvataggio,
                        text: errorMessages.join('\n')
                    });
                }
            );
    }

    const downloadFile = async () => {
        const response = await axiosAuthInstance.get(ApiConfig.ROOT_URL + `/resources/${id}/file`, {
            responseType: 'blob',
        }) as any;

        // faccio il download del file
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const a = document.createElement('a');
        a.href = url;
        a.download = resource.FileName;
        a.click();
        window.URL.revokeObjectURL(url);
    }

    const removeFile = () => {
        setValue("FileData", null);
        setValue("FileName", null);
        setValue("GoogleDriveID", null);

        setIsRemovingFile(false);
    }

    useEffect(() => {
        if (isRemovingFile)
            removeFile();
    }, [isRemovingFile]);


    return (
        <AdminLayout>
            <div className="flex flex-row w-full gap-4">
                <div className="flex flex-col gap-4 flex-1 w-full">
                    <h1 className="text-2xl font-semibold mb-8">{id ? TextDictionary.Admin.Risorse.ModificaRisorsa : TextDictionary.Admin.Risorse.NuovaRisorsa}</h1>

                    <div className="flex flex-row w-full gap-8">
                        <div className="module-card flex flex-col gap-4 w-full">
                            <div className="flex flex-col items-start gap-4 w-full">
                                <div className="flex flex-col gap-4 mb-4 w-full">
                                    <label htmlFor="name" className="font-semibold text-md">{TextDictionary.Admin.Risorse.CampoNome}*</label>
                                    <input type="text" className="border border-black border-opacity-10 w-full px-2 py-1"
                                        {...register("Title", { required: TextDictionary.Admin.Form.CampoObbligatorio })}
                                        aria-invalid={errors.Title ? "true" : "false"}
                                        autoFocus
                                    />
                                    {errors.Title && <p role="alert" className="text-errorRed text-sm">{errors.Title.message}</p>}
                                </div>
                            </div>
                            <div className="flex flex-col gap-4 mb-4 w-full">
                                <label htmlFor="name" className="font-semibold text-md">{TextDictionary.Admin.Risorse.CampoDescrizione}</label>
                                <textarea className="border border-black border-opacity-10 w-full h-32 resize-none px-2"
                                    {...register("Description")}
                                />
                            </div>
                        </div>

                        <div className="flex flex-col gap-4 w-full">
                            <div className="module-card flex flex-col mb-4 w-full">
                                <label htmlFor="name" className="font-semibold text-md pb-4">{TextDictionary.Admin.Risorse.CampoFile}</label>
                                {/* se non è presente FileName, mostro il dropzone per caricare il file */}
                                {!getValues('FileName') && (
                                    <CustomDropzone
                                        onDrop={onDrop}
                                        accept={{}}
                                        maxFiles={1}
                                    />
                                )}

                                {/* se risulta presente FileName, mostro il nome del file, un pulsante per scaricarlo e un pulsante per eliminarlo */}
                                {getValues('FileName') && (
                                    <div className="flex flex-row items-center justify-between mt-4">
                                        <p>{resource.FileName}</p>
                                        <div className="flex flex-row gap-4 items-center">
                                            <button type="button" className="underline" onClick={downloadFile}>Scarica</button>
                                            <button type="button" className="text-errorRed underline" onClick={() => setIsRemovingFile(true)}>Elimina file</button>
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </div>

                <FloatingBottomToolbar onSave={() => {
                    handleSave(false);
                }}
                    onSaveAndClose={() => {
                        handleSave(true);
                    }} onDiscard={() => {
                        discard();
                    }} 
                    isSubmitting={isSubmitting}
                    />
            </div>
        </AdminLayout>
    );
};

export default SingleResource;