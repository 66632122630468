import { PaymentElement, useElements, useStripe } from "@stripe/react-stripe-js";
import PublicLayout from "../layout/PublicLayout";
import { TextDictionary } from "../utils/TextDictionary";
import Swal from "sweetalert2";
import { axiosAuthInstance } from "../auth/sso/auth.interceptor";
import { ApiConfig } from "../config/Configuration";
import { BeginCheckoutRequest } from "./Payment";
import { useNavigate } from "react-router-dom";
import { useContext } from "react";
import { CartContext } from "../core/cartProvider";

interface CompleteCheckoutRequest {
    IntentID: string;
}

interface CheckoutProps {
    intentID: string;
    checkoutBody: BeginCheckoutRequest;
}

const InputField = ({ placeholder, type = "text", ...props }: { placeholder: string; type?: string }) => (
    <input
        type={type}
        placeholder={placeholder}
        className="border border-black border-opacity-20 p-2"
        {...props}
    />
);

const SubtotalElement = ({ id, productName, price, img, type }: { id: number, productName: string; price: number, img: string, type: string }) => {
    const navigate = useNavigate();

    return (
        <div className="flex flex-row justify-between items-center border-b border-black border-opacity-20 p-4">
            <div className="flex flex-row gap-4 items-center">
                <img src={img ? img : 'https://placehold.co/400'} alt="Product" className="w-8 h-8 object-cover cursor-pointer" onClick={() => {
                    if (type === 'Course')
                        navigate(`/public/corsi/${id}`)
                    else if (type === 'Live')
                        navigate(`/public/lives/${id}`)
                    else if (type === 'Subscription')
                        navigate(`/public/abbonamenti/`)
                }} />

                <p className="text-md cursor-pointer"
                    onClick={() => {
                        if (type === 'Course')
                            navigate(`/public/corsi/${id}`)
                        else if (type === 'Live')
                            navigate(`/public/lives/${id}`)
                        else if (type === 'Subscription')
                            navigate(`/public/abbonamenti/`)
                    }}
                >{productName}</p>
            </div>
            <p className="text-md font-medium">{price.toFixed(2)} €</p>
        </div>
    )
}

export const Checkout = ({ intentID, checkoutBody }: CheckoutProps) => {
    const stripe = useStripe();
    const elements = useElements();
    const navigate = useNavigate();

    const { cartItems, getCartTotal, clearCart } = useContext(CartContext);

    const products = cartItems.map((product: any) => ({
        id: product.ID,
        productName: product.Title,
        price: product.DiscountPrice,
        img: product.ImageURL,
        type: product.Type
    }));

    const completeCheckout = async () => {
        const body: CompleteCheckoutRequest = {
            ...checkoutBody,
            IntentID: intentID,
        };

        const response = await axiosAuthInstance.post(ApiConfig.ROOT_URL + '/orders/complete-checkout', body);

        console.log("complete checkout response", response);

        return response;
    }

    const handlePayment = async () => {
        try {
            const checkoutResponse = await completeCheckout();

            if (!stripe || !elements) throw new Error("Stripe non è stato inizializzato correttamente");

            const { error, paymentIntent } = await stripe.confirmPayment({
                elements,
                confirmParams: {
                },
                redirect: 'if_required'
            })

            if (error) { // ERRORE DI STRIPE
                throw new Error(error.message || "Errore durante il pagamento");
            }

            if (paymentIntent?.status === "succeeded") { // STRIPE VA BENE
                try {
                    await axiosAuthInstance.post(ApiConfig.ROOT_URL + '/orders/confirm-checkout', { OrderID: checkoutResponse.data.OrderID });

                    Swal.fire({
                        icon: 'success',
                        title: 'Pagamento completato',
                        text: 'Il pagamento è stato completato con successo',
                    });

                    clearCart();
                    navigate('/profile/courses');
                } catch (error: any) {
                    throw new Error(`Errore durante la conferma dell'ordine: ${error.message}`);
                }
            } else {
                throw new Error(`Errore durante il pagamento: ${paymentIntent?.status}`);
            }

        } catch (error: any) {
            console.error("Error during checkout completion:", error);
            Swal.fire({
                icon: 'error',
                title: 'Errore',
                text: error.response.data.message
            });
        }
    }


    return (
        <PublicLayout>
            <div className="max-w-[1400px] flex flex-col w-full items-start mx-auto my-12 px-4 overflow-y-auto md:px-12">
                <h1 className="text-4xl font-medium mb-12 bodoni">{TextDictionary.Carrello_Checkout.TitoloCheckout}</h1>
                <div className="w-full flex flex-col gap-4 md:flex-row">
                    <div className="w-full md:w-3/5 flex flex-col gap-10">
                        <div className="flex flex-col border border-black border-opacity-20 w-full">
                            <div className="flex flex-row items-center border-b border-black border-opacity-20 p-4">
                                <p className="text-lg font-medium">{TextDictionary.Carrello_Checkout.LabelDettagliDiFatturazione}</p>
                            </div>
                            <div className="flex flex-col gap-4 p-4">
                                <InputField placeholder="Nome" />
                                <InputField placeholder="Cognome" />
                                <InputField placeholder="Nome dell'azienda" />
                                <InputField placeholder="Paese/Regione" />
                                <InputField placeholder="Indirizzo" />
                                <InputField placeholder="Città" />
                                <InputField placeholder="Provincia" />
                                <InputField placeholder="CAP" />
                                <InputField placeholder="Telefono" type="tel" />
                                <InputField placeholder="Indirizzo email" type="email" />
                                <InputField placeholder="Codice fiscale" />
                                <InputField placeholder="Partita IVA" />
                            </div>
                        </div>
                        <div className="flex flex-col border border-black border-opacity-20 p-4 gap-4">
                            <p className="text-lg font-medium">{TextDictionary.Carrello_Checkout.LabelNoteSulTuoOrdine}</p>
                            <textarea
                                placeholder="Note sul tuo ordine"
                                className="border border-black border-opacity-20 p-2 flex-1 resize-none"
                                rows={5}
                            />
                        </div>
                    </div>

                    <div className="w-full md:w-2/5 h-fit flex flex-col gap-8">
                        <div className="flex flex-col border border-black border-opacity-20">
                            <div className="flex flex-row justify-between items-center border-b border-black border-opacity-20 p-4">
                                <p className="text-lg font-medium">{TextDictionary.Carrello_Checkout.LabelProdotto}</p>
                                <p className="text-lg font-medium">{TextDictionary.Carrello_Checkout.LabelSubtotale}</p>
                            </div>
                            {products.map((product: any, index: number) => (
                                <SubtotalElement key={index} {...product} />
                            ))}
                            <div className="flex flex-row justify-between items-center border-b border-black border-opacity-20 p-4">
                                <p className="text-lg font-medium">{TextDictionary.Carrello_Checkout.LabelTotale}</p>
                                <p className="text-lg font-medium">{getCartTotal()} €</p>
                            </div>
                        </div>

                        <div className="flex flex-col gap-4 p-4 border border-black border-opacity-20">
                            <PaymentElement />

                            <button className="bg-primary text-white text-lg font-medium py-2 px-8" onClick={handlePayment} disabled={!stripe}>
                                {TextDictionary.Carrello_Checkout.BottoneEffettuaOrdine}
                            </button>
                        </div>
                    </div>
                </div>
            </div>

        </PublicLayout>
    );
};
