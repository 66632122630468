import { useParams } from "react-router-dom";
import IDPLayout from "../../layout/IDPLayout";
import { TextDictionary } from "../../utils/TextDictionary";
import { useEffect, useState } from "react";
import Swal from "sweetalert2";
import { axiosAuthPublicInstance } from "../../auth/sso/auth.interceptor";
import { ApiConfig } from "../../config/Configuration";
import { axiosLoginInstance } from "../../auth/sso/login.interceptor";

export const ConfirmSignup = () => {
    const [token, setToken] = useState<string | null>(null);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        if (!token) {
            return;
        }

        const confirmAccount = async () => {
            console.log(token);
            try {
                const payload = {
                    Token: token
                };
                console.log(payload);
                const response = await axiosLoginInstance.post(`${ApiConfig.ROOT_URL}/auth/register/verify`, payload);
                if (response.data.Success) {
                    Swal.fire({
                        title: "Account confermato con successo!",
                        text: "Ora puoi effettuare il login.",
                        icon: 'success',
                        showConfirmButton: false,
                        timer: 1500
                    });

                    setLoading(false);
                }
            } catch (error: any) {
                Swal.fire({
                    title: 'Errore in fase di conferma account!',
                    text: error.response.data.message,
                    icon: 'error'
                });
            }
        };

        confirmAccount();
    }, [token]);

    useEffect(() => {
        let params = new URLSearchParams(window.location.search);
        setToken(params.get('token'));
    }, []);

    return (
        <IDPLayout>
            <div className="w-full p-4 sm:p-12.5">
                <span className="mb-1.5 block font-medium bodoni">
                    {TextDictionary.PagineIDP.Sottotitolo}
                </span>
                <h2 className="mb-9 text-3xl font-bold text-black">
                    {TextDictionary.PagineIDP.TitoloConfermaRegistrazione}
                </h2>

                {loading && <p>Conferma in corso...</p>}

                {!loading && <a href="/signin" className="text-primary underline">Vai al login</a>}
            </div>
        </IDPLayout>
    )
};