import { useNavigate } from "react-router-dom";
import { Course } from "../models/course";
import { PrimaryButton } from "./PrimaryButton";
import { TextDictionary } from "../utils/TextDictionary";
import { useEffect, useState } from "react";
import { Live } from "../models/live";

interface ProductCardProps {
    element: any; // Course | Live;
    inverse?: boolean;
    isSliderElem?: boolean;
}

export const ProductCard = ({ element, inverse, isSliderElem = false }: ProductCardProps) => {
    const navigate = useNavigate();

    const formatDate = (date: string) => {
        const d = new Date(date);
        // faccio uno split dopo lo spazio per prendere solo la data
        const splitDate = date.split(' ');
        // sia data che ora aggiungo 0 davanti se sono minori di 10
        return `${splitDate[0]}`;
    }


    return (
        <div className={`flex flex-col h-full rounded-3xl ${inverse ? 'bg-primary' : 'bg-[#f3f3f3]'}` + (isSliderElem ? ' mr-8' : ' mr-0')}> {/* + (isSliderElem ? ' mr-12' : ' mr-0') */}
            <div className="flex flex-col h-48">
                <img src={element.ImageURL && element.ImageURL !== '' ? element.ImageURL : 'https://placehold.co/400'} alt="placeholder" className="object-cover h-48 w-full rounded-t-3xl" />
            </div>
            <div className="flex flex-col h-full px-6 pt-4 w-full">
                <h4 className={`text-xl font-medium text-primary pb-2 ${inverse ? 'text-white' : 'text-primary'} overflow-ellipsis overflow-hidden`}>
                    {element.Title} {element.Type === 'Live' && <span>🔴</span>}
                </h4>
                {/* Aggiungo un divisorio */}
                <div className="border-t-2 my-2 border-customYellow"></div>
                {/* <span
                    dangerouslySetInnerHTML={{ __html: element?.ShortDescription || '' }}
                    className={`text-sm text-gray-500 mb-4 ${inverse ? 'text-white' : 'text-primary'}`}
                /> */}

                {/* riscrivo lo span facendo in modo che occupi tutto lo spazio disponibile */}
                <span
                    dangerouslySetInnerHTML={{ __html: element?.ShortDescription || '' }}
                    className={`text-sm mb-4 ${inverse ? 'text-white text-opacity-80' : 'text-primary'} flex-1`}
                />

                <div className="flex flex-row justify-between items-center mt-4">
                    {element.Type === 'Course' && ( 
                        <h3 className={`text-sm flex ${inverse ? 'text-white' : 'text-gray-500'} `}><span className={`font-medium ${inverse ? 'text-customYellow' : 'text-primary'}`}>{TextDictionary.ListaCorsi.LabelLezioni}</span> {element.VideoNumber || 0}</h3>
                    )}
                    <h3 className={`text-sm flex ${inverse ? 'text-white' : 'text-gray-500'}`}><span className={`font-medium ${inverse ? 'text-customYellow' : 'text-primary'}`}>{TextDictionary.ListaCorsi.LabelData}</span> {formatDate(element.Date)}</h3>
                    {
                        // Aggiungo un controllo per visualizzare la durata solo se è presente
                        element.Duration && element.Duration !== '' && (
                            <h3 className={`text-sm flex ${inverse ? 'text-white' : 'text-gray-500'}`}><span className={`font-medium ${inverse ? 'text-customYellow' : 'text-primary'}`}>{TextDictionary.ListaCorsi.LabelDurata}</span> {element.Duration}</h3>
                        )
                    }
                </div>

                <button className={`${inverse ? 'bg-customYellow text-primary' : 'bg-primary text-customYellow'} rounded-[10px] py-4 px-4 mt-4 mb-6 font-medium`} onClick={
                    () => {
                        console.log('Sto cambiando pagina');
                        if (element.Type === 'Live') {
                            navigate(`/public/lives/${element.ID}`);
                        }
                        else if (element.Type === 'Course')
                            navigate(`/public/corsi/${element.ID}`);
                    }
                }>{TextDictionary.ListaCorsi.ScopriCorso}</button>

            </div>
        </div>
    )
}