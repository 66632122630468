import { useEffect, useState } from "react";

import { Link, useNavigate } from "react-router-dom";
import DropdownUser from "./DropdownUser";
import { AppConfig } from "../config/Configuration";
import { TextDictionary } from "../utils/TextDictionary";
import { AuthService } from "../auth/sso/auth.service";
import { PrimaryButton } from "./PrimaryButton";

interface HeaderMobileProps {
    isUserSetted: boolean;
    isAdminArea: boolean;
}

const HeaderMobile = ({ isUserSetted, isAdminArea }: HeaderMobileProps) => {
    const navigate = useNavigate();

    const [menuOpen, setMenuOpen] = useState(false);

    const dialogMenu = () => {
        return (
            <div className="fixed top-0 left-0 w-full h-full bg-primary z-50">
                <div className="flex justify-end p-4">
                    <svg xmlns="http://www.w3.org/2000/svg" className="h-8 w-8 cursor-pointer" fill="none" viewBox="0 0 24 24" stroke="white" onClick={() => setMenuOpen(false)}>
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
                    </svg>
                </div>
                <div className="flex flex-col items-center justify-center h-full">
                    <div className="flex flex-col gap-8">
                        <Link to="/public/corsi" className="text-white font-medium text-lg cursor-pointer">
                            {TextDictionary.Header.VoceCorsi}
                        </Link>
                        <Link to="/subscription-info" className="text-white font-medium text-lg cursor-pointer">
                            {TextDictionary.Header.VoceScopriAbbonamenti}
                        </Link>
                        <Link to="/international-news" className="text-white font-medium text-lg cursor-pointer">
                            {TextDictionary.Header.VoceInternationalNews}
                        </Link>
                        <Link to="/signin" className="text-white font-medium text-lg cursor-pointer">
                            {TextDictionary.Header.VoceAccedi}
                        </Link>
                        <Link to="/signup" className="text-white font-medium text-lg cursor-pointer">
                            {TextDictionary.Header.VoceRegistrati}
                        </Link>
                    </div>
                </div>
            </div>
        )
    }

    return (
        <header className="sticky top-0 flex w-full bg-primary border-0 justify-between items-center h-[100px] lg:hidden py-4" style={{ zIndex: 999 }}>
            {menuOpen && dialogMenu()}
            <img src={AppConfig.brandLogo} alt="logo" className="h-16 ml-4"/>
            <div className="mr-4">
                <svg xmlns="http://www.w3.org/2000/svg" className="h-8 w-8 cursor-pointer" fill="none" viewBox="0 0 24 24" stroke="white" onClick={() => setMenuOpen(!menuOpen)}>
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4 6h16M4 12h16m-7 6h7" />
                </svg>
            </div>
        </header>
    )
};

export default HeaderMobile;
