import { useEffect, useState } from "react";
import { useLoaderData, useNavigate, useParams } from "react-router-dom";
import { Course } from "../models/course";
import { PublicVideo, Video } from "../models/video";
import { axiosAuthInstance } from "../auth/sso/auth.interceptor";
import { ApiConfig, AppConfig } from "../config/Configuration";
import PublicLayout from "../layout/PublicLayout";
import { PrimaryButton } from "../components/PrimaryButton";
import { QuizComponent } from "../components/QuizComponent";
import { Quiz } from "../models/quiz";
import { TextDictionary } from "../utils/TextDictionary";
import Player from '@vimeo/player';

interface QuestionElem { // Da spostare in un file a parte
    id: number;
    title?: string;
    type: 'intro' | 'question';
    duration?: number;
    description?: string;
    question?: string;
    content?: string;
    special?: 'writing' | 'listening';
    answers: AnswerElem[];
}

interface AnswerElem { // Da spostare in un file a parte
    id: number;
    text: string;
    selected?: boolean;
    isCorrect?: boolean;
    points?: number;
}


export const PublicSingleVideo = () => {
    const { video, course } = useLoaderData() as { video: PublicVideo, course: Course };
    const navigate = useNavigate();

    const [showResourcesModal, setShowResourcesModal] = useState(false); // Mostra il modal con le risorse del corso.
    const [videoCompleted, setVideoCompleted] = useState(false); // Indica se il video è stato completato dall'utente.

    const [quizData, setQuizData] = useState<QuestionElem[] | null>(video.QuizData ? video.QuizData : null); // Dati del quiz

    const downloadResource = async (resource: any, index: number, type: 'video' | 'course') => {
        console.log("downloadResource", resource, index, type);

        const resourceElement = document.getElementById(`${type}-resource-${index}`);
        resourceElement?.classList.add("pointer-events-none");
        resourceElement?.classList.add("opacity-50");

        const response = await axiosAuthInstance.get(ApiConfig.ROOT_URL + resource.DownloadLink, {
            responseType: 'blob',
        }) as any;

        console.log("downloadResource", response);

        // faccio il download del file
        const file = window.URL.createObjectURL(new Blob([response.data]));
        const a = document.createElement('a');
        a.href = file;
        a.download = resource.FileName;
        a.click();
        window.URL.revokeObjectURL(file);

        resourceElement?.classList.remove("pointer-events-none");
        resourceElement?.classList.remove("opacity-50");
    }

    useEffect(() => {
        const initializePlayer = async () => {
            console.log('DOM caricato');

            const iframe = document.querySelector('.public-video-iframe-container iframe');
            if (!iframe) {
                console.error('Iframe non trovato');
                return;
            }

            const player = new Player(iframe as HTMLIFrameElement); // Inizializzo il player di Vimeo

            const courseID = course.ID;
            const videoID = video.ID;

            player.on('loaded', async () => { // Quando il video viene caricato
                const currentTime = Math.floor(await player.getCurrentTime());
                try {
                    const url = `${ApiConfig.ROOT_URL}/progresses/course/video`;
                    const payload = {
                        CourseID: courseID,
                        VideoID: videoID,
                        Seconds: currentTime,
                        QuestionEvaluation: []
                    };
                    const response = await axiosAuthInstance.post(url, payload);
                    console.log("loaded", response);

                    if (response.status > 200) {
                        const responseJSON = response.data;
                        player.setCurrentTime(responseJSON.Seconds).catch((error: any) => {
                            console.error('Errore nell\'impostare il tempo di inizio:', error);
                        });


                        if (responseJSON.ProgressStatus === 'quiz_started' || responseJSON.ProgressStatus === 'video_completed')
                            setVideoCompleted(true);
                    }
                } catch (e) {
                    console.log(e);
                }
            });

            player.on('pause', async () => { // Quando il video viene messo in pausa
                const currentTime = Math.floor(await player.getCurrentTime());
                try {
                    const url = `${ApiConfig.ROOT_URL}/progresses/course/video`;
                    const payload = {
                        CourseID: courseID,
                        VideoID: videoID,
                        Seconds: currentTime,
                        QuestionEvaluation: []
                    };
                    const response = await axiosAuthInstance.post(url, payload);
                    console.log('pause', response);
                    const responseJSON = response.data;
                    console.log(responseJSON);
                } catch (e) {
                    console.log(e);
                }
            });

            player.on('ended', async () => { // Evento di fine video
                const currentTime = Math.floor(await player.getCurrentTime());
                try {
                    const url = `${ApiConfig.ROOT_URL}/progresses/course/video`;
                    const payload = {
                        CourseID: courseID,
                        VideoID: videoID,
                        Seconds: currentTime,
                        QuestionEvaluation: []
                    };
                    const response = await axiosAuthInstance.post(url, payload);
                    console.log('ended', response);
                    const responseJSON = response.data;
                    console.log(responseJSON);
                    setVideoCompleted(true);
                } catch (e) {
                    console.log(e);
                }
            });
        };

        initializePlayer();
    }, []);

    const isLastVideo = () => {
        if (!course.VideoData) return false;
        const lastVideo = course.VideoData[course.VideoData.length - 1];
        return lastVideo.ID === video.ID;
    }

    return (
        <PublicLayout>
            <div className="container w-full mx-auto flex flex-col justify-center items-center">
                <div className="flex flex-col w-full h-full max-w-[1400px] mx-auto lg:flex-row my-20 gap-20 px-8">
                    {/* Contenuto principale */}
                    <div className="w-full lg:w-2/3 h-full flex flex-col">
                        <h2 className="text-3xl font-semibold mb-4 text-primary hover:underline cursor-pointer" onClick={() => {
                            navigate(`/public/corsi/${course.ID}`);
                        }}>{course.Title}</h2>
                        <span className="text-md mb-4 text-primary">{TextDictionary.PaginaVideo.LabelAggiornatoAl} {course.Date}</span>
                        <span className="text-md mb-4 text-primary">{TextDictionary.PaginaVideo.LabelDocente} {course.Teacher ? course.Teacher : 'Non specificato'}</span>

                        {/* divisore */}
                        <div className="w-full border-t border-[#686868] mb-10"></div>

                        <div className="w-full flex justify-between items-center mb-4">
                            <h1 className="text-xl font-semibold mb-4 text-primary">{video?.Title}</h1>
                            <span className="text-lg mb-4 text-[#757575] flex gap-2 items-center">
                                <svg width="15" height="15" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M20 10.0009C20 15.5143 15.5143 20 10.0009 20C4.48569 20 5.34919e-08 15.5143 1.19238e-07 10.0009C1.85006e-07 4.48569 4.48574 -1.85006e-07 10.0009 -1.19238e-07C15.5143 -5.34913e-08 20 4.48574 20 10.0009ZM1.14696 10.0009C1.14696 14.8823 5.11803 18.8516 10.0009 18.8516C14.8823 18.8516 18.8516 14.8806 18.8516 10.0009C18.8516 5.11951 14.8806 1.14696 10.0009 1.14696C5.11951 1.14696 1.14696 5.11803 1.14696 10.0009ZM8.25112 6.96889C8.06692 7.07543 7.96399 7.26866 7.96399 7.46729C7.96399 7.56481 7.98927 7.66413 8.04164 7.75442L9.50441 10.2899C9.60734 10.4668 9.79695 10.577 10.001 10.577L16.4191 10.577C16.7369 10.577 16.9934 10.3206 16.9934 10.0027C16.9934 9.68488 16.7369 9.42845 16.4191 9.42845L10.3334 9.42845L9.03679 7.18015C8.87787 6.90566 8.52752 6.81175 8.25305 6.97067L8.25112 6.96889Z" fill="#757575" />
                                </svg>
                                {video?.Duration}
                            </span>
                        </div>

                        <div className="public-video-iframe-container w-full bg-[#F4F4F4] mb-8 aspect-video"
                            dangerouslySetInnerHTML={{ __html: video?.EmbeddedHTML || '' }}
                        ></div>

                        <span className="text-md mb-8 text-primary">{TextDictionary.PaginaVideo.LabelDocenteLezione} {video.Teacher}</span>
                        <div className="w-full flex justify-between items-center mb-10 gap-4 lg:gap-0">


                            <button className={`w-[150px] h-12 rounded-[15px] flex items-center justify-center gap-4 ${videoCompleted ? 'bg-customYellow text-primary' : 'bg-[#D9D9D9] text-[#5F5F5F] cursor-not-allowed'}`} onClick={
                                () => {
                                    if (!videoCompleted) return;

                                    setTimeout(() => {
                                        const quizBlock = document.getElementById('quiz-block');
                                        quizBlock?.scrollIntoView({ behavior: 'smooth' });
                                    }, 200);
                                }
                            } style={{
                                visibility: quizData ? 'visible' : 'hidden'
                            }}>
                                {TextDictionary.PaginaVideo.BottoneIniziaTest}
                                {
                                    videoCompleted ? (
                                        <svg width="20px" height="20px" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M19 9l-7 7-7-7"></path>
                                        </svg>
                                    ) : (
                                        <svg width="20px" height="20px" viewBox="0 0 26 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M7.16667 12.8333V8.16659C7.16667 6.61949 7.78125 5.13576 8.87521 4.0418C9.96917 2.94783 11.4529 2.33325 13 2.33325C14.5471 2.33325 16.0308 2.94783 17.1248 4.0418C18.2188 5.13576 18.8333 6.61949 18.8333 8.16659V12.8333M4.83333 12.8333H21.1667C22.4553 12.8333 23.5 13.8779 23.5 15.1666V23.3333C23.5 24.6219 22.4553 25.6666 21.1667 25.6666H4.83333C3.54467 25.6666 2.5 24.6219 2.5 23.3333V15.1666C2.5 13.8779 3.54467 12.8333 4.83333 12.8333Z" stroke="#5F5F5F" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                        </svg>
                                    )
                                }
                            </button>


                            {/* controllo se c'è un video successivo a quello corrente */}
                            {
                                !isLastVideo() && (
                                    <a
                                        className="text-primary underline font-medium text-sm lg:text-base cursor-pointer"
                                        onClick={() => {
                                            if (!course.VideoData) return;
                                            const currentVideoIndex = course.VideoData.findIndex(item => item.ID === video.ID);
                                            if (currentVideoIndex === -1) return;

                                            const nextVideo = course.VideoData[currentVideoIndex + 1];
                                            window.location.href = `/public/videos/${course.ID}/${nextVideo.ID}`;
                                        }}
                                    >
                                        {TextDictionary.PaginaVideo.BottoneProssimaUnita}
                                    </a>
                                )
                            }
                        </div>

                        <span className="text-primary font-medium mb-4">{TextDictionary.PaginaVideo.LabelIntroduzione}</span>
                        <span dangerouslySetInnerHTML={{ __html: video.Description || '' }} className="text-black" />

                    </div>


                    {/* Modale risorse del corso e della lezione */}
                    {
                        showResourcesModal && (
                            <div className="fixed top-0 left-0 w-full h-full bg-black bg-opacity-50 z-50 flex justify-center items-center">
                                <div className="w-full lg:w-1/3 bg-white p-8 rounded-[20px]">
                                    <div className="w-full flex justify-end items-center">
                                        <button onClick={() => setShowResourcesModal(false)} className="text-primary">
                                            <svg width="20" height="20" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12"></path>
                                            </svg>
                                        </button>
                                    </div>
                                    <div className="w-full mb-8 flex flex-col gap-2">
                                        {/* riscrivo facendo in modo che anche la label venga mostrata solo se ci sono resource con Origin "video" */}
                                        {
                                            video.ResourceData.find(
                                                resource => resource.Origin === 'video'
                                            ) && (
                                                <div className="w-full flex flex-col gap-2">
                                                    <span className="text-primary font-medium text-lg">{TextDictionary.PaginaVideo.LabelRisorseLezione}</span>
                                                    {
                                                        video.ResourceData?.map((resource, index) => {
                                                            if (resource.Origin === 'video') {
                                                                return (
                                                                    <div key={index} className="w-full flex items-center">
                                                                        <span id={"video-resource-" + index} className="text-black underline cursor-pointer" onClick={() => {
                                                                            downloadResource(resource, index, 'video');
                                                                        }}>{resource.Title}</span>
                                                                    </div>
                                                                )
                                                            }
                                                        })
                                                    }
                                                </div>
                                            )
                                        }
                                    </div>
                                    <div className="w-full flex flex-col gap-2">
                                        {
                                            video.ResourceData.find(
                                                resource => resource.Origin === 'course'
                                            ) && (
                                                <div className="w-full flex flex-col gap-2">
                                                    <span className="text-primary font-medium text-lg">{TextDictionary.PaginaVideo.LabelRisorseCorso}</span>
                                                    {
                                                        video.ResourceData?.map((resource, index) => {
                                                            if (resource.Origin === 'course') {
                                                                return (
                                                                    <div key={index} className="w-full flex items-center">
                                                                        <span id={"course-resource-" + index} className="text-black underline cursor-pointer" onClick={() => {
                                                                            downloadResource(resource, index, 'course');
                                                                        }}>{resource.Title}</span>
                                                                    </div>
                                                                )
                                                            }
                                                        })
                                                    }
                                                </div>
                                            )
                                        }
                                    </div>
                                </div>
                            </div>
                        )
                    }

                    {/* Sidebar */}
                    <div className="w-full lg:w-1/3 h-full">
                        <div className="w-full flex flex-col justify-center mb-8">
                            <span className="text-black text-center text-sm">{TextDictionary.PaginaVideo.LabelRisorse}</span>
                            <button className="w-[200px] h-12 bg-customYellow text-primary font-medium rounded-[15px] mt-4 mx-auto flex gap-4 justify-center items-center"
                                onClick={() => setShowResourcesModal(!showResourcesModal)}
                            >
                                <svg width="20px" height="20px" fill={AppConfig.primaryColor} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 30" x="0px" y="0px"><g data-name="Layer 2"><path d="M22,15.75A1.252,1.252,0,0,0,20.75,17v3a.752.752,0,0,1-.75.75H4A.752.752,0,0,1,3.25,20V17a1.25,1.25,0,0,0-2.5,0v3A3.254,3.254,0,0,0,4,23.25H20A3.254,3.254,0,0,0,23.25,20V17A1.252,1.252,0,0,0,22,15.75Z" /><path d="M12,.75A1.252,1.252,0,0,0,10.75,2V13.331L6.8,10.04A1.251,1.251,0,0,0,5.04,10.2,1.251,1.251,0,0,0,5.2,11.96l6,5a1.269,1.269,0,0,0,.2.116,1.179,1.179,0,0,0,.143.082,1.174,1.174,0,0,0,.911,0,1.3,1.3,0,0,0,.14-.081,1.279,1.279,0,0,0,.2-.116l6-5A1.25,1.25,0,0,0,18,9.75a1.255,1.255,0,0,0-.8.29L13.25,13.331V2A1.252,1.252,0,0,0,12,.75Z" /></g></svg>
                                {TextDictionary.PaginaVideo.BottoneScaricaRisorse}
                            </button>
                        </div>

                        <div className="w-full bg-[#D9D9D9] mb-8 rounded-[20px] p-10">
                            <span className="text-primary font-semibold mb-4 text-xl">{TextDictionary.PaginaVideo.LabelRiepilogo}</span>
                            <div className="w-full mt-6">
                                {
                                    course.VideoData?.map((item, index) => (
                                        <div key={index} className="w-full flex items-center justify-between mb-6">
                                            {/* dovrà contenere un cerchio con border primary e text-primary con il numero del video, il titolo del video, una spunta e una stella con colore bg-customYellow */}
                                            <div className="flex gap-4 items-center w-full">
                                                <div className={`w-6 h-6 bg-transparent text-primary border-primary border font-semibold flex items-center justify-center rounded-full aspect-square ${item.ID === video.ID && 'bg-white border-white'}`} title="Indice del video">{index + 1}</div>
                                                <span className="text-black font-medium overflow-hidden whitespace-nowrap overflow-ellipsis pr-4 hover:underline cursor-pointer"
                                                    title={item.Title}
                                                    onClick={() => {
                                                        navigate(`/public/videos/${course.ID}/${item.ID}`);
                                                    }}
                                                >{item.Title}</span>
                                            </div>
                                            <div className="flex gap-4 items-center">
                                                <span className={`font-medium text-gray-500`} title="Video completato"
                                                    style={{
                                                        visibility: item.IsVideoCompleted ? 'visible' : 'hidden'
                                                    }}
                                                >✓</span>
                                                <svg height="15px" width="15px" version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink"
                                                    viewBox="0 0 53.867 53.867" xmlSpace="preserve" fill='gray'
                                                    style={{
                                                        visibility: item.IsQuizCompleted ? 'visible' : 'hidden'
                                                    }}
                                                >
                                                    <polygon points="26.934,1.318 35.256,18.182 53.867,20.887 40.4,34.013 43.579,52.549 26.934,43.798 
                                                    10.288,52.549 13.467,34.013 0,20.887 18.611,18.182 "/>
                                                </svg>

                                            </div>
                                        </div>
                                    ))
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {quizData && videoCompleted && course.ID && (<QuizComponent quizData={quizData} videoID={video.ID} courseID={course.ID} videoList={course.VideoData} />)}
        </PublicLayout>
    )
}