import axios, { AxiosResponse } from "axios";
import { AuthService } from "./auth.service";
import { AuthUtils } from "./auth.utils";
import { ApiConfig } from "../../config/Configuration";

export enum TokenStatus {
  Unknown = 0,
  Ok = 1,
  EsisteMaScaduto = 2,
  Ko = 3
}

const refreshUrl = `${ApiConfig.ROOT_URL ?? ""}${ApiConfig.REFRESH_URL ?? ""}`;

const axiosAuthInstance = axios.create();
const axiosAuthPublicInstance = axios.create(); // pagina dei corsi, pagina del corso, lista video nella pagina del corso

function getTokenStatus() {
  const accessToken = AuthService.getInstance().accessToken;

  let tokenStatus = TokenStatus.Unknown;
  if (!accessToken) {
    tokenStatus = TokenStatus.Ko;
  } else {
    tokenStatus = AuthUtils.isTokenExpired(accessToken) ? TokenStatus.EsisteMaScaduto : TokenStatus.Ok;
  }

  return tokenStatus;
}

async function refreshAccessToken() {
  const response = await axios.get(refreshUrl, {
    headers: {
      'Authorization': `Bearer ${AuthService.getInstance().accessToken}`
    }
  });

  AuthService.getInstance().accessToken = response.data.Token as string;
  return response.data.Token as string;
}

axiosAuthInstance.interceptors.request.use(async config => {
  const accessTokenStatus = getTokenStatus();
  let accessToken = AuthService.getInstance().accessToken;

  if (accessTokenStatus === TokenStatus.Ko) {
    throw new Error("No token");
  }

  if (accessTokenStatus === TokenStatus.EsisteMaScaduto) {
      accessToken = await refreshAccessToken();
  }

  config.headers["Authorization"] = `Bearer ${accessToken}`;
  return config;
});

axiosAuthPublicInstance.interceptors.request.use(async config => {
  const accessTokenStatus = getTokenStatus();
  let accessToken = AuthService.getInstance().accessToken;

  if (accessTokenStatus === TokenStatus.Ko) {
    return config;
  }

  if (accessTokenStatus === TokenStatus.EsisteMaScaduto) {
    accessToken = await refreshAccessToken();
  }

  config.headers["Authorization"] = `Bearer ${accessToken}`;
  return config;

});


export { axiosAuthInstance, axiosAuthPublicInstance };
