import { Link, useNavigate } from 'react-router-dom';
import { useState } from 'react';
import { AuthService } from '../../auth/sso/auth.service';
import IDPLayout from '../../layout/IDPLayout';
import { useForm } from 'react-hook-form';
import { User } from '../../auth/sso/auth.types';
import { saveFunction } from '../../core/saveFunction';
import Swal from 'sweetalert2';
import { TextDictionary } from '../../utils/TextDictionary';


const Signup = () => {
    const { register, handleSubmit, formState: { errors } } = useForm<User>({
        defaultValues: {
            FirstName: '',
            LastName: '',
            Email: '',
            Password: ''
        }
    });

    const onSubmit = (data: User) => submitSignup(data);

    const authService = AuthService.getInstance();
    const navigate = useNavigate();

    const submitSignup = async (data: User) => {
        const signupPayload = {
            Name: data.FirstName,
            Surname: data.LastName,
            Email: data.Email,
            Password: data.Password
        };

        try {
            const response = await authService.signUp(signupPayload);
            if (response) {
                Swal.fire({
                    title: "Registrazione avvenuta con successo!",
                    text: "Riceverai una mail per confermare il tuo account.",
                    icon: 'success',
                    showConfirmButton: false,
                    timer: 1500
                });

                navigate('/signin'); // Reindirizzo alla home (DA MODIFICARE CON LA PAGINA DOVE REINDIRIZZARE DOPO IL LOGIN)
            }
        } catch (error: any) {
            console.error("---- signup error", error);

            Swal.fire({
                title: 'Errore in fase di registrazione!',
                text: error.response.data.message,
                icon: 'error'
            });
        }
    };

    return (
        <IDPLayout>
            <div className="w-full p-4 sm:p-12.5">
                <span className="mb-1.5 block font-medium bodoni">
                    {TextDictionary.PagineIDP.Sottotitolo}
                </span>
                <h2 className="mb-9 text-3xl font-bold text-black">
                    {TextDictionary.PagineIDP.TitoloRegistrazione}
                </h2>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="mb-8">
                        <label className="mb-2.5 block font-medium text-black ">
                            {TextDictionary.PagineIDP.CampoNome}
                        </label>
                        <div className="relative">
                            <input
                                type="text"
                                placeholder="Inserisci il tuo nome"
                                className="w-full rounded-lg border border-stroke bg-transparent mb-2 py-4 pl-6 pr-10 outline-none focus:border-primary"
                                {...register('FirstName', { required: TextDictionary.PagineIDP.CampoObbligatorio })}
                            />
                            {errors.FirstName && <p className="absolute text-errorRed text-sm">{errors.FirstName.message}</p>}
                        </div>
                    </div>
                    <div className="mb-8">
                        <label className="mb-2.5 block font-medium text-black">
                            {TextDictionary.PagineIDP.CampoCognome}
                        </label>
                        <div className="relative">
                            <input
                                type="text"
                                placeholder="Inserisci il tuo cognome"
                                className="w-full rounded-lg border border-stroke bg-transparent mb-2 py-4 pl-6 pr-10 outline-none focus:border-primary"
                                {...register('LastName', { required: TextDictionary.PagineIDP.CampoObbligatorio })}
                            />
                            {errors.LastName && <p className="absolute text-errorRed text-sm">{errors.LastName.message}</p>}
                        </div>
                    </div>
                    <div className="mb-8">
                        <label className="mb-2.5 block font-medium text-black">
                            {TextDictionary.PagineIDP.CampoEmail}
                        </label>
                        <div className="relative">
                            <input
                                type="email"
                                placeholder="Inserisci la tua email"
                                className="w-full rounded-lg border border-stroke bg-transparent mb-2 py-4 pl-6 pr-10 outline-none focus:border-primary"
                                {...register('Email', { required: TextDictionary.PagineIDP.CampoObbligatorio })}
                            />
                            {errors.Email && <p className="absolute text-errorRed text-sm">{errors.Email.message}</p>}
                        </div>
                    </div>
                    <div className="mb-10">
                        <label className="mb-2.5 block font-medium text-black">
                            {TextDictionary.PagineIDP.CampoPassword}
                        </label>
                        <div className="relative">
                            <input
                                type="password"
                                placeholder="**********"
                                className="w-full rounded-lg border border-stroke bg-transparent mb-2 py-4 pl-6 pr-10 outline-none focus:border-primary"
                                {...register('Password', { required: TextDictionary.PagineIDP.CampoObbligatorio })}
                            />
                            {errors.Password && <p className="absolute text-errorRed text-sm">{errors.Password.message}</p>}
                        </div>
                    </div>

                    <div className="mb-5">
                        <input
                            type="submit"
                            value="Registrati"
                            className="w-full cursor-pointer rounded-lg border border-primary bg-primary p-4 text-white transition hover:bg-opacity-90"
                        />
                    </div>

                    <div className="mt-6 flex flex-row justify-end">
                        <p>
                            {TextDictionary.PagineIDP.BottoneHaiAccount}{' '}
                            <Link to="/signin" className="text-primary underline">
                                {TextDictionary.PagineIDP.BottoneAccedi}
                            </Link>
                        </p>
                    </div>
                </form>
            </div>

        </IDPLayout>
    );
};

export default Signup;
