import { useCallback, useEffect, useState } from "react";
import AdminLayout from "../../../layout/AdminLayout";
import { unstable_usePrompt, useLoaderData, useNavigate, useParams } from "react-router-dom";
import { RelationHandlerComponent } from "../../../components/RelationHandlerComponent";
import { TextDictionary } from "../../../utils/TextDictionary";
import { FloatingBottomToolbar } from "../../../components/FloatingBottomToolbar";
import CustomDropzone from "../../../components/CustomDropzone";
import { Quiz } from "../../../models/quiz";
import { useForm } from "react-hook-form";
import { saveFunction } from "../../../core/saveFunction";
import Swal from "sweetalert2";
import { AnswerElem, QuestionElem } from "../../../components/QuizComponent";

const SingleQuiz = () => {
    const navigate = useNavigate();
    const { id } = useParams();

    const quiz = useLoaderData() as any | null;
    useEffect(() => {
        console.log("QUIZ", quiz);
    }, [quiz]);

    const { register, handleSubmit, setValue, getValues, formState: { errors, isDirty, isSubmitting }, reset } = useForm<Quiz>({
        defaultValues: {
            Title: quiz?.Title || '',
            Description: quiz?.Description || '',
            Data: quiz?.Data || null,
            VideoIDs: quiz?.VideoIDs || [],
            CourseIDs: quiz?.CourseIDs || [],
            LiveIDs: quiz?.LiveIDs || [],
            CorrectAnswerPoints: quiz?.CorrectAnswerPoints || 2,
            VoidAnswerPoints: quiz?.VoidAnswerPoints || 0,
            WrongAnswerPoints: quiz?.WrongAnswerPoints || -1,
            ThreesoldScore: quiz?.ThreesoldScore || 0,
            MaxScore: quiz?.MaxScore || null,       
        }
    });
    const [isSaving, setIsSaving] = useState(false);
    const [toggleAlert, setToggleAlert] = useState(false);
    const onSubmit = (data: Quiz, saveAndClose?: boolean) => saveFunction(data, id, "quizzes", "Quiz", setIsSaving, navigate, saveAndClose);

    useEffect(() => {
        if (quiz) {
            reset({
                Title: quiz.Title,
                Description: quiz.Description,
                Data: quiz.Data,
                VideoIDs: quiz.VideoIDs,
                CourseIDs: quiz.CourseIDs,
                LiveIDs: quiz.LiveIDs,
                CorrectAnswerPoints: quiz.CorrectAnswerPoints,
                VoidAnswerPoints: quiz.VoidAnswerPoints,
                WrongAnswerPoints: quiz.WrongAnswerPoints,
                ThreesoldScore: quiz.ThreesoldScore || 0,
                MaxScore: quiz.MaxScore || null,
            });
        }
    }, [quiz]);

    useEffect(() => {
        if (Object.keys(errors).length > 0) {
            // Stampo la combinazione [campo]: [messaggio di errore] per ogni errore di validazione
            Swal.fire({
                title: TextDictionary.Admin.Salvataggio.ErroreSalvataggio,
                text: Object.keys(errors).map(key => `${key}: ${(errors as any)[key].message}`).join('\n')
            });
        }
    }, [toggleAlert]);

    useEffect(() => {
        const handleBeforeUnload = (e: BeforeUnloadEvent) => {
            if (isDirty && !isSaving) {
                e.preventDefault();
                e.returnValue = '';
            }
        };

        window.addEventListener('beforeunload', handleBeforeUnload);

        return () => {
            window.removeEventListener('beforeunload', handleBeforeUnload);
        };
    }, [isDirty, isSaving]);

    unstable_usePrompt({
        message: TextDictionary.Admin.Salvataggio.MessaggioModificheInSospeso,
        when: ({ currentLocation, nextLocation }) =>
            isDirty && !isSaving && currentLocation.pathname !== nextLocation.pathname
    });

    const options = {
        videos: {
            endpoint: "/videos",
            element: { quiz },
            dictionaryContext: "Video",
            columsToShow: ["Title", "Description", "URLVideo"],
            dropdownElementAttributes: ["Title"],
            idsAttribute: "VideoIDs",
            leftLabel: TextDictionary.Admin.Quiz.VideoAssociati,
            rightLabel: TextDictionary.Admin.Quiz.AggiungiVideo,
            noDataMessage: TextDictionary.Admin.Quiz.NessunVideo,
            gridName: "videosGrid",
        },
        courses: {
            endpoint: "/courses",
            element: { quiz },
            dictionaryContext: "Corsi",
            columsToShow: ["Title"],
            dropdownElementAttributes: ["Title"],
            idsAttribute: "CourseIDs",
            leftLabel: TextDictionary.Admin.Quiz.CorsiAssociati,
            rightLabel: TextDictionary.Admin.Quiz.AggiungiCorsi,
            noDataMessage: TextDictionary.Admin.Quiz.NessunCorso,
            gridName: "coursesGrid",
        },
        lives: {
            endpoint: "/lives",
            element: { quiz },
            dictionaryContext: "Live",
            columsToShow: ["Title"],
            dropdownElementAttributes: ["Title"],
            idsAttribute: "LiveIDs",
            leftLabel: TextDictionary.Admin.Quiz.LiveAssociate,
            rightLabel: TextDictionary.Admin.Quiz.AggiungiLive,
            noDataMessage: TextDictionary.Admin.Quiz.NessunaLive,
            gridName: "livesGrid",
        }
    }

    const onDrop = useCallback((acceptedFiles: any) => {

        acceptedFiles.map((file: any) => {
            // file.type = "text/csv";
            const reader = new FileReader();

            reader.onload = function (e) {
                setValue("Data", file); // Setto il blob nel campo Data
            };

            reader.readAsDataURL(file);
            return file;
        });
    }, []);


    const handleDiscard = () => {
        // Mostro un avviso se il form è stato modificato
        navigate('/admin/quizzes');
    }

    const handlePreview = () => {

        // Partendo da quiz.Data, devo stampare le seguenti cose:
        // - Titolo del modulo, se presente (quiz.title)
        // - Descrizione del modulo, se presente (quiz.description)
        // - Testo della domanda (quiz.question)
        // - lista di risposte (quiz.answers) con la prima preceduta da un simbolo di spunta

        const objectToReturn = [] as any[];

        const data: QuestionElem[] = quiz.Data; // è un array di domande

        for (const elem of data) {
            const moduleTitle = elem.title ? <h3 className="font-semibold">{elem.title}</h3> : null;
            const moduleDescription = elem.description ? <p>{elem.description}</p> : null;
            const question = elem.question ? <p className="font-semibold">{elem.question}</p> : null;
            let answers = [] as any[];

            if (elem.answers && elem.type !== 'intro') {
                answers = elem.answers.map((answer: any, index: number) => (
                    <div key={index} className="flex flex-row gap-4 items-center">
                        <span>{index === 0 ? '✅' : ''}</span>
                        <p className={index !== 0 ? 'pl-6' : 'pl-0.5'}>{answer.text}</p>
                    </div>
                ));
            }

            objectToReturn.push(
                <div className="flex flex-col gap-4 border-b border-black border-opacity-15 pb-5">
                    {moduleTitle}
                    {moduleDescription}
                    {question}
                    {answers}
                </div>
            );
        }


        return objectToReturn;
    }

    const handleSave = (saveAndClose?: boolean) => {
        handleSubmit(() => onSubmit(getValues(), saveAndClose))()
            .then((e) => {
                setToggleAlert(!toggleAlert);
            },
                (error) => {
                    const errorMessages = [];
                    for (const key in error.response.data.errors) {
                        errorMessages.push(`${key}: ${error.response.data.errors[key]}`);
                    }
                    Swal.fire({
                        title: TextDictionary.Admin.Salvataggio.ErroreSalvataggio,
                        text: errorMessages.join('\n')
                    });
                }
            );
    }


    return (
        <AdminLayout>
            <div className="flex flex-row w-full gap-4">
                <div className="flex flex-col gap-4 flex-1 w-full">
                    <h1 className="text-2xl font-semibold mb-8">{id ? TextDictionary.Admin.Quiz.ModificaQuiz : TextDictionary.Admin.Quiz.NuovoQuiz}</h1>

                    <div className="module-card flex flex-row gap-4 w-full">
                        {/* Nome e importo */}
                        <div className="flex flex-col items-start gap-4 w-full">
                            <div className="flex flex-col mb-4 w-full">
                                <label htmlFor="name" className="font-semibold text-md pb-4">{TextDictionary.Admin.Quiz.CampoNome}*</label>
                                <div className="flex flex-col gap-4">
                                    <input type="text" id="name" className="border border-black border-opacity-10 w-full px-2 py-1"
                                        {...register("Title", { required: TextDictionary.Admin.Form.CampoObbligatorio })}
                                        autoFocus
                                    />
                                    {errors.Title && <p role="alert" className="text-errorRed text-sm">{errors.Title.message}</p>}
                                </div>
                            </div>
                        </div>
                        {/* Descrizione */}
                        <div className="flex flex-col mb-4 w-full">
                            <label htmlFor="description" className="font-semibold text-md pb-4">{TextDictionary.Admin.Quiz.CampoDescrizione}</label>
                            <input className="border border-black border-opacity-10 w-full px-2 py-1"
                                {...register("Description")}
                            />
                        </div>
                    </div>

                    <div className="flex flex-row w-full gap-8">
                        <div className="module-card flex flex-col gap-4 w-full">
                            {/* File da caricare */}
                            <div className="flex flex-col mb-4 w-full">
                                <label htmlFor="name" className="font-semibold text-md pb-4">{TextDictionary.Admin.Quiz.CampoFile}</label>
                                <CustomDropzone
                                    onDrop={onDrop}
                                    // Faccio in modo che venga accettato solo il csv
                                    accept={{
                                        'text/csv': ['.csv']
                                    }}
                                    //        text/csv
                                    maxFiles={1}
                                />
                            </div>

                            {/* Punteggio minimo */}
                            <div className="flex flex-row mb-4 w-full items-center gap-4">
                                <label htmlFor="name" className="font-semibold text-md">{TextDictionary.Admin.Quiz.CampoPunteggioMinimo}</label>
                                <input type="number" className="border border-black border-opacity-10 w-32 px-2 py-1"
                                    {...register("ThreesoldScore", { valueAsNumber: true })}
                                />
                                / <span className="text-sm">{quiz?.MaxScore || TextDictionary.Admin.Quiz.PlaceholderPunteggioMassimo}</span>
                            </div>

                            {/* CorrectAnswerPoints */}
                            <div className="flex flex-row mb-4 w-full items-center gap-4">
                                <label htmlFor="name" className="font-semibold text-md">{TextDictionary.Admin.Quiz.CampoRispostaCorretta}</label>
                                <input type="number" className="border border-black border-opacity-10 w-32 px-2 py-1"
                                    {...register("CorrectAnswerPoints", { valueAsNumber: true })}
                                />
                            </div>

                            {/* VoidAnswerPoints */}
                            <div className="flex flex-row mb-4 w-full items-center gap-4">
                                <label htmlFor="name" className="font-semibold text-md">{TextDictionary.Admin.Quiz.CampoRispostaVuota}</label>
                                <input type="number" className="border border-black border-opacity-10 w-32 px-2 py-1"
                                    {...register("VoidAnswerPoints", { valueAsNumber: true })}
                                />
                            </div>

                            {/* WrongAnswerPoints */}
                            <div className="flex flex-row mb-4 w-full items-center gap-4">
                                <label htmlFor="name" className="font-semibold text-md">{TextDictionary.Admin.Quiz.CampoRispostaErrata}</label>
                                <input type="number" className="border border-black border-opacity-10 w-32 px-2 py-1"
                                    {...register("WrongAnswerPoints", { valueAsNumber: true })}
                                />
                            </div>
                        </div>

                        {/* Anteprima del file */}
                        <div className="module-card flex flex-col gap-4 w-full max-h-[500px]">
                            <label className="font-semibold text-md pb-4">{TextDictionary.Admin.Quiz.AnteprimaQuiz}</label>
                            <div className="overflow-y-auto flex flex-col gap-4">
                                {quiz?.Data && Object.keys(quiz?.Data).length > 0 ? handlePreview() : <p>{TextDictionary.Admin.Quiz.NessunQuizCaricato}</p>}
                            </div>
                        </div>
                    </div>

                    {/* Associazioni da fare:
                        - Corso
                        - Video
                    */}

                    <RelationHandlerComponent
                        options={options.videos}
                        setValue={setValue}
                    />

                    <RelationHandlerComponent
                        options={options.courses}
                        setValue={setValue}
                    />

                    <RelationHandlerComponent
                        options={options.lives}
                        setValue={setValue} 
                    />
                </div>

                {/* Card di salvataggio */}
                <FloatingBottomToolbar onSave={() => {
                    handleSave(false);
                }} 
                onSaveAndClose={() => {
                    handleSave(true);
                }} onDiscard={() => {
                    handleDiscard();
                }} 
                isSubmitting={isSubmitting}
                />
            </div>
        </AdminLayout>
    );
};

export default SingleQuiz;