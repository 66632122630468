import { useEffect, useState } from "react";
import Swal from "sweetalert2";
import { Quiz } from "../models/quiz";
import { ApiConfig, AppConfig } from "../config/Configuration";
import { axiosAuthInstance } from "../auth/sso/auth.interceptor";
import { VideoDataElement } from "../models/course";
import { useNavigate } from "react-router-dom";
export interface QuestionElem {
    id: number;
    title?: string;
    type: 'intro' | 'question';
    duration?: number;
    description?: string;
    question?: string;
    content?: string;
    special?: 'writing' | 'listening';
    answers: AnswerElem[];
}

export interface AnswerElem {
    id: number;
    text: string;
    selected?: boolean;
    isCorrect?: boolean;
    points?: number;
}

export interface FinalDataQuestion {
    question: string;
    answer: string;
}
export interface FinalData {
    [key: string]: FinalDataQuestion[];
}

interface QuizComponentProps {
    quizData: QuestionElem[];
    videoID: number;
    courseID: number;
    videoList?: VideoDataElement[];
}

enum CourseVideoProgressEnum {
    VIDEO_STARTED = 'video_started',
    VIDEO_COMPLETED = 'video_completed',
    QUIZ_STARTED = 'quiz_started',
    QUIZ_FAILED = 'quiz_failed',
    QUIZ_SUCCESSFUL = 'quiz_successful'
}

export const QuizComponent = ({ quizData, videoID, courseID, videoList }: QuizComponentProps) => {
    const [data, setData] = useState<QuestionElem[]>(quizData);
    const [currentModule, setCurrentModule] = useState<number>(-1);
    const [testStarted, setTestStarted] = useState(false);

    const navigate = useNavigate();

    useEffect(() => {
        // Effettuo uno shuffle delle risposte di ogni domanda
        if (quizData) {
            const updatedData = quizData.map((item) => {
                if (item.type === 'question') {
                    const shuffledAnswers = item.answers.sort(() => Math.random() - 0.5);
                    console.log("shuffledAnswers", shuffledAnswers);
                    return {
                        ...item,
                        answers: shuffledAnswers
                    };
                }
                return item;
            });


            setData(updatedData);
        }
    }, [quizData]);


    const renderTitle = (index: number) => { // Funzione che restituisce il titolo del modulo corrente
        const title = data.slice(0, index).reverse().find((elem) => elem.type === 'intro');
        if (title) {
            return <p className='text-[.875em] md:text-xl mb-2 md:mb-8 text-primary uppercase font-medium'>{title.title}</p>;
        }
    };

    const moduleQuestionCounts = () => { // Funzione che restituisce un array con il numero di domande per ogni modulo
        const counts = []; // Array che conterrà il numero di domande per ogni modulo
        let currentCount = 0; // Conteggio corrente delle domande

        data.forEach((item) => {
            if (item.type === 'intro') { // Se l'elemento è di tipo intro, pusho il conteggio corrente nell'array counts e resetto il conteggio
                if (currentCount > 0) {
                    counts.push(currentCount);
                }
                currentCount = 0;
            } else if (item.type === 'question') { // Se l'elemento è di tipo question, incremento il conteggio corrente
                currentCount++;
            }
        });
        counts.push(currentCount); // Pusho l'ultimo conteggio

        return counts;
    };

    const counts = moduleQuestionCounts(); // Array che mappa il numero di domande per ogni modulo (intro)

    const renderProgress = (index: number) => { // Funzione che restituisce il conteggio delle domande per il modulo corrente
        let moduleIndex = -1; // -1 perchè il primo modulo è l'intro
        let questionIndex = 0; // 0 perchè la prima domanda è la prima del primo modulo

        for (let i = 0; i <= index; i++) {
            if (data[i].type === 'intro') { // Se l'elemento è di tipo intro, incremento l'indice del modulo e resetto l'indice della domanda
                moduleIndex++;
                questionIndex = 0;
            } else if (data[i].type === 'question') { // Se l'elemento è di tipo question, incremento l'indice della domanda
                questionIndex++;
            }
        }

        return <p className='text-[.875em] md:text-xl mb-2 md:mb-8 font-medium'>Question {questionIndex} / {counts[moduleIndex]} </p>; // Restituisco il conteggio delle domande per il modulo corrente
    }

    // Funzione che restituisce il numero di domande risposte
    const getAnsweredQuestionsCount = () => {
        return data.filter((item) => item.type === 'question').filter((item) => item.answers.find((answer) => answer.selected)).length;
    }

    // Funzione che restituisce il numero totale di domande
    const getTotalQuestions = () => {
        return data.filter((item) => item.type === 'question').length;
    }

    const handleScroll = (index: number) => { // Funzione che gestisce lo scroll verso la domanda successiva
        if (index + 1 === data.length) {
            const endElement = document.getElementById('end');
            endElement?.style.removeProperty('display');

            const element = document.getElementById('end');
            element?.scrollIntoView({ behavior: 'smooth' });
            return;
        }

        // Rimuovo il display none dalla domanda successiva
        const nextElement = document.getElementById('element-' + (index + 1));
        nextElement?.style.removeProperty('display');

        const element = document.getElementById('element-' + (index + 1));
        element?.scrollIntoView({ behavior: 'smooth' }); // Effettuo uno scroll verso la domanda successiva
    }

    const handleSubmit = async () => { // Funzione che gestisce il submit del quiz
        // STEP 1: COSTRUISCO UN ARRAY CON DENTRO OGGETTI COMPOSTI DA QuestionID e AnswerID
        const evaluationData = data.filter((item) => item.type === 'question').map((item) => {
            const questionID = item.id
            const answerID = item.answers.find((answer) => answer.selected)?.id || -1;

            return {
                QuestionID: questionID,
                AnswerID: answerID
            };
        });

        // STEP 2: CONTATTO IL SERVER PER SAPERE SE IL QUIZ È STATO SUPERATO O MENO
        const url = `${ApiConfig.ROOT_URL}/progresses/course/video/quiz`;
        const evaluationPayload = {
            CourseID: courseID,
            VideoID: videoID,
            QuestionEvaluation: evaluationData
        }

        const evaluationResponse = await axiosAuthInstance.post(url, evaluationPayload);
        console.log("QUIZ EVALUATION response", evaluationResponse);


        // STEP 3: Mostro i risultati del quiz in un alert, differenziati da "quiz_successful" e "quiz_failed" e mostrando lo Score ottenuto / MaxScore
        const score = evaluationResponse.data.Score;
        const maxScore = evaluationResponse.data.MaxScore;
        const result = evaluationResponse.data.Status as CourseVideoProgressEnum;

        const scoreResult = await Swal.fire({
            title: result === CourseVideoProgressEnum.QUIZ_SUCCESSFUL ? 'Hai superato il test' : 'Non hai superato il test',
            text: `Hai ottenuto ${score} punti su ${maxScore}!`,
            icon: result === CourseVideoProgressEnum.QUIZ_SUCCESSFUL ? 'success' : 'error',
            confirmButtonText: 'Okay'
        })

        if (scoreResult.isConfirmed) {
            // Vado al prossimo video
            const currentVideoIndex = videoList?.findIndex((video) => video.ID === videoID);
            if (currentVideoIndex && videoList && currentVideoIndex < videoList.length - 1) {
                const nextVideoID = videoList[currentVideoIndex + 1].ID;
                navigate(`/public/videos/${courseID}/${nextVideoID}`);
            } else {
                // Se non ci sono più video, vado alla pagina del corso
                navigate(`/public/corsi/${courseID}`);
            }
        }
    }

    return (
        <>
            { // h-auto min-h-svh
                // Introduzione al quiz
                <div id="quiz-block" className="h-svh w-full flex flex-col md:flex-row justify-center items-center m-auto px-4 mt-4">
                    <div className='flex flex-col justify-center items-start'>
                        <h1 className='text-5xl md:text-8xl mb-2 md:mb-8 font-extrabold'>Carbognani</h1>
                        <h2 className='text-3xl md:text-4xl mb-2 md:mb-8 italic'>Quiz di conferma conoscenze</h2>

                        <button className='bg-primary rounded-full text-white px-10 py-2 mt-4 flex justify-center items-center'
                            onClick={async () => {
                                // CONTATTO L'API PER SALVARE LO STATO DI PROGRESSO DEL VIDEO, OVVERO CHE IL QUIZ È STATO AVVIATO
                                const url = `${ApiConfig.ROOT_URL}/progresses/course/video/quiz`;
                                const payload = {
                                    CourseID: courseID,
                                    VideoID: videoID,
                                };
                                const response = await axiosAuthInstance.post(url, payload);
                                console.log("QUIZ INIZIATO response", response);


                                const firstElement = document.getElementById('element-0');
                                firstElement?.style.removeProperty('display');

                                const element = document.getElementById('element-0');
                                element?.scrollIntoView({ behavior: 'smooth' });
                            }}
                        >
                            Procedi
                        </button>
                    </div>
                </div>
            }

            {
                data.map((item, index) => { // Mappo tutti gli elementi del quiz
                    return (
                        <div className={`h-svh max-w-[1400px] flex flex-col items-center justify-center m-auto px-4`} id={'element-' + index} style={{
                            display: 'none'
                        }}>
                            <div className="h-[100px]"></div>


                            <div className='flex flex-row gap-2 w-full justify-center items-baseline'>
                                {item.title && <h2 className='text-[1.35em] md:text-[2.25em] lg:text-[2.4em] mb-2 max-w-[1400px] font-bold text-primary'>{item.title}</h2>}
                            </div>

                            {item.description && <p className='text-md md:text-2xl mb-4 max-w-[1400px] text-center'>{item.description.indexOf('<br>') > -1 ? item.description.split('<br>').map(
                                (item, index) => {
                                    return (
                                        <p key={index}>{item}</p>
                                    );
                                }
                            ) : item.description}</p>}

                            <div className='flex flex-row gap-2 w-full justify-start md:justify-center items-baseline pt-[10px]'>
                                {item.type === 'question' && renderProgress(index)}
                                {item.type === 'question' && renderTitle(index)}
                            </div>

                            <div className='flex flex-row gap-2 w-full justify-start md:justify-center items-baseline'>
                                {item.question && (
                                    item.special !== 'writing' ?
                                        <h2 className='text-[1.35em] md:text-[2.25em] lg:text-[2.4em] mb-4 md:mb-8 max-w-[1400px] font-bold text-primary' dangerouslySetInnerHTML={{ __html: item.question }}></h2>
                                        : <h2 className='text-[1.35em] md:text-[1.5em] lg:text-[1.8em] mb-4 md:mb-8 max-w-[1400px] font-bold text-primary' dangerouslySetInnerHTML={{ __html: item.question }}></h2>
                                )}
                            </div>

                            <div className={`flex flex-col gap-6 ${item.type === 'question' && 'border border-primary p-4 rounded-[20px] flex-1 overflow-y-auto w-full'} `}>
                                {
                                    item.answers.map((answer) => {
                                        return (
                                            <button
                                                // riscrivo className con un oggetto per gestire lo stile condizionale basato sul item.type
                                                className={item.type === 'intro' ? 'questionButton bg-primary rounded-full w-full text-white px-10 pt-[10px] pb-[5px] flex justify-center items-center' : `questionButton rounded-[20px] flex-1 text-md md:text-xl w-full px-4 pt-[16px] pb-[10px] flex justify-start items-center text-left border-primary hover:bg-disabledGray border hover:shadow-lg ${answer.selected && 'hover:text-black'}`}
                                                style={
                                                    answer.selected ? {
                                                        backgroundColor: AppConfig.customYellow,
                                                        borderColor: AppConfig.customYellow,
                                                    } : {}
                                                }
                                                onClick={() => {
                                                    if (item.type === 'question') {
                                                        // Imposto selected a false per tutte le risposte della stessa domanda
                                                        const updatedDataA = data.map((elem, i) => {
                                                            if (i === index) {
                                                                elem.answers = elem.answers.map((a) => {
                                                                    return {
                                                                        ...a,
                                                                        selected: false
                                                                    };
                                                                });
                                                            }
                                                            return elem;
                                                        });

                                                        setData(updatedDataA);

                                                        // Imposto selected a true per la risposta selezionata della domanda corrente
                                                        const updatedAnswers = item.answers.map((a) => {
                                                            if (a.text === answer.text) {
                                                                return {
                                                                    ...a,
                                                                    selected: true
                                                                };
                                                            }
                                                            return a;
                                                        });

                                                        const updatedDataB = data.map((elem, i) => {
                                                            if (i === index) {
                                                                elem.answers = updatedAnswers;
                                                            }
                                                            return elem;
                                                        });

                                                        setData(updatedDataB);

                                                        // Gestione replace del placeholder con la risposta selezionata
                                                        const updatedDataC = data.map((elem, i) => {
                                                            if (i === index && elem.question) {
                                                                // Cerco all'interno di elem.question lo span con la classe 'answer-text' e lo sostituisco con la risposta selezionata
                                                                const currentSection = document.getElementById('element-' + index);
                                                                const answerText = currentSection?.querySelector('.answer-text') as HTMLElement;
                                                                if (answerText) {
                                                                    answerText.innerHTML = answer.text;
                                                                    answerText.style.color = AppConfig.customYellow;
                                                                    answerText.style.textDecoration = 'underline';
                                                                    answerText.style.textDecorationThickness = '3px';
                                                                    answerText.style.textUnderlineOffset = '6px';
                                                                }
                                                            }
                                                            return elem;
                                                        });

                                                        setData(updatedDataC);

                                                        // Gestisco il salvataggio dello stato attuale del json e del tempo rimanente nel localStorage
                                                        localStorage.setItem('data-backup', JSON.stringify(updatedDataC));

                                                        handleScroll(index); // Gestisco lo scroll verso la domanda successiva

                                                    }
                                                    else if (item.type === 'intro') {
                                                        // Se l'elemento coincide con l'indice 0, invoco l'evento test_started
                                                        if (index === 0 && !testStarted) {
                                                            setTestStarted(true);
                                                        }

                                                        // Se l'elemento è di tipo intro, faccio scroll verso l'elemento successivo
                                                        handleScroll(index);

                                                        // Imposto l'indice del modulo corrente
                                                        setCurrentModule(index);
                                                    }
                                                }}
                                            >{answer.text}</button>
                                        );
                                    })
                                }
                            </div>
                            {
                                item.type === 'question' &&
                                <div className='flex flex-row w-full justify-between items-center px-10'>
                                    {index > 0 && (<a className='previousQuestion underline text-md md:text-xl mt-8 mb-4 md:mt-12 cursor-pointer text-primary' onClick={() => {
                                        handleScroll(index - 2);
                                    }}>
                                        Precedente
                                    </a>)}

                                    <a className='skipButton underline text-md md:text-xl mt-8 mb-4 md:mt-12 cursor-pointer text-primary' onClick={() => {
                                        handleScroll(index);
                                    }}>
                                        Salta
                                    </a>
                                </div>
                            }
                        </div>
                    );
                })
            }
            <div className={`h-svh w-full flex flex-col justify-center items-center m-auto px-4 md:px-0`} id='end' style={{
                display: 'none'
            }}>
                <h3 className='text-4xl mb-2 font-bold text-primary'>Hai concluso il quiz!</h3>
                <h4 className='text-xl mb-2 font-normal pb-[50px]'>Hai risposto a {getAnsweredQuestionsCount()} domande su {getTotalQuestions()}
                    &nbsp;
                    <a className='underline cursor-pointer' onClick={() => {
                        const firstQuestionIndex = data.findIndex((item) => item.type === 'question');
                        handleScroll(firstQuestionIndex - 1);
                    }}>Clicca qui per ricontrollare le risposte</a>
                </h4>

                <button className='bg-primary rounded-full text-white px-10 py-2 mt-4 flex justify-center items-center' onClick={handleSubmit}>Concludi</button>
            </div>
        </>
    );
}

export default QuizComponent;
