import { useContext, useEffect, useState } from "react";
import PublicLayout from "../layout/PublicLayout";
import { TextDictionary } from "../utils/TextDictionary";
import { useNavigate } from "react-router-dom";
import { CartContext } from "../core/cartProvider";
import { Course } from "../models/course";
import { AuthService } from "../auth/sso/auth.service";

const Cart = () => {
    const navigate = useNavigate();
    const { cartItems, getCartTotal, removeFromCart, getPromoCode, applyPromoCode } = useContext(CartContext);
    // const [promoCode, setPromoCode] = useState<string>('');

    const ProductCard = (product: any) => {
        return (
            <div className="flex flex-row justify-between items-center p-4 border-b border-black border-opacity-20">
                <div className="flex flex-row gap-4 items-center">
                    <img src={product.ImageURL ? product.ImageURL : 'https://placehold.co/400'} alt="Product" className="w-16 h-16 object-cover cursor-pointer"
                        onClick={() => {
                            if (product.Type === 'Course')
                                navigate(`/public/corsi/${product.ID}`)
                            else if (product.Type === 'Live')
                                navigate(`/public/lives/${product.ID}`)
                        }}
                    />
                    <div>
                        <div className="flex flex-row gap-8 items-center">
                            <p className="text-lg font-medium cursor-pointer hover:underline"
                                onClick={() => {
                                    console.log(product)
                                    if (product.Type === 'Course')
                                        navigate(`/public/corsi/${product.ID}`)
                                    else if (product.Type === 'Live')
                                        navigate(`/public/lives/${product.ID}`)
                                }}
                            >
                                {product.Title} {product.Type === 'Live' && <span>🔴</span>}
                            </p>
                            {product.Type === 'Subscription' && <div className="bg-primary text-white text-xs font-medium py-1 px-2">{TextDictionary.Carrello_Checkout.LabelAbbonamento}</div>}
                        </div>

                        <div className="flex flex-row gap-2 items-center">
                            {/* mostro prezzo vecchio (barrato) e prezzo scontato */}
                            <p className="text-md text-gray-500 line-through">{product.RegularPrice.toFixed(2) ?? 0.00} €</p>
                            <p className="text-md text-primary">{product.DiscountPrice ? product.DiscountPrice.toFixed(2) : 0.00} €</p>
                        </div>

                        {/* Tasto per rimuovere il prodotto dal carrello */}
                        <button className="text-sm font-light underline"
                            onClick={() => removeFromCart(product)}
                        >{TextDictionary.Carrello_Checkout.BottoneRimuoviProdotto}</button>
                    </div>
                </div>
                <p className="text-lg font-medium text-primary">{product.DiscountPrice ? product.DiscountPrice.toFixed(2) : 0.00} €</p>
            </div>
        );
    }

    return (
        <PublicLayout>
            <div className="max-w-[1400px] flex flex-col w-full items-start mx-auto my-12 px-4 overflow-y-auto md:px-12"> {/* Container principale */}
                <h1 className="text-4xl font-medium mb-12 bodoni">{TextDictionary.Carrello_Checkout.TitoloCarrello}</h1>
                <div className="w-full flex flex-col gap-4 md:flex-row"> {/* Contenuto */}
                    <div className="w-full md:w-3/5 border border-black border-opacity-20 flex flex-col"> {/* Prodotti */}
                        {/* Header */}
                        <div className="flex flex-row justify-between items-center border-b border-black border-opacity-20 p-4">
                            <p className="text-lg font-medium">{TextDictionary.Carrello_Checkout.LabelProdotto}</p>
                            <p className="text-lg font-medium">{TextDictionary.Carrello_Checkout.LabelTotale}</p>
                        </div>

                        {/* Lista prodotti, composta da un componente per ogni prodotto */}
                        <div className="flex flex-col gap-4">
                            {cartItems.map((product: Course, index: number) => (
                                <ProductCard key={index} {...product} />
                            ))}
                        </div>
                    </div>
                    <div className="w-full md:w-2/5 h-fit border border-black border-opacity-20 flex flex-col gap-4 p-4"> {/* Sidebar */}
                        <p className="text-lg font-medium">{TextDictionary.Carrello_Checkout.LabelRiepilogo}</p>

                        {/* Codice sconto */}
                        <div className="flex flex-row gap-4 pb-8">
                            <input type="text" placeholder="Codice promozionale" className="border border-black border-opacity-20 p-2 flex-1" 
                                   value={getPromoCode()} 
                                   onChange={(e) => applyPromoCode(e.target.value)} 
                            />
                        </div>

                        {/* Subtotale */}
                        <div className="flex flex-row justify-between items-center">
                            <p className="text-lg font-medium">{TextDictionary.Carrello_Checkout.LabelSubtotale}</p>
                            <p className="text-lg font-semibold">{getCartTotal()} €</p>
                        </div>

                        {/* Divisore */}
                        <div className="border-b border-black border-opacity-20"></div>

                        {/* Spedizione */}

                        {/* Totale */}
                        <div className="flex flex-row justify-between items-center pb-8">
                            <p className="text-2xl font-medium">{TextDictionary.Carrello_Checkout.LabelTotale}</p>
                            <p className="text-2xl font-semibold">{getCartTotal()} €</p>
                        </div>


                        {/* Vai al pagamento */}
                        <button className="bg-primary text-white text-lg font-medium py-2 px-8"
                            onClick={() => {
                                if (AuthService.getInstance().accessToken)
                                    navigate('/payment')
                                else 
                                    navigate('/signin')
                            }}
                            disabled={cartItems.length === 0}
                            style={{ opacity: cartItems.length === 0 || +getCartTotal() === 0 ? 0.5 : 1, cursor: cartItems.length === 0 || +getCartTotal() === 0 ? 'not-allowed' : 'pointer' }}
                        >{TextDictionary.Carrello_Checkout.BottoneProcediPagamento}</button>

                        <div className="border-b border-black border-opacity-20"></div>

                        {/* Informazioni aggiuntive */}
                        <div className="flex flex-col gap-2">
                            <p className="text-lg font-medium mb-4 mt-2">{TextDictionary.Carrello_Checkout.LabelInformazioniAggiuntive}</p>
                            <div className="flex flex-row gap-4 items-center">
                                <img src="https://placehold.co/400" alt="Product" className="w-8 h-8 object-cover" />
                                <p>Informazione aggiuntiva Alpha</p>
                            </div>

                            <div className="flex flex-row gap-4 items-center">
                                <img src="https://placehold.co/400" alt="Product" className="w-8 h-8 object-cover" />
                                <p>Informazione aggiuntiva Beta</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </PublicLayout>
    );
};

export default Cart;