import { useEffect, useState } from "react";
import AdminLayout from "../../../layout/AdminLayout";
import { Link, unstable_usePrompt, useLoaderData, useNavigate, useParams } from "react-router-dom";
import EmptyAvatar from "../../../assets/images/empty-avatar.png";
import { TextDictionary } from "../../../utils/TextDictionary";
import { RelationHandlerComponent } from "../../../components/RelationHandlerComponent";
import { EmployeesData, User } from "../../../models/user";
import axios from "axios";
import { ApiConfig, AppConfig } from "../../../config/Configuration";
import { FloatingBottomToolbar } from "../../../components/FloatingBottomToolbar";
import { Controller, set, useForm } from "react-hook-form";
import { saveFunction } from "../../../core/saveFunction";
import Swal from "sweetalert2";
import { Subscription } from "../../../models/subscription";
import { axiosAuthInstance } from "../../../auth/sso/auth.interceptor";
import Select from 'react-select';

const SingleUser = () => {
    const navigate = useNavigate();
    // Pesco dall'url l'id dell'utente
    const { id } = useParams();
    const user = useLoaderData() as User | null;

    const [imageSrc, setImageSrc] = useState(user?.URLImage || EmptyAvatar);
    const { register, setValue, getValues, watch, control, handleSubmit, formState: { errors, isDirty, isSubmitting }, reset } = useForm<User>({
        defaultValues: {
            Name: user?.Name || '',
            Surname: user?.Surname || '',
            Company: user?.Company || '',
            Email: user?.Email || '',
            Phone: user?.Phone || '',
            Province: user?.Province || '',
            CouponIDs: user?.CouponIDs || [],
            URLImage: user?.URLImage || '',
            UserSubscriptionSettings: {
                SubscriptionID: user?.UserSubscriptionSettings?.SubscriptionID || null,
                ExpireDate: user?.UserSubscriptionSettings?.ExpireDate || null,
                EmployeesNumber: user?.UserSubscriptionSettings?.EmployeesNumber || null
            },
        }
    });
    const [isSaving, setIsSaving] = useState(false);
    const [toggleAlert, setToggleAlert] = useState(false);

    const onSubmit = (data: User, saveAndClose?: boolean) => {
        saveFunction(data, id, "users", "Utenti", setIsSaving, navigate, saveAndClose);
    }
    // const onSubmit = (data: User, saveAndClose?: boolean) => console.log("onSubmitData", data);


    // Variabili per la gestione degli abbonamenti (sia come proprietario che come employee)
    const [ownerEmail, setOwnerEmail] = useState<string>('');
    const [subscriptionName, setSubscriptionName] = useState<string | null>(null);
    const [subscriptions, setSubscriptions] = useState<Subscription[]>([]);

    const [employeesNumber, setEmployeesNumber] = useState<number | null>(null);
    const [employees, setEmployees] = useState<EmployeesData[]>([]);
    const [expirationDate, setExpirationDate] = useState<string | null>(null);

    const [showEmployeeModal, setShowEmployeeModal] = useState(false);
    const [isCompilingEmployee, setIsCompilingEmployee] = useState(false);


    const options = {
        coupons: {
            endpoint: "/coupons",
            element: { user },
            dictionaryContext: "Coupon",
            columsToShow: ["Title", "Description"],
            dropdownElementAttributes: ["Title"],
            idsAttribute: "CouponIDs",
            leftLabel: TextDictionary.Admin.Utenti.CouponAssociati,
            rightLabel: TextDictionary.Admin.Utenti.AggiungiCoupon,
            noDataMessage: TextDictionary.Admin.Utenti.NessunCoupon,
            gridName: "couponsGrid",
        },
    }

    useEffect(() => {
        if (user) {
            reset({
                Name: user.Name,
                Surname: user.Surname,
                Company: user.Company,
                Email: user.Email,
                Phone: user.Phone,
                Province: user.Province,
                CouponIDs: user.CouponIDs,
                URLImage: user.URLImage,
                UserSubscriptionSettings: {
                    SubscriptionID: user.UserSubscriptionData?.[0].SubscriptionID,
                    ExpireDate: user.UserSubscriptionData?.[0].ExpireDate,
                    EmployeesNumber: user.UserSubscriptionData?.[0].EmployeesNumber
                }
                // SubscriptionID: user.SubscriptionID,
            });

            if (user.UserSubscriptionData) { // Se questo è valorizzato, vuol dire che l'utente è proprietario di un abbonamento
                setSubscriptionName(user.UserSubscriptionData[0].SubscriptionName); // Setto il nome dell'abbonamento
                setEmployeesNumber(user.UserSubscriptionData[0].EmployeesNumber); // Setto il numero di dipendenti
                setEmployees(user.UserSubscriptionData[0].Employees?.map((employee) => { // Setto i dipendenti
                    return {
                        ID: employee.ID,
                        UserID: employee.UserID,
                        Name: employee.Name,
                        Surname: employee.Surname,
                        Email: employee.Email
                    };
                }) || []);
                setExpirationDate(user.UserSubscriptionData[0].ExpireDate); // Setto la data di scadenza
            }

            if (user.UserSubscriptionEmployeeData) {
                setOwnerEmail(user.UserSubscriptionEmployeeData[0].UserSubscriptionOwnerEmail); // Setto l'email del proprietario dell'abbonamento
                setSubscriptionName(user.UserSubscriptionEmployeeData[0].SubscriptionName); // Setto il nome dell'abbonamento
                setExpirationDate(user.UserSubscriptionEmployeeData[0].ExpireDate); // Setto la data di scadenza
            }


            // Chiamo l'endpoint per ottenere la lista degli abbonamenti
            const fetchSubscriptions = async () => {

                const response = await axiosAuthInstance.get(`${ApiConfig.ROOT_URL}/subscriptions`);
                console.log("subscriptions", response.data);
                // setSubscriptions(response.data);


                setSubscriptions(response.data);
                // Aggiungo l'opzione " " se l'utente non ha abbonamenti associati con ID null
                setSubscriptions([...response.data, { ID: null, Title: "Non abbonato" }]);

                // Se l'utente non ha abbonamenti associati, setto l'elemento selezionato a null
                if (!user.UserSubscriptionData && !user.UserSubscriptionEmployeeData) {
                    console.log("setto null");
                    setValue('UserSubscriptionSettings.SubscriptionID', null);
                }
            }

            fetchSubscriptions();
        }
    }, [user]);

    useEffect(() => {
        if (Object.keys(errors).length > 0) {
            // Stampo la combinazione [campo]: [messaggio di errore] per ogni errore di validazione
            Swal.fire({
                title: TextDictionary.Admin.Salvataggio.ErroreSalvataggio,
                text: Object.keys(errors).map(key => `${key}: ${(errors as any)[key].message}`).join('\n')
            });
        }
    }, [toggleAlert]);

    useEffect(() => {
        const handleBeforeUnload = (e: BeforeUnloadEvent) => {
            if (isDirty && !isSaving) {
                e.preventDefault();
                e.returnValue = '';
            }
        };

        window.addEventListener('beforeunload', handleBeforeUnload);

        return () => {
            window.removeEventListener('beforeunload', handleBeforeUnload);
        };
    }, [isDirty, isSaving]);

    unstable_usePrompt({
        message: TextDictionary.Admin.Salvataggio.MessaggioModificheInSospeso,
        when: ({ currentLocation, nextLocation }) =>
            isDirty && !isSaving && currentLocation.pathname !== nextLocation.pathname
    });

    const handleDiscard = () => {
        // Mostro un avviso se il form è stato modificato
        navigate('/admin/users');
    }

    const handleRemoveImage = () => {
        setValue('URLImage', EmptyAvatar);
        setImageSrc(EmptyAvatar);
    }

    const handleSave = (saveAndClose?: boolean) => {
        handleSubmit(() => onSubmit(getValues(), saveAndClose))()
            .then((e) => {
                setToggleAlert(!toggleAlert);
            },
                (error) => {
                    const errorMessages = [];
                    for (const key in error.response.data.errors) {
                        errorMessages.push(`${key}: ${error.response.data.errors[key]}`);
                    }
                    Swal.fire({
                        title: TextDictionary.Admin.Salvataggio.ErroreSalvataggio,
                        text: errorMessages.join('\n')
                    });
                }
            );
    }

    const handleEmployeeModal = () => {
        // Faccio in modo che se il form è dirty, mostro un avviso che chiede se salvare le modifiche prima di aprire il modale
        if (isDirty && !isSaving) {
            Swal.fire({
                title: TextDictionary.Admin.Salvataggio.MessaggioModificheInSospeso,
                showCancelButton: true,
                confirmButtonText: TextDictionary.Admin.Salvataggio.SalvaModifiche,
                cancelButtonText: TextDictionary.Admin.Salvataggio.Annulla,
            }).then((result) => {
                if (result.isConfirmed) {
                    handleSave(false);
                }
            });
        } else setShowEmployeeModal(true);
    }

    const saveEmployee = async (employee: EmployeesData) => {
        if (!employee.Email || !employee.Name || !employee.Surname) {
            return;
        }

        // Chiamo l'endpoint per salvare i dipendenti uno ad uno
        setIsCompilingEmployee(true);

        try {
            // /userSubscriptions/:userSubscriptionID/employee
            const payload = {
                Name: employee.Name,
                Surname: employee.Surname,
                Email: employee.Email
            };

            const response = await axiosAuthInstance.post(`${ApiConfig.ROOT_URL}/userSubscriptions/${user?.UserSubscriptionData?.[0].ID}/employee`, payload);
            console.log("response save", response.data);

            if (response.status >= 200) {
                // Imposto l'ID del dipendente prendendolo dalla response.data
                employee.ID = response.data.UserID;
                employee.UserID = response.data.UserID; // Imposto l'ID del dipendente effettivo
                employee.Name = response.data.Name; // Imposto il nome del dipendente effettivo
                employee.Surname = response.data.Surname; // Imposto il cognome del dipendente effettivo

                const index = employees.findIndex((emp) => emp.Email === employee.Email);
                setEmployees([...employees.slice(0, index), employee, ...employees.slice(index + 1)]); // Sostituisco l'elemento nell'array

                Swal.fire({
                    title: TextDictionary.Admin.Salvataggio.DipendenteAssociato,
                });
            }

            setIsCompilingEmployee(false);
        } catch (error: any) {
            Swal.fire({
                title: TextDictionary.Admin.Salvataggio.ErroreSalvataggio,
                text: error.response.data.message
            });

            setIsCompilingEmployee(false);
        }
    }

    const deleteEmployee = async (employee: EmployeesData) => {
        if (!employee.ID) {
            // Rimuovo l'elemento dall'array
            const index = employees.findIndex((emp) => emp.Email === employee.Email);
            setEmployees([...employees.slice(0, index), ...employees.slice(index + 1)]);
            return;
        }

        // Mostro un avviso di conferma
        const result = await Swal.fire({
            title: TextDictionary.Admin.Salvataggio.EliminaDipendente,
            showCancelButton: true,
            confirmButtonText: TextDictionary.Admin.Salvataggio.Conferma,
            cancelButtonText: TextDictionary.Admin.Salvataggio.Annulla
        });

        if (!result.isConfirmed) {
            return;
        }

        // Chiamo l'endpoint per eliminare il singolo dipendente
        setIsCompilingEmployee(true);
        try {
            // /userSubscriptions/:userSubscriptionID/employee
            const response = await axiosAuthInstance.delete(`${ApiConfig.ROOT_URL}/userSubscriptions/${user?.UserSubscriptionData?.[0].ID}/employee/${employee.ID}`);
            console.log(response.data);


            if (response.status >= 200) {
                const index = employees.findIndex((emp) => emp.Email === employee.Email);
                setEmployees([...employees.slice(0, index), ...employees.slice(index + 1)]); // Elimino l'elemento dall'array
            }

            setIsCompilingEmployee(false);

        } catch (error: any) {
            Swal.fire({
                title: TextDictionary.Admin.Salvataggio.ErroreSalvataggio,
                text: error.response.data.message
            });

            setIsCompilingEmployee(false);
        }
    }

    return (
        <AdminLayout>
            <div className="px-4 py-2 relative overflow-hidden">
                <h1 className="text-2xl font-semibold mb-8">{id ? TextDictionary.Admin.Utenti.ModificaUtente : TextDictionary.Admin.Utenti.NuovoUtente}</h1>

                {/* Dati anagrafici */}
                <div className="grid grid-cols-3 border border-black border-opacity-10 mb-8">
                    <div className="col-span-1 border-r border-black border-opacity-10 flex justify-center">
                        <div className="relative">
                            <img
                                src={imageSrc}
                                alt="Empty avatar illustration"
                                className="w-[150px] h-[150px] mx-auto cursor-pointer rounded-full border border-black border-opacity-20 mt-[50%]"
                                onClick={() => {
                                    const input = document.createElement('input');
                                    input.type = 'file';
                                    input.accept = 'image/*';
                                    input.click();

                                    input.addEventListener('change', async (e) => {
                                        const file = (e.target as HTMLInputElement).files?.[0];
                                        if (file) {
                                            const newImageSrc = URL.createObjectURL(file);
                                            setValue('URLImage', newImageSrc);
                                            setImageSrc(newImageSrc);
                                        }
                                    });
                                }}
                            />
                            {imageSrc !== EmptyAvatar && (
                                <button
                                    onClick={handleRemoveImage}
                                    className="absolute top-0 right-0 bg-errorRed text-white rounded-full h-8 w-8 flex justify-center items-center"
                                >
                                    X
                                </button>
                            )}
                        </div>
                    </div>
                    <div className="col-span-2">
                        <div className="flex flex-col border-b border-black border-opacity-10 pb-4">
                            <div className="grid grid-cols-2 gap-4 p-4">
                                <div className="flex flex-row gap-8 items-center">
                                    <label htmlFor="name" className="font-medium text-sm">{TextDictionary.Admin.Utenti.CampoNome}</label>
                                    <input type="text" id="name" className="border border-black border-opacity-10 px-4 py-1"
                                        {...register('Name')}
                                        autoFocus />
                                </div>
                                <div className="flex flex-row gap-8 items-center">
                                    <label htmlFor="surname" className="font-medium text-sm">{TextDictionary.Admin.Utenti.CampoCognome}</label>
                                    <input type="text" id="surname" className="border border-black border-opacity-10 px-4 py-1"
                                        {...register('Surname')}
                                    />
                                </div>
                            </div>
                            <div className="flex flex-row gap-8 p-4 items-center">
                                <label htmlFor="company" className="font-medium text-sm">{TextDictionary.Admin.Utenti.CampoAzienda}</label>
                                <input id="company" className="border border-black border-opacity-10 px-4 py-1"
                                    {...register('Company')}
                                />
                            </div>
                        </div>
                        <div className="flex flex-col gap-4 pt-4">
                            <div className="flex flex-row gap-8 p-4 items-center">
                                <label htmlFor="email" className="font-medium text-sm">{TextDictionary.Admin.Utenti.CampoEmail}</label>
                                <input type="text" id="email" className="border border-black border-opacity-10 px-4 py-1"
                                    {...register('Email')}
                                    disabled={id ? true : false}
                                    style={{ backgroundColor: id ? '#f9f9f9' : 'white' }}
                                />
                                <button className="bg-primary text-white px-4 py-1 w-[200px]" onClick={() => {
                                    Swal.fire({
                                        title: TextDictionary.Admin.Utenti.ResetPassword,
                                        text: TextDictionary.Admin.Utenti.MessaggioResetPassword,
                                        showCancelButton: true,
                                        confirmButtonText: TextDictionary.Admin.Utenti.Conferma,
                                        cancelButtonText: TextDictionary.Admin.Utenti.Annulla
                                    }).then(async (result) => {
                                        if (result.isConfirmed) {
                                            // Chiamo l'endpoint per il forgot password


                                            const payload = {
                                                Email: getValues('Email')
                                            };
                                            
                                            await axiosAuthInstance.post(`${ApiConfig.ROOT_URL}/auth/password-forgot`, payload)
                                                .then((response) => {
                                                    if (response.status >= 200) {
                                                        Swal.fire({
                                                            title: TextDictionary.Admin.Utenti.ResetPassword,
                                                            text: TextDictionary.Admin.Utenti.MessaggioResetPasswordInviata
                                                        });
                                                    }
                                                })
                                                .catch((error) => {
                                                    Swal.fire({
                                                        title: TextDictionary.Admin.Utenti.ErroreResetPassword,
                                                        text: error.response.data.message
                                                    });
                                                });
                                        }
                                    });
                                }}>{TextDictionary.Admin.Utenti.ResetPassword}</button>
                            </div>
                            <div className="flex flex-row gap-8 p-4 items-center">
                                <label htmlFor="phone" className="font-medium text-sm">{TextDictionary.Admin.Utenti.CampoTelefono}</label>
                                <input type="text" id="phone" className="border border-black border-opacity-10 px-4 py-1"
                                    {...register('Phone')}
                                />
                            </div>
                        </div>


                    </div>
                </div>

                { /* Se UserSubscriptionEmployeeData e UserSubscriptionData sono entrambi null, non mostro nulla al momento*/}

                <div className="module-card flex flex-col mb-8">
                    {/* Abbonamenti e informazioni */}

                    { /* Se l'utente non è proprietario, mostro l'email dell'utente proprietario che detiene l'abbonamento, il nome dell'abbonamento e la data di scadenza (non modificabile) */}

                    {
                        !user?.UserSubscriptionEmployeeData && !user?.UserSubscriptionData && (
                            <div className="flex flex-row gap-8 items-center">
                                <label htmlFor="subscription" className="font-medium text-sm">{TextDictionary.Admin.Utenti.CampoTipoAbbonamento}</label>
                                {/* <Controller
                                    name="UserSubscriptionSettings.SubscriptionID"
                                    control={control}
                                    render={({ field: { onChange, value, ref } }) => (
                                        <Select
                                            className='w-[300px]'
                                            options={subscriptions}
                                            value={selectedSubscription}
                                            onChange={handleChangeSubscription}
                                            closeMenuOnSelect={true}
                                            hideSelectedOptions={false}
                                            placeholder="Cerca abbonamento..."
                                        />
                                    )}
                                /> */}
                                {/* riscrivo usando una select con i valori degli abbonamenti */}
                                <select id="subscription" className="subscriptionSelect border border-black border-opacity-10 px-4 py-1 bg-white min-w-[250px]"
                                    {...register('UserSubscriptionSettings.SubscriptionID', { valueAsNumber: true })}
                                >
                                    {
                                        subscriptions.map((subscription) => (
                                            <option key={subscription.ID} value={subscription.ID}>{subscription.Title}</option>
                                        ))
                                    }
                                </select>
                            </div>
                        )
                    }

                    {
                        user?.UserSubscriptionEmployeeData && (
                            <div className="flex flex-col gap-4 p-4">
                                <div className="flex flex-row gap-8 items-center">
                                    <span className="font-medium text-sm">{TextDictionary.Admin.Utenti.CampoEmailProprietario}: <span
                                        className="underline cursor-pointer pl-8"
                                        onClick={() => {
                                            navigate(`/admin/users/${user.UserSubscriptionEmployeeData[0].UserSubscriptionOwnerID}`);
                                        }}
                                    >{ownerEmail}</span></span>
                                    {/* <input type="text" id="ownerMail" className="border border-black border-opacity-10 bg-[#f9f9f9] px-4 py-1" value={ownerEmail} disabled /> */}
                                </div>

                                <div className="flex flex-row gap-8 items-center">
                                    <label htmlFor="subscription" className="font-medium text-sm">{TextDictionary.Admin.Utenti.CampoTipoAbbonamento}</label>
                                    <input type="text" id="subscription" className="border border-black border-opacity-10 bg-[#f9f9f9] px-4 py-1" value={subscriptionName ?? ''} disabled />

                                    <label htmlFor="expirationDate" className="font-medium text-sm">{TextDictionary.Admin.Utenti.CampoDataScadenza}</label>
                                    <input type="date" id="expirationDate" className="border border-black border-opacity-10 bg-[#f9f9f9] px-4 py-1" value={expirationDate ?? ''} disabled />
                                </div>
                            </div>
                        )
                    }

                    { /* Se l'utente è proprietario, mostro in una row il nome dell'abbonamento dentro ad una input disabilitata e la scadenza con un date picker per la data di scadenza */}
                    {
                        user?.UserSubscriptionData && (
                            <div className="flex flex-col gap-4 p-4">
                                <div className="flex flex-row items-center gap-8 ">
                                    <label htmlFor="subscription" className="font-medium text-sm">{TextDictionary.Admin.Utenti.CampoTipoAbbonamento}</label>
                                    <select id="subscription" className="border border-black border-opacity-10 px-4 py-1 bg-white min-w-[250px]"
                                        {...register('UserSubscriptionSettings.SubscriptionID', { valueAsNumber: true })}
                                    >
                                        {
                                            subscriptions.map((subscription) => (
                                                <option key={subscription.ID} value={subscription.ID}>{subscription.Title}</option>
                                            ))
                                        }
                                    </select>
                                    <label htmlFor="expirationDate" className="font-medium text-sm">{TextDictionary.Admin.Utenti.CampoDataScadenza}</label>
                                    <input type="date" id="expirationDate" className="border border-black border-opacity-10 px-4 py-1"
                                        {...register('UserSubscriptionSettings.ExpireDate')}
                                    />
                                </div>

                                <div className="flex flex-row gap-8 items-center">
                                    <label htmlFor="employeesNumber" className="font-medium text-sm pt-1">{TextDictionary.Admin.Utenti.CampoNumeroDipendenti}</label>

                                    <div className="flex flex-row gap-2 items-center">
                                        <span className="font-medium text-sm">{employees.length > 0 ? employees.length : 0} / </span>
                                        <input type="number" id="employeesNumber" className="border border-black border-opacity-10 w-24 h-fit px-4 py-1" min={employees.length}
                                            {...register('UserSubscriptionSettings.EmployeesNumber')}
                                        />
                                    </div>

                                    <button className="bg-primary text-white px-4 py-1" onClick={handleEmployeeModal}>{TextDictionary.Admin.Utenti.ModaleDipendenti}</button>
                                </div>
                            </div>
                        )
                    }
                </div>
                {
                    showEmployeeModal && (
                        <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-20 min-w-[500px]">
                            <div className="bg-white p-4 rounded-lg min-w-[500px]">
                                <div className="flex flex-row justify-between items-center mb-8">
                                    <h2 className="text-2xl font-semibold">{TextDictionary.Admin.Utenti.ModaleDipendenti}</h2>
                                    {employeesNumber && employees.length < employeesNumber && (
                                        <button className="bg-primary text-white px-4 py-1" onClick={() => {
                                            // Aggiungo un nuovo elemento all'array employees con valori nulli
                                            const newEmployees = [...employees];
                                            newEmployees.push({
                                                ID: null,
                                                UserID: null,
                                                Name: '',
                                                Surname: '',
                                                Email: ''
                                            });
                                            setEmployees(newEmployees);
                                        }} disabled={isCompilingEmployee} style={{ opacity: isCompilingEmployee ? 0.5 : 1 }}>
                                            +
                                        </button>
                                    )}
                                </div>
                                <div className="flex flex-col gap-4">

                                    {/* riscrivo iniziando a far si che vengano mostrate le righe solo se sono compilate (disabilitata e con un tasto per rimuoverla). Se clicco col tasto "+" deve aggiungermi una nuova
                                    riga con i campi vuoti e, una volta compilati tutti i campi deve comparire un tasto salva che salva la riga e la disabilita. */}
                                    {
                                        employees.length > 0 ? employees.map((employee: EmployeesData, index) => (
                                            employeesNumber && index < employeesNumber && (
                                                <div key={index} className="flex flex-row gap-4">
                                                    <input type="text" className="border border-black border-opacity-10 px-4 py-1" value={employee.Name} onChange={(e) => {
                                                        const newEmployees = [...employees];
                                                        newEmployees[index].Name = e.target.value;
                                                        setEmployees(newEmployees);
                                                    }} placeholder="Nome"
                                                        disabled={employee.ID ? true : false}
                                                        style={{ backgroundColor: employee.ID ? AppConfig.lightGray : 'white' }}
                                                    />
                                                    <input type="text" className="border border-black border-opacity-10 px-4 py-1" value={employee.Surname} onChange={(e) => {
                                                        const newEmployees = [...employees];
                                                        newEmployees[index].Surname = e.target.value;
                                                        setEmployees(newEmployees);
                                                    }} placeholder="Cognome"
                                                        disabled={employee.ID ? true : false}
                                                        style={{ backgroundColor: employee.ID ? AppConfig.lightGray : 'white' }}
                                                    />
                                                    <input type="text" className={`border border-black border-opacity-10 px-4 py-1 ${employee.ID && 'cursor-pointer'}`} value={employee.Email} onChange={(e) => {
                                                        const newEmployees = [...employees];
                                                        newEmployees[index].Email = e.target.value;
                                                        setEmployees(newEmployees);
                                                    }} placeholder="Email"
                                                        style={{ backgroundColor: employee.ID ? AppConfig.lightGray : 'white' }}
                                                        onClick={() => {
                                                            console.log("employee", employee);
                                                            if (employee.UserID) {
                                                                setShowEmployeeModal(false);
                                                                navigate(`/admin/users/${employee.UserID}`);
                                                            }
                                                        }}
                                                    />
                                                    {
                                                        employees[index] && (
                                                            <button className="bg-errorRed text-white rounded-md w-20" onClick={() => {
                                                                deleteEmployee(employee);
                                                            }}>X</button>
                                                        )
                                                    }
                                                    {
                                                        employee.Email && employee.Name && employee.Surname && employee.ID === null && (
                                                            <button className="bg-primary text-white px-4 py-1" onClick={() => {
                                                                saveEmployee(employee)
                                                            }} disabled={isCompilingEmployee} style={{ opacity: isCompilingEmployee ? 0.5 : 1 }}>
                                                                Salva
                                                            </button>
                                                        )
                                                    }
                                                </div>)
                                        )) : (
                                            <span>{TextDictionary.Admin.Utenti.NessunDipendente}</span>
                                        )
                                    }
                                </div>
                                <div className="flex flex-row gap-4 pt-8 justify-end">
                                    <button className="underline px-4 py-1" onClick={() => setShowEmployeeModal(false)}>Chiudi</button>
                                </div>
                            </div>
                        </div>
                    )
                }

                {/* Associazione con i coupon */}
                <RelationHandlerComponent
                    options={options.coupons}
                    setValue={setValue}
                />

                {/* Card di salvataggio */}
                <FloatingBottomToolbar onSave={() => {
                    handleSave(false);
                }}
                    onSaveAndClose={() => {
                        handleSave(true);
                    }} onDiscard={() => {
                        handleDiscard();
                    }}
                    isSubmitting={isSubmitting}
                />
            </div>
        </AdminLayout>

    );
};

export default SingleUser;