import { AgColumn, createGrid, GridApi, GridOptions, IDatasource, IGetRowsParams } from "ag-grid-community";
import { useEffect, useState } from "react";
import axios from "axios";
import { ApiConfig } from "../config/Configuration";
import { useNavigate } from "react-router-dom";
import { TextDictionary } from "../utils/TextDictionary";
import Swal from "sweetalert2";
import { axiosAuthInstance } from "../auth/sso/auth.interceptor";

interface TableComponentProps {
  endpoint: string;
  dictionaryContext?: string;
}

interface HttpFormParams {
  page_number?: number;
  items_per_page?: number;

  order_by?: string;
  order_sort?: string;

  filters?: string;
  filter_values?: string;
}

export const TableComponent = (props: TableComponentProps) => {
  const navigate = useNavigate();
  const endPointUrl = ApiConfig.ROOT_URL + props.endpoint;

  const [columns, setColums] = useState<any[]>([]); // List of columns for the selected form type
  const [gridApi, setGridApi] = useState<GridApi | null>(null);
  const [dataLoading, setDataLoading] = useState<boolean>(true);

  useEffect(() => {
    if (props.endpoint)
      loadColums();
  }, [props.endpoint]);

  useEffect(() => {
    if (columns && columns.length > 0) {
      createTableInfinite();
    }
  }, [columns]);

  const loadColums = async () => {
    const response = await axiosAuthInstance.get(endPointUrl + '/table-config');
    if (response.data.length > 0) {
      const columns = getColumnListData(response.data);
      setColums(columns);
    }
  }

  const setTrashIcon = (el: any) => {
    el.innerHTML = props.endpoint !== '/users' ? `<svg width="20px" height="20px" viewBox="0 0 24 24" fill="#f5584d" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M3 6.52381C3 6.12932 3.32671 5.80952 3.72973 5.80952H8.51787C8.52437 4.9683 8.61554 3.81504 9.45037 3.01668C10.1074 2.38839 11.0081 2 12 2C12.9919 2 13.8926 2.38839 14.5496 3.01668C15.3844 3.81504 15.4756 4.9683 15.4821 5.80952H20.2703C20.6733 5.80952 21 6.12932 21 6.52381C21 6.9183 20.6733 7.2381 20.2703 7.2381H3.72973C3.32671 7.2381 3 6.9183 3 6.52381Z" fill="#f5584d"/>
                                                    <path fillRule="evenodd" clipRule="evenodd" d="M11.5956 22H12.4044C15.1871 22 16.5785 22 17.4831 21.1141C18.3878 20.2281 18.4803 18.7749 18.6654 15.8685L18.9321 11.6806C19.0326 10.1036 19.0828 9.31511 18.6289 8.81545C18.1751 8.31579 17.4087 8.31579 15.876 8.31579H8.12404C6.59127 8.31579 5.82488 8.31579 5.37105 8.81545C4.91722 9.31511 4.96744 10.1036 5.06788 11.6806L5.33459 15.8685C5.5197 18.7749 5.61225 20.2281 6.51689 21.1141C7.42153 22 8.81289 22 11.5956 22ZM10.2463 12.1885C10.2051 11.7546 9.83753 11.4381 9.42537 11.4815C9.01321 11.5249 8.71251 11.9117 8.75372 12.3456L9.25372 17.6087C9.29494 18.0426 9.66247 18.3591 10.0746 18.3157C10.4868 18.2724 10.7875 17.8855 10.7463 17.4516L10.2463 12.1885ZM14.5746 11.4815C14.9868 11.5249 15.2875 11.9117 15.2463 12.3456L14.7463 17.6087C14.7051 18.0426 14.3375 18.3591 13.9254 18.3157C13.5132 18.2724 13.2125 17.8855 13.2537 17.4516L13.7537 12.1885C13.7949 11.7546 14.1625 11.4381 14.5746 11.4815Z" fill="#f5584d"/>
                                                  </svg>` : 
                                                  `<svg fill="#f5584d" height="20px" width="20px" version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" 
                                                      viewBox="0 0 177.055 177.055" xml:space="preserve">
                                                      <path d="M0.001,88.527c0,48.814,39.713,88.527,88.527,88.527c48.813,0,88.526-39.713,88.526-88.527S137.341,0,88.528,0
                                                        C39.714,0,0.001,39.713,0.001,88.527z M88.528,24.304c35.413,0,64.224,28.811,64.224,64.224c0,13.324-4.081,25.712-11.055,35.983
                                                        L52.544,35.359C62.816,28.385,75.204,24.304,88.528,24.304z M124.511,141.696c-10.272,6.974-22.659,11.055-35.983,11.055
                                                        c-35.413,0-64.223-28.811-64.223-64.224c0-13.324,4.081-25.711,11.054-35.983L124.511,141.696z"/>
                                                    </svg>`;
  }

  const setSpinnerIcon = (el: any) => {
    el.innerHTML = `<div class="h-6 w-6 animate-spin rounded-full border-2 border-solid border-primary border-t-transparent"></div>`;
  }

  const getColumnListData = (columnOptions: { headerName: string, field: string, filter?: boolean, sortable?: boolean, resizable?: boolean, bbType?: 'number' | 'currency' | 'string' | 'url' | 'image' }[]) => {
    const newColumnOptions = columnOptions.map((column) => {
      if (props.dictionaryContext) {
        return {
          headerName: (TextDictionary.Admin.Tabelle as any)[props.dictionaryContext][column.field] || column.headerName,
          field: column.field,
          filter: column.filter,
          sortable: column.sortable,
          resizable: column.resizable,
          bbType: column.bbType
        }
      }
      return column;
    });

    let gridColumnList: { headerName: string, field: string, filter?: boolean, sortable?: boolean, resizable?: boolean, bbType?: 'number' | 'currency' | 'string' | 'url' | 'image' }[] = [];

    gridColumnList.push({ headerName: 'Modifica', field: 'ID' });

    gridColumnList = gridColumnList.concat(newColumnOptions);

    gridColumnList.push({ headerName: 'Elimina', field: 'ID' });

    const returnGridColumnsList = gridColumnList.map((column) => {
      if (column.headerName === 'Modifica') {
        return {
          headerName: '',
          field: column.field,
          cellRenderer: (params: any) => {
            const fieldValue = params.value;
            const handleEdit = (fieldValue: any, props: TableComponentProps) => {
              navigate('/admin' + props.endpoint + '/' + fieldValue);
            };

            const container = document.createElement('div');
            container.className = 'flex items-center justify-center gap-2 w-full mt-3';

            const editButton = document.createElement('button');
            editButton.innerHTML = `<svg fill="black" version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" 
                                        width="15px" height="15px" viewBox="0 0 494.936 494.936"
                                        xml:space="preserve">
                                      <g>
                                        <g>
                                          <path d="M389.844,182.85c-6.743,0-12.21,5.467-12.21,12.21v222.968c0,23.562-19.174,42.735-42.736,42.735H67.157
                                            c-23.562,0-42.736-19.174-42.736-42.735V150.285c0-23.562,19.174-42.735,42.736-42.735h267.741c6.743,0,12.21-5.467,12.21-12.21
                                            s-5.467-12.21-12.21-12.21H67.157C30.126,83.13,0,113.255,0,150.285v267.743c0,37.029,30.126,67.155,67.157,67.155h267.741
                                            c37.03,0,67.156-30.126,67.156-67.155V195.061C402.054,188.318,396.587,182.85,389.844,182.85z"/>
                                          <path d="M483.876,20.791c-14.72-14.72-38.669-14.714-53.377,0L221.352,229.944c-0.28,0.28-3.434,3.559-4.251,5.396l-28.963,65.069
                                            c-2.057,4.619-1.056,10.027,2.521,13.6c2.337,2.336,5.461,3.576,8.639,3.576c1.675,0,3.362-0.346,4.96-1.057l65.07-28.963
                                            c1.83-0.815,5.114-3.97,5.396-4.25L483.876,74.169c7.131-7.131,11.06-16.61,11.06-26.692
                                            C494.936,37.396,491.007,27.915,483.876,20.791z M466.61,56.897L257.457,266.05c-0.035,0.036-0.055,0.078-0.089,0.107
                                            l-33.989,15.131L238.51,247.3c0.03-0.036,0.071-0.055,0.107-0.09L447.765,38.058c5.038-5.039,13.819-5.033,18.846,0.005
                                            c2.518,2.51,3.905,5.855,3.905,9.414C470.516,51.036,469.127,54.38,466.61,56.897z"/>
                                        </g>
                                      </g>
                                    </svg>`;
            editButton.addEventListener('click', () => handleEdit(fieldValue, props));

            container.appendChild(editButton);
            return container;
          }
        }
      }
      else if (column.headerName === 'Elimina') {
        return {
          headerName: '',
          field: column.field,
          cellRenderer: (params: any) => {
            const fieldValue = params.value;


            const handleDelete = (fieldValue: any) => {
              Swal.fire({
                text: TextDictionary.TabelleDinamiche.ConfermaEliminazione,
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: TextDictionary.TabelleDinamiche.ConfermaSi,
                cancelButtonText: TextDictionary.TabelleDinamiche.ConfermaNo
              }).then((result) => {
                if (result.isConfirmed) {

                  setSpinnerIcon(deleteButton);
                  axiosAuthInstance.delete(endPointUrl + '/' + fieldValue).then(() => {
                    if (params.api) {
                      params.api.purgeInfiniteCache();

                      setTrashIcon(deleteButton);
                    }
                  }).catch((error) => {
                    // AGGIUNGERE ALERT DI ERRORE
                    Swal.fire({
                      text: error.response.data.message,
                      icon: 'error',
                      confirmButtonText: TextDictionary.TabelleDinamiche.ConfermaOk
                    });

                    setTrashIcon(deleteButton);

                    console.error('Error deleting element', error);
                  });
                }
              });
            };

            const container = document.createElement('div');
            container.className = 'flex items-center justify-center gap-2 w-full mt-3';

            const deleteButton = document.createElement('button');
            setTrashIcon(deleteButton);

            deleteButton.addEventListener('click', () => handleDelete(fieldValue));

            container.appendChild(deleteButton);

            return container;
          }
        }
      }
      return {
        headerName: column.headerName,
        field: column.field,
        sortable: column.sortable,
        filter: column.filter && 'agTextColumnFilter',
        filterParams: {
          defaultOption: 'contains',
          suppressAndOrCondition: true,
          filterOptions: ['contains']
        },
        resizable: true,
        type: column.bbType === 'number' || column.bbType === 'currency' ? 'rightAligned' : 'leftAligned',
        cellRenderer: (params: any) => {
          if (params.value === null || params.value === undefined) {
            return '';
          }
          if (column.bbType === 'url') {
            return `<a href="${params.value}" target="_blank" class="underline text-primary">${params.value}</a>`;
          }
          if (column.bbType === 'image' && params.value) {
            return `<img src="${params.value}" alt="${column.headerName}" style="width: 100px; height: 40px; object-fit: contain" />`;
          }
          if (column.bbType === 'currency') {
            return `<span>${params.value.toFixed(2)}</span>`;
          }

          return `<span>${params.value}</span>`;
        },
        tooltipShowDelay: 500,
        tooltipValueGetter: (params: any) => {
          return params.value;
        },
      };
    });

    return returnGridColumnsList;
  }
  const sizeToFit = () => {
    setTimeout(() => {
      gridApi?.getColumns()?.forEach((column: any) => {
        gridApi.autoSizeColumns([column]);
        if (column.getActualWidth() > 300) {
          // Imposto la larghezza massima delle colonne a 300px
          (column as AgColumn).setActualWidth(300, "api");
        }

        // Imposto una larghezza minima di 100px per la colonna con colId 'Data'
        if (column.colId === 'Date') {
          (column as AgColumn).setActualWidth(150, "api");
        }
      });
    }, 100)
  }

  useEffect(() => {
    if (dataLoading === false && gridApi) {
      sizeToFit();
    }
  }, [dataLoading]);


  const createTableInfinite = () => {
    const gridOptions = {
      rowModelType: 'infinite',
      cacheBlockSize: 25, // Ogni blocco scaricato dal server contiene 50 righe
      pagination: true,
      paginationPageSize: 25,
      paginationPageSizeSelector: [10, 25, 50, 100],
      paginationAutoPageSize: false,
      columnDefs: columns,
      enableCellTextSelection: true,
      singleClickEdit: true,
      autoSizeStrategy: {
        type: "fitCellContents",
        // skipHeader: true
      },
    } as GridOptions;

    const dataSource: IDatasource = {
      rowCount: undefined,
      getRows: (params: IGetRowsParams) => {
        setDataLoading(true);
        const sortModel = params.sortModel;
        const filterModel = params.filterModel;

        const chunkPaginationPageSize = 25;

        // Calcolo la pagina corrente in base ai parametri di infinite scrolling
        const pageNumber = Math.floor(params.startRow / chunkPaginationPageSize) + 1;

        const httpParams: HttpFormParams = {
          page_number: pageNumber,
          items_per_page: chunkPaginationPageSize,
        }

        if (sortModel.length > 0) {
          httpParams['order_by'] = sortModel[0].colId;
          httpParams['order_sort'] = sortModel[0].sort;
        }

        const filterKeys = Object.keys(filterModel);
        if (filterKeys.length > 0) {
          httpParams['filters'] = filterKeys.map((key) => encodeURIComponent(key)).join(',');
          httpParams['filter_values'] = filterKeys.map((key) => encodeURIComponent(filterModel[key].filter)).join(',');
        }

        axiosAuthInstance.get(endPointUrl, {
          params: httpParams
        }).then((response) => {
          if (response.data.length === 0) {
            setDataLoading(false);
            params.successCallback([], 0);
            return;
          }

          const rowsThisBlock: any[] = []
          response.data.forEach((element: any) => {
            const row: any = {};
            columns.forEach((column) => {
              row[column.field] = element[column.field];
            });
            rowsThisBlock.push(row);
          });

          const lastRow = response.data.length < chunkPaginationPageSize ? params.startRow + response.data.length : -1;
          // Pass the data to the grid
          setTimeout(() => {
            setDataLoading(false);
            params.successCallback(rowsThisBlock, lastRow);
          }, 0);
        }).catch((error: any) => {
          console.error("Error fetching rows", error);
          setDataLoading(false);
          params.failCallback();
        });
      },
    };

    const myGrid = document.querySelector("#myGrid") as HTMLElement;
    if (myGrid) {
      myGrid.innerHTML = "";
    }

    gridOptions.datasource = dataSource;

    const grid = createGrid(myGrid, gridOptions);
    setGridApi(grid);
  };


  if (!columns && !dataLoading) { // Se il caricamento dei dati fallisce, visualizza un messaggio di errore e un pulsante per riprovare
    return (
      <>
        <span className="block text-xl text-black font-semibold">{TextDictionary.TabelleDinamiche.Errore}</span>
        <span className="block text-md mb-5 text-black font-normal">{TextDictionary.TabelleDinamiche.ErroreMessaggio}</span>
        <button
          className="bg-primary text-white text-sm px-6 py-1 rounded-full hover:bg-transparent hover:text-primary border border-primary"
          onClick={() => loadColums()}
        >
          {TextDictionary.TabelleDinamiche.BottoneRiprova}
        </button>
      </>
    );
  }

  return (
    <div className="relative h-full w-full">
      <div id="myGrid" className="ag-theme-quartz h-full w-full" />
      {dataLoading && (
        <div className="absolute inset-0 bg-white bg-opacity-80 flex items-center justify-center">
          <div className="flex flex-col items-center">
            <div className="h-8 w-8 animate-spin rounded-full border-2 border-solid border-primary border-t-transparent"></div>
          </div>
        </div>
      )}
    </div>
  );
};