import { useEffect } from "react";
import Profile from "../Profile";
import { TextDictionary } from "../../utils/TextDictionary";
import { useLoaderData } from "react-router-dom";

const UserCertificates = () => {
    const myProducts = useLoaderData() as any;
    return (
        <div className="w-full flex flex-col">
            { /* creo un header con il titolo della pagina centrato */}
            <div className="flex flex-col justify-center items-center w-full py-4 border-y border-black border-opacity-10 gap-2 md:border-t-0 border-x md:border-x-0">
                <h1 className="text-black text-lg md:text-2xl font-medium">{TextDictionary.CertificatiUtente.Titolo}</h1>
                <span className="text-black text-opacity-80 text-sm md:text-md">{TextDictionary.CertificatiUtente.Sottotitolo}</span>
            </div>
        </div>
    );
};

export default UserCertificates;