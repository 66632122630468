import { useState } from "react";
import PublicLayout from "../layout/PublicLayout";
import { Outlet, NavLink } from "react-router-dom";
import { TextDictionary } from "../utils/TextDictionary";
import EmptyAvatar from "../assets/images/empty-avatar.png";
import { UserService } from "../services/user.service";

const Profile = () => {
    const [imageSrc, setImageSrc] = useState(UserService.getInstance().user?.UrlImage || EmptyAvatar);

    return (
        <PublicLayout>
            <div className="flex max-w-[1400px] h-full justify-center items-center mx-auto my-0 md:my-10">
                <div className="flex flex-col md:flex-row h-full w-full border border-black border-opacity-10">
                    <aside className="flex w-full md:w-60 h-full flex-col overflow-y-hidden border-r border-black border-opacity-10">
                        <img
                            src={imageSrc}
                            alt="Empty avatar illustration"
                            className="w-[120px] h-[120px] md:w-[150px] md:h-[150px] mx-auto cursor-pointer rounded-full border border-black border-opacity-20 mt-8 md:mt-4"
                            onClick={() => {
                                const input = document.createElement('input');
                                input.type = 'file';
                                input.accept = 'image/*';
                                input.click();

                                input.addEventListener('change', async (e) => {
                                    const file = (e.target as HTMLInputElement).files?.[0];
                                    if (file) {
                                        const newImageSrc = URL.createObjectURL(file);
                                        setImageSrc(newImageSrc);

                                        // TODO: Gestione del cambio dell'immagine
                                    }
                                });
                            }}
                        />
                        <span className="text-black text-md font-medium text-center py-4">{UserService.getInstance().user?.Name ?? 'Nome'} {UserService.getInstance().user?.Surname ?? 'Cognome'}</span>
                        <div className="md:border-t border-black border-opacity-10 mb-2"></div>
                        <ul className="flex flex-col py-4 md:py-0">
                            <li>
                                <NavLink
                                    to="my-profile"
                                    className="relative flex items-center gap-2.5 rounded-sm py-2 px-4 font-medium text-black text-sm md:text-md"
                                    style={({ isActive, isPending }) => {
                                        return {
                                            textDecoration: isActive || isPending ? "underline" : "none",
                                        };
                                    }}
                                >
                                    {TextDictionary.Profilo.MioProfilo}
                                </NavLink>
                            </li>
                            <li>
                                <NavLink
                                    to="courses"
                                    className="relative flex items-center gap-2.5 rounded-sm py-2 px-4 font-medium text-black text-sm md:text-md"
                                    style={({ isActive, isPending }) => {
                                        return {
                                            textDecoration: isActive || isPending ? "underline" : "none",
                                        };
                                    }}
                                >
                                    {TextDictionary.Profilo.MieiCorsi}
                                </NavLink>
                            </li>
                            <li>
                                <NavLink
                                    to="certificates"
                                    className="relative flex items-center gap-2.5 rounded-sm py-2 px-4 font-medium text-black text-sm md:text-md"
                                    style={({ isActive, isPending }) => {
                                        return {
                                            textDecoration: isActive || isPending ? "underline" : "none",
                                        };
                                    }}
                                >
                                    {TextDictionary.Profilo.Certificati}
                                </NavLink>
                            </li>
                            <li>
                                <NavLink
                                    to="subscriptions"
                                    className="relative flex items-center gap-2.5 rounded-sm py-2 px-4 font-medium text-black text-sm md:text-md"
                                    style={({ isActive, isPending }) => {
                                        return {
                                            textDecoration: isActive || isPending ? "underline" : "none",
                                        };
                                    }}
                                >
                                    {TextDictionary.Profilo.GestioneAbbonamenti}
                                </NavLink>
                            </li>
                        </ul>
                    </aside>
                    <main className="flex grow flex-1 w-full p-4 md:p-0">
                        {/* Qui verranno renderizzati i componenti figli */}
                        <Outlet />
                    </main>
                </div>
            </div>
        </PublicLayout>
    );
};

export default Profile;
