import { useEffect } from "react";
import Profile from "../Profile";
import { TextDictionary } from "../../utils/TextDictionary";
import { UserService } from "../../services/user.service";
import { AuthUser } from "../../auth/interfaces/user.interface";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { axiosAuthInstance } from "../../auth/sso/auth.interceptor";
import { ApiConfig } from "../../config/Configuration";
import Swal from "sweetalert2";

const MyProfile = () => {
    const navigate = useNavigate();
    const { register, setValue, getValues, control, handleSubmit, formState: { errors, isDirty, isSubmitting }, reset } = useForm<AuthUser>({
        defaultValues: {
            ID: UserService.getInstance().user?.ID,
            Name: UserService.getInstance().user?.Name,
            Surname: UserService.getInstance().user?.Surname,
            Email: UserService.getInstance().user?.Email,
            Phone: UserService.getInstance().user?.Phone,
            Company: UserService.getInstance().user?.Company,
            City: UserService.getInstance().user?.City,
            Address: UserService.getInstance().user?.Address,
        }
    });

    useEffect(() => {
        if (UserService.getInstance().user) {
            reset({
                ID: UserService.getInstance().user?.ID ?? 0,
                Name: UserService.getInstance().user?.Name ?? '',
                Surname: UserService.getInstance().user?.Surname ?? '',
                Email: UserService.getInstance().user?.Email ?? '',
                Phone: UserService.getInstance().user?.Phone ?? '',
                Company: UserService.getInstance().user?.Company ?? '',
                City: UserService.getInstance().user?.City ?? '',
                Address: UserService.getInstance().user?.Address ?? '',
            });
        }
    }, [UserService.getInstance().user]);

    const onSubmit = async (data: AuthUser) => {
        console.log(data);

        // Aspettare nuovo endpoint per gestire l'aggiornamento dei dati utente lato public
        const response = await axiosAuthInstance.put(ApiConfig.ROOT_URL + '/auth/user', data);
        console.log(response);

        if (response.status >= 200) {
            Swal.fire({
                title: 'Successo!',
                text: 'I dati sono stati aggiornati correttamente',
                icon: 'success',
                confirmButtonText: 'Ok'
            });


            UserService.getInstance().user = data;
            navigate('/profile');
        }
    }

    const handlePasswordChange = () => {
        Swal.fire({
            title: TextDictionary.Admin.Utenti.ResetPassword,
            text: TextDictionary.Admin.Utenti.MessaggioResetPassword,
            showCancelButton: true,
            confirmButtonText: TextDictionary.Admin.Utenti.Conferma,
            cancelButtonText: TextDictionary.Admin.Utenti.Annulla
        }).then(async (result) => {
            if (result.isConfirmed) {
                // Chiamo l'endpoint per il forgot password
                const payload = {
                    Email: getValues('Email')
                };

                await axiosAuthInstance.post(`${ApiConfig.ROOT_URL}/auth/password-forgot`, payload)
                    .then((response) => {
                        if (response.status >= 200) {
                            Swal.fire({
                                title: TextDictionary.Admin.Utenti.ResetPassword,
                                text: TextDictionary.Admin.Utenti.MessaggioResetPasswordInviata
                            });
                        }
                    })
                    .catch((error) => {
                        Swal.fire({
                            title: TextDictionary.Admin.Utenti.ErroreResetPassword,
                            text: error.response.data.message
                        });
                    });
            }
        });
    }

    return (
        <div className="w-full flex flex-col">
            { /* creo un header con il titolo della pagina centrato */}
            <div className="flex flex-col justify-center items-center w-full py-4 border-y border-black border-opacity-10 gap-2 md:border-t-0 border-x md:border-x-0">
                <h1 className="text-black text-lg md:text-2xl font-medium">{TextDictionary.ProfiloUtente.Titolo}</h1>
                <span className="text-black text-opacity-80 text-sm md:text-md">{TextDictionary.ProfiloUtente.Sottotitolo}</span>
            </div>

            <div className="flex flex-col gap-12 w-full h-full px-4 md:px-20 py-10 border-x md:border-x-0 border-b md:border-b-0">
                <div className="flex flex-col gap-4">
                    <span className="text-black text-md font-medium">{TextDictionary.ProfiloUtente.DatiPersonali}</span>
                    <div className="flex flex-col md:flex-row gap-4">
                        <input type="text" placeholder="Nome" className="w-full md:w-1/2 p-2 border border-black border-opacity-10" {...register('Name')} />
                        <input type="text" placeholder="Cognome" className="w-full md:w-1/2 p-2 border border-black border-opacity-10" {...register('Surname')} />
                    </div>
                    <div className="flex flex-col md:flex-row gap-4">
                        <input type="text" placeholder="Email" className="w-full md:w-1/2 p-2 border border-black border-opacity-10 bg-disabledGray" {...register('Email')} disabled />
                        <input type="text" placeholder="Telefono" className="w-full md:w-1/2 p-2 border border-black border-opacity-10" {...register('Phone')} />
                    </div>
                    <div className="flex flex-col md:flex-row gap-4">
                        <input type="text" placeholder="Azienda" className="w-full md:w-1/3 p-2 border border-black border-opacity-10" {...register('Company')} />
                        <input type="text" placeholder="Città" className="w-full md:w-1/3 p-2 border border-black border-opacity-10" {...register('City')} />
                        <input type="text" placeholder="Indirizzo" className="w-full md:w-1/3 p-2 border border-black border-opacity-10" {...register('Address')} />
                    </div>
                </div>
                <div className="flex flex-col gap-4">
                    <span className="text-black text-md font-medium">{TextDictionary.ProfiloUtente.Sicurezza}</span>
                    <span className="text-black text-md underline cursor-pointer"
                        onClick={handlePasswordChange}
                    >{TextDictionary.ProfiloUtente.CambiaPassword}</span>
                </div>

                <button className="bg-primary border border-primary text-white text-md font-medium py-2 px-4 w-fit mt-8 hover:bg-white hover:border hover:border-primary hover:text-primary"
                    onClick={handleSubmit(onSubmit)}
                    disabled={isSubmitting}
                >{TextDictionary.ProfiloUtente.SalvaModifiche}</button>
            </div>
        </div>
    );
};

export default MyProfile;