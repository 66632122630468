import { useEffect } from "react";
import Profile from "../Profile";
import { TextDictionary } from "../../utils/TextDictionary";
import { useLoaderData } from "react-router-dom";

const UserCourses = () => {
    const myProducts = useLoaderData() as any;
    return (
        <div className="w-full flex flex-col">
            { /* creo un header con il titolo della pagina centrato */}
            <div className="flex flex-col justify-center items-center w-full py-4 border-y border-black border-opacity-10 gap-2 md:border-t-0 border-x md:border-x-0">
                <h1 className="text-black text-lg md:text-2xl font-medium">{TextDictionary.CorsiUtente.Titolo}</h1>
                <span className="text-black text-opacity-80 text-sm md:text-md">{TextDictionary.CorsiUtente.Sottotitolo}</span>
            </div>

            { /* creo una lista di corsi stampando il titolo */}
            <div className="flex flex-col gap-4 p-4">
                {myProducts.map((product: any) => (
                    <div key={product.ID} className="flex flex-row gap-4 border border-black border-opacity-20 p-4">
                        <span className="text-lg">{product.ID}</span>
                        <p className="text-lg font-medium">{product.Title}</p>
                        <p className="text-lg">[{product.Type}]</p>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default UserCourses;