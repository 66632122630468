import { useEffect, useState } from "react";
import { useLoaderData, useParams } from "react-router-dom";
import { Course } from "../models/course";
import { PublicVideo, Video } from "../models/video";
import { axiosAuthInstance } from "../auth/sso/auth.interceptor";
import { ApiConfig, AppConfig } from "../config/Configuration";
import PublicLayout from "../layout/PublicLayout";
import { PrimaryButton } from "../components/PrimaryButton";
import { QuizComponent } from "../components/QuizComponent";
import { Quiz } from "../models/quiz";
import { TextDictionary } from "../utils/TextDictionary";
import Player from '@vimeo/player';

interface QuestionElem { // Da spostare in un file a parte
    title?: string;
    type: 'intro' | 'question';
    duration?: number;
    description?: string;
    question?: string;
    content?: string;
    special?: 'writing' | 'listening';
    answers: AnswerElem[];
}

interface AnswerElem { // Da spostare in un file a parte
    text: string;
    selected?: boolean;
    isCorrect?: boolean;
    points?: number;
}


export const PublicSingleLive = () => {
    const { id } = useParams();
    const live = useLoaderData() as any;


    const [showResourcesModal, setShowResourcesModal] = useState(false); // Mostra il modal con le risorse del corso.
    const [videoCompleted, setVideoCompleted] = useState(true); // Indica se il video è stato completato dall'utente.

    // const [quizData, setQuizData] = useState<QuestionElem[]>(video.QuizData);
    const [quizStarted, setQuizStarted] = useState(false);

    const downloadResource = async (resource: any, index: number, type: 'video' | 'course') => {
        console.log("downloadResource", resource, index, type);


        const resourceElement = document.getElementById(`${type}-resource-${index}`);

        resourceElement?.classList.add("cursor-not-allowed");
        resourceElement?.classList.add("pointer-events-none");
        resourceElement?.classList.add("opacity-50");

        const response = await axiosAuthInstance.get(ApiConfig.ROOT_URL + resource.DownloadLink, {
            responseType: 'blob',
        }) as any;

        console.log("downloadResource", response);

        // faccio il download del file
        const file = window.URL.createObjectURL(new Blob([response.data]));
        const a = document.createElement('a');
        a.href = file;
        a.download = resource.FileName;
        a.click();
        window.URL.revokeObjectURL(file);

        resourceElement?.classList.remove("cursor-not-allowed");
        resourceElement?.classList.remove("pointer-events-none");
        resourceElement?.classList.remove("opacity-50");
    }

    return (
        <PublicLayout>
            <div className="container w-full mx-auto flex flex-col justify-center items-center h-full">
                <div className="flex flex-col w-full h-full max-w-[1400px] mx-auto lg:flex-row my-20 gap-20 px-8">
                    {/* Contenuto principale */}
                    <div className="w-full lg:w-2/3 h-full flex flex-col">
                        <h1 className="text-3xl font-semibold mb-4 text-primary">{live.Title}🔴</h1>
                        <span className="text-md mb-4 text-primary">{TextDictionary.PaginaLive.LabelAggiornatoAl} {live.Date}</span>
                        <span className="text-md mb-4 text-primary">{TextDictionary.PaginaLive.LabelDocente} {live.Teacher}</span>

                        {/* divisore */}
                        <div className="w-full border-t border-[#686868] mb-10"></div>

                        <div className={`public-video-iframe-container w-full bg-[#F4F4F4] mb-8 ${!live?.EmbeddedHTML && 'h-[400px]'}`}
                            dangerouslySetInnerHTML={{ __html: live?.LiveMetadata.PlayerEmbedURL || '' }}
                        ></div>

                        {/* <div className="w-full flex justify-start items-center mb-10 gap-4 lg:gap-0">

                            <button className={`w-[150px] h-12 rounded-[15px] flex items-center justify-center gap-4 ${videoCompleted ? 'bg-customYellow text-primary' : 'bg-[#D9D9D9] text-[#5F5F5F] cursor-not-allowed'}`} onClick={
                                () => {
                                    if (!videoCompleted) return;

                                    setQuizStarted(true);

                                    setTimeout(() => {
                                        const quizBlock = document.getElementById('quiz-block');
                                        quizBlock?.scrollIntoView({ behavior: 'smooth' });
                                    }, 200);
                                }
                            }>
                                {TextDictionary.PaginaLive.BottoneIniziaTest}
                                {
                                    videoCompleted ? (
                                        <svg width="20px" height="20px" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M19 9l-7 7-7-7"></path>
                                        </svg>
                                    ) : (
                                        <svg width="20px" height="20px" viewBox="0 0 26 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M7.16667 12.8333V8.16659C7.16667 6.61949 7.78125 5.13576 8.87521 4.0418C9.96917 2.94783 11.4529 2.33325 13 2.33325C14.5471 2.33325 16.0308 2.94783 17.1248 4.0418C18.2188 5.13576 18.8333 6.61949 18.8333 8.16659V12.8333M4.83333 12.8333H21.1667C22.4553 12.8333 23.5 13.8779 23.5 15.1666V23.3333C23.5 24.6219 22.4553 25.6666 21.1667 25.6666H4.83333C3.54467 25.6666 2.5 24.6219 2.5 23.3333V15.1666C2.5 13.8779 3.54467 12.8333 4.83333 12.8333Z" stroke="#5F5F5F" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                        </svg>
                                    )
                                }
                            </button>
                        </div> */}

                        <span className="text-primary font-medium mb-4">{TextDictionary.PaginaLive.LabelIntroduzione}</span>
                        <span dangerouslySetInnerHTML={{ __html: live.Description || '' }} className="text-black" />
                    </div>
                    {/* Modale risorse del corso e della lezione */}
                    {
                        showResourcesModal && (
                            <div className="fixed top-0 left-0 w-full h-full bg-black bg-opacity-50 z-50 flex justify-center items-center">
                                <div className="w-full lg:w-1/3 bg-white p-8 rounded-[20px]">
                                    <div className="w-full flex justify-end items-center">
                                        <button onClick={() => setShowResourcesModal(false)} className="text-primary">
                                            <svg width="20" height="20" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12"></path>
                                            </svg>
                                        </button>
                                    </div>
                                    <div className="w-full mb-8 flex flex-col gap-2">
                                        <span className="text-primary font-medium text-lg">{TextDictionary.PaginaLive.LabelRisorseLezione}</span>
                                        {
                                            live.ResourceData.find(
                                                (resource: any) => resource.Origin === 'live'
                                            ) ? live.ResourceData?.map((resource: any, index: number) => {
                                                if (resource.Origin === 'video') {
                                                    return (
                                                        <div key={index} className="w-full flex items-center">
                                                            <span id={"video-resource-" + index} className="text-black underline cursor-pointer" onClick={() => {
                                                                downloadResource(resource, index, 'video');
                                                            }}>{resource.Title}</span>
                                                        </div>
                                                    )
                                                }
                                            }) : <span className="text-black">{TextDictionary.PaginaLive.LabelNessunaRisorsaLezione}</span>
                                        }
                                    </div>
                                </div>
                            </div>
                        )
                    }


                    {/* Sidebar */}
                    <div className="w-full lg:w-1/3 h-full flex flex-col">
                        <div className="w-full flex flex-col justify-center mb-16">
                            <span className="text-black text-center text-sm">{TextDictionary.PaginaLive.LabelRisorse}</span>
                            <button className="w-[200px] h-12 bg-customYellow text-primary font-medium rounded-[15px] mt-5 mx-auto flex gap-4 justify-center items-center"
                                onClick={() => setShowResourcesModal(!showResourcesModal)}
                            >
                                <svg width="20px" height="20px" fill={AppConfig.primaryColor} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 30" x="0px" y="0px"><g data-name="Layer 2"><path d="M22,15.75A1.252,1.252,0,0,0,20.75,17v3a.752.752,0,0,1-.75.75H4A.752.752,0,0,1,3.25,20V17a1.25,1.25,0,0,0-2.5,0v3A3.254,3.254,0,0,0,4,23.25H20A3.254,3.254,0,0,0,23.25,20V17A1.252,1.252,0,0,0,22,15.75Z" /><path d="M12,.75A1.252,1.252,0,0,0,10.75,2V13.331L6.8,10.04A1.251,1.251,0,0,0,5.04,10.2,1.251,1.251,0,0,0,5.2,11.96l6,5a1.269,1.269,0,0,0,.2.116,1.179,1.179,0,0,0,.143.082,1.174,1.174,0,0,0,.911,0,1.3,1.3,0,0,0,.14-.081,1.279,1.279,0,0,0,.2-.116l6-5A1.25,1.25,0,0,0,18,9.75a1.255,1.255,0,0,0-.8.29L13.25,13.331V2A1.252,1.252,0,0,0,12,.75Z" /></g></svg>
                                {TextDictionary.PaginaLive.BottoneScaricaRisorse}
                            </button>
                        </div>

                        <div className="public-live-chat-container border mt-4 h-full flex-1" dangerouslySetInnerHTML={{ __html: live?.LiveMetadata.ChatEmbedURL || '' }} />
                    </div>
                </div>
            </div>
            {/* {quizData && quizStarted && (<QuizComponent quizData={quizData} />)} */}
        </PublicLayout>
    )
}